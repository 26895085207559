.crm-create-template-page-wrapper-container {
    display: grid;
    grid-template-rows: auto 11fr;
    position: absolute;
    width: 100%;
    height: 90%;
    left: 0;
    top: 0;
    .tabs-content > li.active {
        pointer-events: unset;
    }
    .tabs-content-holder .loader-holder {
        height: 100%;
    }
}
.crm-create-template-page-head {
    position: relative;
    width: 100%;
}
.crm-create-template-page-content {
    width: 100%;
    height: 100%;
    border: 20px solid transparent;
    position: relative;
    &:only-child {
        grid-row: span 2;
    }
    .invalid-tab {
        width: 100%;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        background-color: rgba(223, 47, 77, 0.06);
        p {
            width: 100%;
        }
        &.active:before {
            border-bottom: 4px solid red !important;
        }
    }
    .tabs-holder {
        background-color: var(--background);
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        height: 100%;
        .tabs-head {
            .tabs-actions {
                button {
                    margin-inline-end: unset;
                }
            }
        }
    }
    .tabs-content-holder {
        height: 100%;
        padding: 0 !important;
        position: relative;
    }
    .push-n-template-data {
        .crm-messaging-row-content textarea {
            min-height: auto;
            border: unset;
        }
    }
    .crm-loader-template-page-content {
        display: flex;
        justify-content: center;
        align-content: center;
        height: 100%;
    }
}
.crm-create-template-page-c-container {
    display: grid;
    width: 100%;
    height: 100%;

    &.push,
    &.hoory {
        grid-template-rows: auto 11fr;
    }
    &.sms {
        grid-template-rows: auto 11fr;
        .crm-messaging-row-content {
            height: 95%;
        }
    }

    &.viber,
    &.email {
        grid-template-rows: 0.5fr 1fr 8fr;
    }

    &.dynamic-pop-up {
        display: block;
    }

    &.telegram {
        grid-template-rows: 0.5fr 1fr 8fr;
    }
}

.viber,
.email {
    grid-template-rows: 0.5fr 1fr 8fr;
}

.dynamic-pop-up {
    .dynamic-pop-up-url-section {
        margin-top: 1rem;
        padding: 0 2rem;
        display: flex;
        column-gap: 2rem;

        .dynamic-pop-up-url-type {
            width: 24rem;
        }
        .dynamic-pop-up-url-input {
            max-width: 40rem;
        }
        .dynamic-pop-up-device-type {
            width: 24rem;
        }
    }
}

.telegram {
    grid-template-rows: 0.5fr 1fr 8fr;
}

.crm-create-template-page-c-c-head {
    width: 100%;
}
.crm-create-template-page-c-c-h-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(#000000, 0.1);
}
.crm-create-template-page-c-c-h-tabs-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    height: 50px;
    border-bottom: 1px solid rgba(#000000, 0.1);
}
.crm-create-template-page-c-c-tabs-column {
    display: flex;
    align-items: center;
    height: 100%;
    &:only-child {
        flex: 1;
    }
    &.align-end {
        align-items: flex-end;
        padding-bottom: 3px;
    }
}
.crm-create-template-page-c-c-h-inputs-row {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 20px 0 20px;
}
.crm-create-template-page-c-c-h-inputs-column {
    flex: 1;
    max-width: 550px;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
    > input {
        height: 42px;
        font-size: 14px;
        border: 1px solid rgba(#000000, 0.1);
        color: rgba(60, 64, 67, 0.7);
        font-weight: 600;
        border-radius: 20px;
        padding: 0 20px;
        width: 100%;
    }
    .input-holder {
        height: 48px;
    }
}
.crm-create-template-page-c-c-content {
    display: grid;
    grid-gap: 20px;
    width: 100%;
    padding: 2rem;

    &:only-child {
        grid-row: span 2;
    }
}
.crm-create-template-page-variables-row {
    padding: 2rem 2rem 1rem;
}
.crm-create-template-page-variables-row-head {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    > span {
        font-weight: 600;
        font-size: 14px;
        text-transform: capitalize;
        color: var(--hero);
    }
    > i {
        font-size: 20px;
        margin: 0 6px;
        cursor: pointer;
        font-weight: 600;
    }
}
.crm-create-template-page-variables-container {
    display: flex;
    align-items: center;
    min-height: 7.2rem;

    .crm-create-template-page-variables-row-content {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        max-width: 100%;
    }
}

.crm-messaging-row-content {
    hr {
        border-width: 0 0 1px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }
    width: 100%;
    border: 1px solid rgba(var(--background-sc-rgb), 0.1);
    border-radius: 10px;

    &:focus-within {
        border-color: var(--hero);
    }

    .public-DraftEditorPlaceholder-root {
        color: rgba(60, 64, 67, 0.7);
    }
    .input-structure {
        height: 100%;
        padding: 0;
    }
    .input-holder {
        height: 100%;
        label {
            height: 100%;
        }
    }
    .input-element-relative {
        height: 100%;
    }
    textarea {
        padding: 11px 20px;
        width: 100% !important;
        height: 100% !important;
        border-radius: 10px;
        border: solid 1px var(--line-color);
        font-weight: 600;
        color: var(--custom-color-1);
        resize: none;
    }

    .rdw-link-modal {
        padding: 15px 15px 0 15px !important;
        .rdw-link-modal-buttonsection {
            margin-top: 2rem !important;
        }
    }
    .rdw-image-modal {
        padding: 20px 20px 0 20px !important;
        .rdw-image-modal-header {
            & + div {
                width: 100%;
            }
        }
        .rdw-image-modal-upload-option-label {
            display: block !important;
            width: 100% !important;
        }
        .rdw-image-modal-btn-section {
            margin-top: 2rem !important;
        }
    }
}
.sms-template-wrap {
    height: 100%;
    .crm-messaging-row-content textarea {
        border: none;
    }
    .rdw-editor-main {
        width: 100%;
        height: calc(100% - 60px) !important;
        overflow-y: scroll;
        position: absolute;
    }
    .select-languages-block {
        display: flex;
        align-items: center;
        color: var(--custom-color-1);
        > span {
            display: flex;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            > img {
                display: block;
                width: 100%;
            }
        }
        > p {
            padding-left: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .tabs-content {
        padding-top: 8px;
    }

    .tabs-content.type-button {
        box-shadow: unset;
        border-radius: unset;
        overflow: auto;
    }
    .tabs-content.type-button > li {
        border-radius: 1rem 1rem 0 0;
        border: 1px solid rgba(var(--background-sc-rgb), 0.1);
        border-bottom: none;
        position: relative;
        box-shadow: unset;
        color: var(--custom-color-1);
        height: 3.4rem;

        &.active {
            box-shadow: unset;
            & > .ellipsis-text p {
                font-weight: 700;
            }
        }

        &:not(:first-child) {
            margin-left: 5px;
        }

        &.active {
            position: relative;
            box-shadow: unset;
            &:before {
                display: block;
                position: absolute;
                content: '';
                width: 100%;
                height: 3px;
                border-bottom: 4px solid var(--hero);
                bottom: 0;
                left: 0;
            }
            > div {
                p {
                    font-weight: bold;
                }
            }
        }
    }
    .tabs-content > li .icon {
        margin-right: 0;
    }
    .bc-icon-profit {
        font-size: 14px;
    }
    .tabs-head {
        display: flex;
        justify-content: space-between;
        padding: 0 2rem 0 1rem;
        box-shadow: 0 1px var(--line-color);
        max-width: calc(100vw / var(--zoom) - 4.6rem);
        .tabs-wrapper {
            flex: 1;
            width: 100%;
            height: 100%;
            position: relative;
            overflow-y: hidden;
            .tabs-content.type-button {
                display: inline-flex;
                max-width: 100%;
                height: 100%;
                overflow-y: hidden;
            }
        }
        .tabs-actions {
            box-shadow: none;
            button {
                display: flex;
                align-items: center;
                i {
                    font-size: 22px;
                    font-weight: 600;
                }
                span {
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: 600;
                }
            }
        }
    }
    .sms-template {
        .crm-messaging-row-content {
            .input-element-back {
                display: none;
            }
        }
        .crm-scrollable-container {
            padding-right: 0;
        }
        .crm-messaging-row-head > p {
            padding-left: 10px;
        }
        .rdw-editor-main {
            height: auto;
            resize: none;
        }
        .rdw-editor-wrapper {
            height: 100%;
            border: none;
        }
    }
}
.add-language {
    .table-holder:not(.content-empty) {
        max-height: 484px !important;
    }
    .input-holder {
        border: 1px solid var(--line-color);
        border-radius: 21px;
    }
    .modal-head {
        .ellipsis-text {
            color: var(--custom-color-1);
            font-weight: bold;
        }
    }
    .table-heading-text {
        color: var(--custom-color-1);
    }
    .modal-content {
        width: 550px !important;
        max-height: 674px !important;
        height: 100% !important;
    }
    .modal-body {
        padding: 0;
        overflow: hidden;
        height: 100%;
    }
    .module-header {
        display: none;
    }
    .paper.with-shadow {
        border: none;
    }
    .table-holder {
        height: 100%;
        max-height: 48.7rem;
    }
    .ta-pagination-holder {
        display: none;
    }
}
.crm-messaging-row-content.error-color {
    border: 1px solid rgba(207, 0, 15, 1);
}
.crm-internal-message-editor.error-color {
    .rdw-editor-toolbar {
        border-bottom: 1px solid rgba(207, 0, 15, 1);
    }
}
.error-text {
    color: #cf000f;
    font-size: 13px;
    padding-left: 6px;
}

.internal-radio-group-container {
    z-index: 1;
    padding-left: 20px;
    padding-top: 20px;
}

.crm-sms-main-content {
    :first-child {
        height: 100%;
    }
}
