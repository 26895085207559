//Campaign Select Mode Modal
.campaign-modal {
    .modal-body {
        padding: 1rem 2rem 2rem 2rem;
    }
    .campaign-modal-holder {
        width: 38.2rem;
        p {
            font-size: 1.2rem;
            text-align: center;
        }
        .campaign-modal-select-mode {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3rem;
            margin-bottom: 2.5rem;
            label {
                color: var(--custom-color-1);
                font-size: 1.4rem;
                font-weight: 600;
                margin-left: 0.5rem;
            }
        }
        .campaign-modal-textarea {
            width: 38.4rem;
        }
    }
}
// Campaign Stop Modal
.campaign-stop-modal-holder {
    width: 35.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > .crm-circle-icon-cnt {
        background-color: rgba(125, 198, 129, 0.06);
        > .bc-icon-info-48 {
            font-size: 4.8rem;
            color: #7dc681;
        }
        flex: 0 0 auto;
        margin-left: 2rem;
        & + span {
            flex: 1;
            margin-left: 2rem;
            font-size: 1.4rem;
            font-weight: 600;
            color: var(--custom-color-1);
        }
    }
}

.campaign-info-modal {
    .crm-campaign-info-popup {
        > .title-wrapper {
            padding: 0 2rem;
            margin-bottom: 0;
        }
        .crm-custom-tabs-btn-panel {
            margin-bottom: 0px;
        }
    }
    .crm-campaign-info-detail-modal-body {
        padding: 0 2rem;
        .crm-campaign-info-action-buttons-container {
            display: flex;
            justify-content: flex-end;
        }
    }
    .crm-campaign-info-popup {
        width: 100%;
        height: 643px;
        max-width: 100%;
        max-height: 100%;
        .loader-holder {
            margin: 25%;
        }
        .empty-state-holder {
            width: 100%;
            height: 100%;
        }
        .crm-custom-tabs-cnt {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 12fr;
            grid-template-rows: auto 11fr;
        }
        .crm-custom-tabs-content {
            width: 100%;
            height: 100%;
        }
        .crm-tab-content {
            &.active {
                width: 100%;
                height: 100%;
            }
            .campaign-info-modal-segment-container {
                height: calc(100% - 3rem);   
                padding-left: 1rem;
            }
        }
    }

    .segment-modal-bottom {
        height: 380px;
        overflow: auto;
    }
    .segment-modal-bottom {
        .tabs-content-holder {
            padding: 2rem 0 !important;
        }
    }
}

.campaign-bonus-row {
    padding: 0 2rem;
}

.key-value-h-row {
    display: grid;
    grid-template-columns: 4fr 8fr;
}

.key-value-holder {
    padding: 0 2rem;
}

.key-value-h-wrapper {
    overflow-x: hidden;
    max-height: 265px;
    height: 100%;
    overflow-y: auto;
}

.crm-set-goal-modal {
    .modal-body {
        .dropdown-holder,
        .input-holder {
            width: 410px;
        }

        .input-holder:not(:first-child) {
            margin-top: 20px;
        }
    }
}
