.crm-dynamic-segment-header {
    display: flex;
    box-shadow: 0 1px 0 0 var(--line-color);
    .input-holder {
        max-width: 255px;
    }
}

.crm-dynamic-segment-list-wrap {
    min-height: 482px;

    .empty-state-holder {
        width: calc(100% - 275px - 20px);
    }
}

.crm-dynamic-segment-filters-wrap {
    min-height: 482px;

    .empty-state-holder {
        width: 100%;
    }
}

.crm-dynamic-segment-list {
    box-shadow: inset -1px 0 0 0 rgba(var(--background-sc-rgb), 0.03);
    overflow-y: auto;
    width: 295px;
    transition: width 0.3s ease;
    padding: 10px 6px 10px 10px;
    &.crm-closed {
        width: 62px;
        &:hover {
            width: 295px;
        }
    }
}

.crm-dynamic-segment-list-item {
    height: 42px;
    min-width: 42px;
    border-radius: 21px;
    cursor: pointer;
    padding: 0 10px;
    margin-bottom: 2px;
    overflow: hidden;
    &:hover,
    &.crm-active {
        background-color: rgba(0, 0, 0, 0.03);
    }
}

.crm-dynamic-segment-list-item-color-circle {
    width: 24px;
    height: 24px;
    padding: 7px;
    display: flex;
    margin: 0 10px 0 0;

    span {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
    }
}
.crm-dynamic-segment-list-item-icon {
    color: var(--background);
    height: 24px;
    width: 24px;
    border-radius: 20px;
    margin: 0 10px 0 0;
    &.folder {
        color: var(--dynamic-segment-list-item-icon);
    }
}

.crm-segment-filters-header-blocks {
    display: flex;
    align-items: center;
    .input-element-relative {
        .input-element {
            width: 60px;
        }
    }
}

.crm-segment-filters-header-left-side {
    font-size: 14px;

    i {
        display: inline-block;
        cursor: pointer;
    }
}

.crm-filters-group__scrollbar {
    .scrollbars-custom-content {
        padding: 0 20px !important;
    }

    .scroll-content {
        padding: 0 20px;
    }
}

.crm-dynamic-segment-filter-schema-wrapper {
    padding: 0 1rem 1rem 1rem;

    .crm-dynamic-segment-filter-schema {
        padding: 1rem 1rem 0 1rem;
        border: 1px solid;
        width: 100%;
        position: relative;
        border-radius: 6px;
        margin: 14px 0 0 0;
        border-color: var(--kpi-group-rgb-color);
        color: var(--kpi-group-rgb-color);

        .title-holder {
            min-height: 2rem;
        }

        .title-text > div {
            font-weight: 400;
        }

        .crm-dynamic-segment-filters-container-warning-message {
            .alert-box {
                padding-left: 0;
                background: none;
                border: none;
            }

            .ab-bc-icon-c {
                margin-top: 0.4rem;
                margin-bottom: 0.4rem;
                padding: 0;
                display: flex;
                align-items: center;
                color: rgba(var(--background-sc-rgb), 0.75);
            }

            .alert-box-title {
                font-size: 1.2rem;
                font-weight: 400;
            }
        }

        .crm-delete-modal {
            color: rgba(var(--background-sc-rgb), 0.75);
        }

        &:not(:nth-of-type(1)) {
            margin: 24px 0 0 0;
        }

        &__header {
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(-50%);
            width: 100%;
            padding: 0 2rem;

            &__title {
                background: var(--background);
                padding: 0 4px;
            }

            &__icons {
                .icon {
                    background: var(--background);
                    border: 1px solid;
                    border-radius: 24px;
                    cursor: pointer;
                    width: 28px;
                    height: 28px;
                    font-size: 17px;
                    line-height: 26px;
                    text-align: center;
                    transition: background 0.3s ease, transform 0.3s ease;

                    &:not(:nth-of-type(1)) {
                        margin: 0 0 0 10px;
                    }

                    &:hover {
                        color: #ffffff;
                        background-color: var(--kpi-group-rgb-color);
                    }
                }
            }
        }
    }
}

.crm-dynamic-segment-filters-item-wrapper {
    padding: 0 0 1rem 0;
}

.crm-dynamic-segment-filters-item {
    padding: 0.5rem;
    border: 1px solid;
    width: 100%;
    position: relative;
    border-radius: 6px;
    margin: 14px 0 0 0;
    border-color: var(--kpi-group-rgb-color);
    color: var(--kpi-group-rgb-color);

    .crm-delete-modal {
        color: rgba(var(--background-sc-rgb), 0.75);
    }

    &:not(:nth-of-type(1)) {
        margin: 24px 0 0 0;
    }

    &__header {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-50%);
        width: 100%;
        padding: 0 7rem 0 2rem;

        &__title {
            background: var(--background);
            padding: 0 4px;
        }

        &__icons {
            .icon {
                background: var(--background);
                border: 1px solid;
                border-radius: 24px;
                cursor: pointer;
                width: 28px;
                height: 28px;
                font-size: 17px;
                line-height: 26px;
                text-align: center;
                transition: background 0.3s ease, transform 0.3s ease;

                &:not(:nth-of-type(1)) {
                    margin: 0 0 0 10px;
                }

                &:hover {
                    color: #ffffff;
                    background-color: var(--kpi-group-rgb-color);
                }
            }
        }
    }
}

.crm-dynamic-segment-filters-group_title {
    position: absolute;
    top: -10px;
    left: 20px;
    z-index: 1;
    background: var(--background);
    padding: 0 2px;
}

.crm-dynamic-segment-filters-group_icons {
    position: absolute;
    top: -10px;
    right: 20px;
    z-index: 1;
    background: var(--background);
    padding: 0 2px;
}

.crm-filters-group-item {
    width: 100%;
    display: flex;
    min-height: 26px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 10px;
    &:not(:nth-of-type(1)) {
        margin: 0.4rem 0 0 0;
    }
    p {
        display: flex;
        align-items: center;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
    }
}

.crm-filters-group-item__icons {
    display: flex;
    align-items: flex-end;

    i {
        cursor: pointer;
    }
}

// dynamic segment CREATE START
.create-dynamic-segment-page-wrapper {
    display: grid;
    grid-template-rows: auto 11fr;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 90%;
    left: 0;
    top: 0;
    --header-height: 0;
    --table-max-height-additional-calc: 408px;
    .modal-splash {
        .divider.type-horizontal {
            width: 100% !important;
        }
    }
    .breadcrumbs-link {
        font-size: 1.4rem;
        &:not(.active) {
            color: var(--custom-color-1);
        }
    }
    h6 {
        white-space: nowrap;
    }
    .empty-state-image {
        > img {
            display: block;
            height: 15vmin;
        }
    }
    .empty-state-subtitle {
        word-break: break-word;
        font-size: 1.6vmin;
    }
    .empty-state-title {
        font-size: 1.8vmin;
    }
}

.dynamic-segment-tabs-container {
    max-width: 610px;
    .tabs-content-holder,
    .tabs-holder {
        height: 100%;
        padding: unset !important;
        .create-dynamic-segment-p-w-c-i-c-content-column {
            height: 90%;
            padding-bottom: 10px;
        }
    }
    .empty-container {
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .tabs-wrapper {
        .tabs-content > li.active {
            pointer-events: auto;
        }
        li {
            color: var(--custom-color-1);
            .ellipsis-text {
                display: flex;
                align-items: center;
                min-width: 40px;
                max-width: 200px;
            }
            button {
                min-height: 30px;
                max-width: 123px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-weight: 600;
                }
            }
            i {
                font-weight: 600;
            }
        }
    }
    .dynamic-segment-name-input-container {
        .input-holder {
            padding: 15px;
            width: 350px;
        }
        box-shadow: 0 1px 0 0 var(--line-color);
    }

    .tabs-holder.horizontal {
        .tabs-content.type-basic li {
            .invalid-tab {
                width: unset;
            }
            display: flex;
            &.invalid-segment-tab {
                background-color: red;
            }
            width: unset;
            min-width: unset;
            max-width: 180px;
            & > div {
                & > span {
                    font-weight: inherit;
                }
            }
        }
    }
    .substep-tabs-actions-wrapper {
        i {
            color: #ffffff;
            background: rgba(20, 115, 230, 0.85);
            border: 1px solid;
            border-radius: 24px;
            cursor: pointer;
            width: 28px;
            height: 28px;
            font-size: 17px;
            line-height: 26px;
            text-align: center;
            transition: background 0.3s ease, transform 0.3s ease;

            &:not(:nth-of-type(1)) {
                margin: 0 0 0 10px;
            }

            &:hover {
                color: #ffffff;
                background-color: rgb(20, 115, 230);
            }
        }
    }
}

.create-dynamic-segment-p-w-content {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 8fr 4fr;
    grid-gap: 20px;
    padding: 20px;
    &:only-child {
        grid-row: span 2;
    }
    .crm-sports-book-match-form__dropdown-with-icon {
        .crm-sports-book-match-form__search-dropdown {
            background-color: var(--background);
        }
    }
}
.create-dynamic-segment-p-w-c-i-column {
    display: grid;
    grid-template-rows: auto 11fr;
    height: 100%;
    background-color: var(--background);
    border-radius: 20px;
    border: 1px solid var(--line-color);
}
.create-dynamic-segment-p-w-c-i-column-head {
    width: 100%;
}
.create-dynamic-segment-p-w-c-i-column-content {
    display: grid;
    grid-template-columns: auto 11fr;
    position: relative;
    .empty-state-holder {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .loader-holder {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        justify-content: center;
    }
}
.create-dynamic-segment-p-w-c-i-c-content-column {
    display: flex;
    height: 100%;
    position: relative;
    &:only-child {
        grid-column: span 2;
    }
}
.create-dynamic-segment-p-w-c-i-c-content-c-inner {
    width: 100%;
    height: 100%;
    padding: 10px 6px 10px 10px;

    .crm-editable-state {
        .crm-dynamic-segment-filters-item {
            border-color: var(--kpi-group-rgb-color-disabled);
            color: var(--kpi-group-rgb-color-disabled);

            .crm-filters-group-item:not(.crm-editable-item) {
                border-color: var(--kpi-group-rgb-color-disabled);
                color: var(--kpi-group-rgb-color-disabled);
            }

            .crm-editable-item {
                color: var(--kpi-group-rgb-color);
            }
        }
    }
}
// dynamic segment CREATE END

.icon.bc-icon-refresh.rotate {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/**/
