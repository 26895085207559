$spaceAmounts: (0, 1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 95, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceAmounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px;
        }
        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px;
        }
    }
}

@each $space in $spaceAmounts {
    .m-#{$space} {
        margin: #{$space}px;
    }
    .p-#{$space} {
        padding: #{$space}px;
    }
    .mtb-#{$space} {
        margin-top: #{$space}px;
        margin-bottom: #{$space}px;
    }
    .ptb-#{$space} {
        padding-top: #{$space}px;
        padding-bottom: #{$space}px;
    }
    .mlr-#{$space} {
        margin-left: #{$space}px;
        margin-right: #{$space}px;
    }
    .plr-#{$space} {
        padding-left: #{$space}px;
        padding-right: #{$space}px;
    }
}

.container {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    &.root-container {
        width: calc(100% - 40px);
        padding: 0;
    }
}

.row {
    display: flex;
}

@for $i from 1 through 12 {
    .crm-col-#{$i} {
        width: percentage(calc($i / 12)) !important;
    }
}

@for $i from 1 through 12 {
    .crm-col--offset-#{$i} {
        margin-left: percentage(calc($i / 12)) !important;
    }
}

.remove-scroll {
    height: 100vh;
    overflow: hidden;
}

.crm-show {
    display: block !important;
}

.crm-invisible {
    visibility: hidden;
}

.crm-full-width {
    width: 100%;
}

.crm-content-width {
    width: auto !important;

    &-inline {
        display: inline-block !important;
    }
}

.crm-text-center {
    text-align: center;

    * {
        width: 100%;
    }
}

.crm-blue {
    color: var(--hero);
}

.crm-flex {
    display: flex;
}

.crm-flex-row {
    flex-direction: row;
}

.crm-flex-column {
    flex-direction: column;
}

.crm-flex-row-reverse {
    flex-direction: row-reverse;
}

.crm-flex-column-reverse {
    flex-direction: column-reverse;
}

.crm-flex-wrap {
    flex-wrap: wrap;
}

.crm-flex-nowrap {
    flex-wrap: nowrap;
}

.crm-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.crm-flex-fill {
    flex: 1 1 auto;
}

.crm-flex-grow-0 {
    flex-grow: 0;
}

.crm-flex-grow-1 {
    flex-grow: 1;
}

.crm-flex-shrink-0 {
    flex-shrink: 0;
}

.crm-flex-shrink-1 {
    flex-shrink: 1;
}

.crm-justify-content-start {
    justify-content: flex-start;
}

.crm-justify-content-end {
    justify-content: flex-end;
}

.crm-justify-content-center {
    justify-content: center;
}

.crm-justify-content-between {
    justify-content: space-between;
}

.crm-justify-content-around {
    justify-content: space-around;
}

.crm-align-items-start {
    align-items: flex-start;
}

.crm-align-items-end {
    align-items: flex-end;
}

.crm-align-items-center {
    align-items: center;
}

.crm-align-items-baseline {
    align-items: baseline;
}

.crm-align-items-stretch {
    align-items: stretch;
}

.crm-align-content-start {
    align-content: flex-start;
}

.crm-align-content-end {
    align-content: flex-end;
}

.crm-align-content-center {
    align-content: center;
}

.crm-align-content-between {
    align-content: space-between;
}

.crm-align-content-around {
    align-content: space-around;
}

.crm-align-content-stretch {
    align-content: stretch;
}

.crm-align-self-auto {
    align-self: auto;
}

.crm-align-self-start {
    align-self: flex-start;
}

.crm-align-self-end {
    align-self: flex-end;
}

.crm-align-self-center {
    align-self: center;
}

.crm-align-self-baseline {
    align-self: baseline;
}

.crm-align-self-stretch {
    align-self: stretch;
}
