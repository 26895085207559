.overlay-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid rgba(var(--background-sc-rgb), 0.1);
    background-color: var(--background);
}

.info-button {
    display: flex !important;
}

.accordion-body-holder {
    .dropdown-holder {
        max-width: 420px;
    }
}

.overlay-content-wrap {
    .accordion-holder {
        margin-top: 18px;
    }
    .ellipsis-text {
        color: var(--custom-color-1);
    }
}
.accordion-body-inside {
    display: flex;
    align-items: center;
    .input-holder {
        max-width: 240px;
        margin-right: 20px;
    }
    .dropdown-holder {
        max-width: 160px;
        margin-right: 20px;
    }
}
.accordion-wrap {
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: scroll;
    .accordion-holder {
        padding: 0 20px;
    }
    .tabs-content {
        position: relative;
    }
    .tabs-info-icon {
        display: flex;
        align-items: center;
        height: 100%;
        position: absolute;
        top: 0;
        right: -30px;
    }
    .crs-label {
        label {
            display: flex;
            align-items: center;
            i {
                padding-left: 6px;
            }
            p {
                font-size: 14px;
                font-weight: 600;
                color: var(--custom-color-1);
            }
        }
    }
    .cha-ra-group .crs-holder {
        padding: 0 2rem 2rem 0;
    }
    .tabs-content-holder {
        padding: 1.9rem 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .crs-holder {
            padding-bottom: 18px;
        }
        .limits-check {
            .crs-holder {
                padding-bottom: unset;
            }
        }
    }
    .accordion-body {
        padding: 1rem 0;
        .wallet-selection-container {
            display: flex;
            align-items: center;
            padding-bottom: 2rem;
            .wallet-dropdown {
                max-width: 420px;
            }
        }
    }

    .trading-plan-container {
        padding: 3rem;
        display: flex;
        .dropdown-holder,
        .input-holder {
            width: 25rem;
        }
        .dropdown-holder {
            margin-right: 5rem;
        }
    }
}
.amount-wrap-inner {
    display: inline-flex;
    .input-holder {
        max-width: 210px;
    }
}
.amount-text {
    display: flex;
    flex-direction: column;
    width: 180px;
    margin-right: 10px;
    p {
        line-height: 19px;
        font-size: 14px;
        font-weight: 600;
    }
}
.amount-text-code {
    color: var(--custom-color-1);
}
.amount-text-count {
    color: rgba(60, 64, 67, 0.7);
}
.amount-wrap {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 20px;
}
.bonus-limits {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 20px 0;
}
.bonus-holder {
    display: flex;
    flex-direction: column;
    .input-holder {
        max-width: 240px;
        margin-right: 20px;
    }
}
.percentage-block {
    display: flex;
    flex-direction: column;
    .input-holder {
        max-width: 160px;
        margin-right: 20px;
    }
}
.bonus-holder-title {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}
.limits-check {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: flex-end;
    .crs-holder {
        margin-bottom: 6px;
        margin-right: 8px;
    }
    i {
        margin-bottom: 2px;
    }
}
.header-title-name {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: var(--custom-color-1);
}
.header-title-count {
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
    color: var(--custom-color-1);
}
.overlay-content-alert {
    margin-left: 1.5em;
    margin-top: 1em;
}

.collapse-items-group-container {
    .key-value-items-title {
        font-size: 12px;
        color: rgba(60, 64, 67, 0.7);
    }
}

.bonus-list-table {
    min-height: 500px;
}
