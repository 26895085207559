/* Code Editor Styles */
.html-code-editor-container {
    height: 100%;
}
.html-editor-container {
    height: 100%;
    display: flex;
    .code-editor-header {
        display: flex;
        justify-content: flex-end;
        background-color: var(--editor-header-background);
        align-items: center;
        height: 50px;
        border-radius: 10px 0px 0px 0px;
        .action-gallery {
            display: flex;
            align-items: center;
            margin-right: 10px;
            :hover {
                color: #1473e6;
            }
        }
    }
    .code-editor-container {
        flex: 1;
        height: auto;
        position: relative;
        .code-editor-textarea {
            left: 0;
            top: 0;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            scrollbar-width: thin;
            padding-right: 4px;
            overflow-y: scroll;
            height: calc(100% - 50px) !important;
            padding: 10px;
            font-size: 14px;
            line-height: 1.5;
            border: 1px solid var(--line-color);
            border-radius: 10px;
            background-color: var(--background);
        }
        .error-text {
            padding-top: 8px;
        }
    }
    .editor-iframe-preview {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: auto;
        .editor-iframe-preview-header {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--editor-header-background);
            height: 50px;
            border-radius: 0px 10px 0px 0px;
            border-left: 1px solid var(--line-color);
            > p {
                font: 600 1.4rem/1.8rem var(--font-family);
            }
        }
        iframe {
            border: 1px solid var(--line-color);
            border-radius: 5px;
            flex: 1;
            height: 100%;
            body {
                color: var(--background-sc-rgb);
            }
        }
    }
}
.gallery-popover-modal {
    .modal-content {
        min-width: 500px;
        min-height: 500px;
    }
    .modal-body {
        width: 100%;
        padding: 1rem;
        height: 100%;
        min-height: 380px;
        display: flex;
        .loader-holder {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .gallery-popover-container {
        .gallery-popover-images-container {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            height: 70vh;
            overflow-y: scroll;
            gap: 1rem;
            padding: 1rem;
            img {
                cursor: pointer;
                transition: box-shadow 0.3s, border-radius 0.3s, scale 0.3s;
                &:hover {
                    box-shadow: 0px 0px 10px 1px;
                    border-radius: 5px;
                    scale: 1.05;
                }
            }
        }
    }
}
.html-editor-container.error-color {
    border: 1px solid rgba(207, 0, 15, 1);
}
