.view-theme-dark {
    background: var(--page-background, #f9fbfc);
    color: rgba(var(--background-sc-rgb), 0.75);
    --background: #171c26;
    --background-hover: #262f3f;
    --background-sc: #fff;
    --background-rgb: 23, 28, 38;
    --background-sc-rgb: 255, 255, 255;
    --page-background: #0b1017;
}

.view-theme-light {
    --custom-color-1: rgba(60, 64, 67, 1);
    --custom-color-2: rgba(60, 64, 67, 0.5);
    --custom-color-3: rgba(60, 64, 67, 0.8);
    --line-color: rgba(230, 230, 230, 1);
    --header-primary-color: var(--gun-powder);
    --editor-header-background: #f9f9f9;
}
.view-theme-dark {
    --custom-color-1: rgba(255, 255, 255, 0.75);
    --custom-color-2: rgba(255, 255, 255, 0.4);
    --custom-color-3: rgba(255, 255, 255, 0.4);
    --line-color: rgba(255, 255, 255, 0.1);
    --header-primary-color: var(--c-bunker);
    --editor-header-background: rgba(75, 153, 100, 0);
}

// Charts Modal colors START
.view-theme-dark {
    .chart-overflow-holder {
        background-color: var(--background);
    }
    .highcharts-title {
        color: rgba(var(--background-sc-rgb), 0.75) !important;
    }
    .chart-overflow-holder.charts-donut-chart .highcharts-title {
        background-color: var(--background);
    }
    .chart-overflow-holder.charts-pie-chart .highcharts-graph,
    .chart-overflow-holder.charts-donut-chart .highcharts-graph {
        fill: rgba(var(--background-sc-rgb), 0.1);
    }
}
// Charts Modal colors END
