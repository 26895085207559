.crm-campaign-deep-view {
    width: 100%;
    height: 100%;
    padding: 1.8rem 2rem;
    &-inner {
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        background-color: var(--background);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        display: grid;
        grid-template-rows: auto 11fr;
        grid-template-areas:
            'deep-view-top'
            'deep-view-bottom';
    }
    .crm-custom-tabs-content-wrapper {
        height: 100%;
        overflow: hidden;
        .chart-overflow-holder {
            border-radius: 1rem;
            background-color: transparent;
        }
    }
    .paper.with-shadow {
        box-shadow: none;
    }
    .crm-campaign-report-block > .crm-custom-tabs-btn-panel li:hover {
        border-color: transparent;
    }
    .crm-campaign-report-block > .crm-custom-tabs-btn-panel li.active:hover {
        border: 1px solid var(--line-color);
        border-bottom: 1px solid var(--background);
    }
    .crm-statics-segment-data-table-name {
        font-size: 14px;
        font-weight: 600;
        color: var(--custom-color-1);
    }
    .module-header {
        padding: 0px 0px 0px 20px;
        li {
            gap: 10px;
        }
        p:not(.crm-label-tag) {
            font-size: 14px;
            font-weight: 600;
            color: #3c4043;
        }
    }
}
.crm-campaign-deep-view-inner {
    .crm-campaign-deep-view-date-picker {
        padding: 0 2rem;
        border-bottom: 1px solid var(--line-color);
    }
    .crm-campaign-deep-view-content {
        padding: 2rem;
    }
}
.activity-metrics-holder {
    width: 100%;
    height: 100%;
    position: relative;
    .crm-campaign-report-block {
        margin-top: 2rem;
    }

    .crm-custom-tabs-content > .crm-tab-content {
        border-radius: 0 0 20px 20px;
    }
}

.activity-metrics-holder-inner {
    display: grid;
    grid-template-rows: auto 11fr;
    grid-template-areas: 'metrics-top' 'metrics-bottom';
    grid-row-gap: 2rem;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //overflow-x: hidden;
    //overflow-y: auto;
    .ta-nested-child-holder.table-empty-holder {
        min-height: 49rem;
    }
}
.activity-metrics-section.paper {
    border-left: 2rem solid transparent;
    border-right: 2rem solid transparent;
    border-bottom: 2rem solid transparent;
    position: relative;
}
.activity-metrics-section {
    .module-header {
        justify-content: end;
    }
}
.crm-custom-tabs-cnt {
    display: grid;
    grid-template-rows: auto 11fr;
    grid-template-columns: 12fr;
    grid-area: metrics-bottom;
    width: 100%;
    height: 100%;
    .crm-custom-tabs-content-wrapper {
        height: 100%;
        border: none;
        border-radius: unset;
        padding: unset;
        overflow-y: auto;
        .crm-campaign-info-detail-modal-body {
            height: 100%;
            overflow-y: auto;
        }
    }
}
.crm-campaign-info-popup {
    .crm-custom-tabs-content {
        .crm-tab-content.active {
            border-radius: unset;
        }
    }
}
.crm-campaign-deep-view-date-picker {
    width: 100%;
    height: 50px;
    grid-area: deep-view-top;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .popover-track {
        max-width: 360px;
    }
    .breadcrumbs-link {
        text-transform: capitalize;
        color: var(--custom-color-1);
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        &.active {
            color: var(--hero);
        }
    }
    .bread-crumbs-holder {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 0.3rem;
            width: 3.4rem;
            background: var(--hero);
            bottom: -0.6rem;
            left: 0.4rem;
        }
        i {
            font-size: 2.2rem;
        }
    }
}
.crm-campaign-deep-view-content {
    width: 100%;
    height: 100%;
    grid-area: deep-view-bottom;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab-switcher {
        display: none;
    }
    .crm-campaign-report-block {
        display: grid;
        grid-template-rows: auto 11fr;
        width: 100%;
        height: 100%;
        .crm-custom-tabs-btn-panel {
            margin-bottom: -1px;
            p {
                font-size: 1.4rem;
                font-weight: 600;
                color: var(--custom-color-1);
            }
        }
    }
}
.details-page-wrapper-inner {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        'c-general-details c-activity'
        'c-tracked-links   c-activity';
    grid-gap: 2rem;
    background-color: var(--background);
    border-radius: 0 0 2rem 2rem;
    .section-holder {
        height: 100%;
    }
}
.campaign-general-details {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.campaign-activity {
    grid-column: 2 / -1;
    grid-row: 1 / 3;
    .section-holder {
        padding: 0;
    }
    .section-head {
        padding: 0 2rem;
    }
    .section-body {
        .paper.with-shadow {
            border-left: unset;
            border-right: unset;
        }
    }
}
.campaign-tracked-links {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}
// General details content START
.general-details-content {
    display: flex;
    width: 100%;
    gap: 20px;
    padding: 2rem;
    .react-loading-skeleton {
        height: 20px !important;
    }
}
.general-details-column {
    flex: 1;
}
.g-details-title {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--custom-color-2);
    margin-bottom: 1.2rem;
}
.g-details-subtitle {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--custom-color-1);
    &.active {
        color: var(--hero);
        cursor: pointer;
    }
}
.general-details-column-info {
    margin-bottom: 2.3rem;
}
// General details content END

// Details (Campaign tracked Links) START
.c-tracked-list-content {
    display: flex;
    width: 100%;
    &-item {
        flex: 0 0 33%;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--custom-color-2);
        &:not(:last-child) {
            margin-right: 2rem;
        }
    }
}
// Details (Campaign tracked Links) END

// Campaign report visualisation START
.c-report-visual-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}
.c-report-visual-wrapper-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    &-item {
        .section-holder {
            padding: 0;
            overflow: hidden;
            .section-head {
                padding: 0 2rem;
            }
            .section-body {
                padding: 1.5rem;
                background-color: #f9fbfc;
            }
        }
    }
}
// Campaign report visualisation END

// Activity Metrics START
.activity-metrics-holder {
    > .section-holder {
        padding: 0;
        overflow: hidden;
        .section-head {
            padding: 0 2rem;
        }
    }

    .crm-custom-tabs-cnt {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        > .crm-custom-tabs-btn-panel {
            border: 1px solid var(--line-color);
            border-radius: 20px 20px 0 0;
            padding: 0 2rem;
            border-bottom: 0;
            height: 50px;
        }

        .crm-common-table {
            position: relative;
            height: 100%;

            .paper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .table-holder {
                    height: 100%;
                }
            }
        }
    }
}

.c-deep-view-block {
    display: flex;
    background-color: var(--page-background, #f9fbfc);
    padding: 2rem;
    border-radius: 0 0 20px 20px;
}
.c-deep-view-block-item {
    flex: 1;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.05);
    background-color: var(--background);
    padding: 2rem;
    &:not(:last-child) {
        margin-right: 2rem;
    }
    &-title-wrapper {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0.5rem;
            left: 0;
            width: 0.8rem;
            height: 0.8rem;
            background-color: currentColor;
            border-radius: 50%;
        }
    }
    &-title {
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--custom-color-3);
        padding-left: 1.7rem;
    }
    &-subtitle {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--custom-color-1);
    }
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &-title-with-tooltip-wrapper {
        display: flex;
        align-items: center;
        > .icon {
            margin-bottom: 1rem;
            font-size: 2rem;
            color: var(--custom-color-3);
        }
    }
}
.color-pink {
    color: rgb(217, 135, 222);
}
.color-yellow {
    color: rgb(255, 220, 83);
}
.color-green {
    color: rgb(95, 222, 150);
}
.color-blue {
    color: rgb(78, 149, 233);
}
// Activity Metrics END

.crm-custom-tabs-content {
    width: 100%;
    height: 100%;
    .crm-tab-content {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 2rem;
        .section-holder {
            width: 100%;
        }

        .section-body {
            position: relative;
            width: 100%;
            height: 100%;
        }
        .live-monitoring-table {
            display: grid;
            grid-template-columns: 12fr;
            grid-template-rows: auto 11fr;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .module-header {
            border-bottom: 1px solid var(--line-color);
        }
    }

    .live-monitoring-table-header {
        display: grid;
        grid-template-columns: 15% 10% 10% 10% auto;
        align-items: center;
        height: 4.2rem;
        background-color: rgba(var(--background-sc-rgb), 0.04);
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
        border: 1px solid var(--line-color);
        border-right: unset;
        border-left: unset;

        & > div {
            font-size: 14px;
            font-weight: bold;

            &:first-child {
                padding-left: 52px;
            }
        }

        p {
            color: var(--custom-color-1);
            font-weight: bold;
            &:first-child {
                width: 130px;
            }
        }
    }

    .live-monitoring-table-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100% - 130px);
        overflow-x: hidden;
        overflow-y: overlay;

        & > button:last-child {
            margin: 5px 5px 0 5px;
        }

        .live-monitoring-table-content-inner {
            display: grid;
            grid-template-columns: 15% 10% 10% 10% auto;

            &:not(:last-child) {
                border-bottom: 1px solid var(--line-color);
            }

            &:nth-child(even) {
                background-color: rgba(var(--background-sc-rgb), 0.02);
            }

            &:hover {
                background-color: rgba(var(--hero-rgb), 0.08);
            }

            .live-monitoring-t-row {
                display: flex;
                align-items: center;
                height: 42px;

                p {
                    font-size: 14px;
                    font-weight: 600;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

            .live-monitoring-date-block {
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 52px;

                i {
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                    left: 15px;
                    color: var(--danger);
                    font-size: 19px;
                    padding-right: 10px;

                    &.bc-icon-error {
                        color: var(--danger);
                    }

                    &.bc-icon-success {
                        color: var(--confirm);
                    }
                }
            }
        }
    }
}

.crm-campaign-report-block {
    .crm-custom-tabs-content > .crm-tab-content {
        border: 1px solid var(--line-color);
    }
}

.crm-campaign-report-block {
    > .crm-custom-tabs-content-wrapper {
        > .crm-custom-tabs-content {
            > .crm-tab-content {
                &:first-child {
                    border-top-left-radius: 0;
                }
            }
        }
    }
}
.crm-campaign-report-block > .crm-custom-tabs-btn-panel li.active {
    z-index: 9;
    button {
        p {
            color: var(--hero);
        }
    }
}

.c-report-visual-wrapper-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2rem;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.live-monitoring-section {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin-top: -1px;
    border-top: 1px solid rgba(#000000, 0.1);
    .live-monitor-filter {
        position: relative;
        display: flex;
        padding: 2rem;
        > div {
            margin-right: 2rem;
        }
    }
    .d-toolbar-content {
        z-index: 100;
    }
}
.c-report-visual-wrapper-inner-item {
    width: 100%;
    height: 100%;
    .report-visualization {
        width: 100%;
        height: 100%;
        min-height: 580px;
        border: 1px solid var(--line-color);
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        .empty-state-holder {
            height: calc(100% - 5.4rem);
        }
        .loader-holder {
            display: flex;
            height: 100%;
            justify-content: center;
        }
    }
}

.campaign-bonus-wrap {
    width: 100%;
    height: 100%;
    position: relative;
}

.campaign-bonus-wrap-inner {
    display: grid;
    grid-template-rows: 6fr 6fr;
    grid-template-areas: 'bonus-top' 'bonus-bottom';
    grid-row-gap: 2rem;
    position: absolute;
    padding: 2rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.bonus-currency {
    grid-area: bonus-top;
    border: 1px solid var(--line-color);
    border-radius: 20px;
    min-width: 0;
    overflow: hidden;
    .empty-state-holder {
        min-height: calc(100% - 50px);
    }
}

.bonus-sum-holder {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));
    grid-auto-rows: 100px;
    grid-gap: 20px;
    width: 100%;
    height: calc(100% - 50px);
    max-height: 258px;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bonus-sum-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: var(--background);
    .title-currency {
        font-size: 14px;
        line-height: 19px;
        color: rgba(60, 64, 67, 0.8);
    }
    .currency-amount {
        font-size: 16px;
        line-height: 22px;
        color: #3c4043;
        font-weight: 600;
    }
}

.bonus-received-players {
    grid-area: bonus-bottom;
    min-width: 0;
    border: 1px solid var(--line-color);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    .table-empty-holder {
        min-height: calc(100% - 72px);
    }
    .d-toolbar-content {
        z-index: 100;
    }
}
.bonus-received-players-inner {
    display: grid;
    grid-template-rows: auto 11fr;
    grid-template-columns: 12fr;
    grid-area: metrics-bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .crm-common-table {
        position: relative;
        height: 100%;

        .paper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .table-holder {
                height: 100%;
            }
        }
    }
}

.details-page-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2rem;
}
.details-section {
    border: 1px solid var(--line-color);
    border-radius: 20px;
    height: 100%;
    overflow: hidden;
    .table-empty-holder {
        min-height: 39rem;
    }
    .module-header {
        border-bottom: 1px solid var(--line-color);
    }
}
.tracked-links-section {
    padding: 2rem;
    .tracked-link {
        display: flex;
        align-items: center;
        line-height: 30px;
        a {
            span {
                font-weight: bold;
                text-decoration: none;
                color: var(--hero);
                word-break: break-all;
            }
        }
        button {
            padding-left: 30px;
            font-weight: bold;
            cursor: pointer;
            color: var(--hero);
        }
    }
}
.clicked-tracked-modal.c-tracked-modal-wrapper {
    .modal-content {
        width: 100%;
        max-width: 1300px;
    }
    .modal-body {
        min-height: 49.6rem;
    }
    .modal-body {
        position: relative;
        padding: 0;
        .paper.full-radius {
            border-radius: unset;
        }
    }
}
.campaign-activity-section {
    padding-top: 2rem;
}
.report-visualization {
    display: grid;
    grid-template-rows: auto 11fr;
    width: 100%;
    height: 100%;
    min-height: 580px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: relative;
    .empty-state-holder {
        height: calc(100% - 5.4rem);
        .details-section {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            height: 100%;

            .table-empty-holder {
                min-height: 39rem;
            }
            .table-empty-holder {
                min-height: 39rem;
            }
            .tracked-links-section {
                padding: 2rem;
            }
            .campaign-activity-section {
                padding-top: 2rem;
            }
        }
        .loader-holder {
            display: flex;
            height: 100%;
            justify-content: center;
        }
    }
    .campaign-kpi {
        grid-area: metrics-top;
        width: 100%;
        height: 100%;
    }
    .campaign-kpi {
        border: 1px solid var(--line-color);
        border-radius: 20px;
        color: var(--custom-color-1);
    }
    .campaign-kpi {
        grid-area: metrics-top;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 20px;
    }
}

.campaign-report-ab-test-tab-container {
    padding: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .tabs-holder {
        height: 100%;

        & > .tabs-content-holder {
            padding: 2rem 0.5rem 1rem 2rem !important;
            height: calc(100% - 4.3rem);

            .campaign-report-ab-test-scrollable-container {
                overflow-y: scroll;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 2rem;

                .grid-items-group {
                    width: calc(100% + 1rem) !important;
                }

                .campaign-report-ab-test-general-details-container {
                    margin-right: 1rem;

                    & > .details-section > .ab-test-general-details-content {
                        width: 100%;
                        padding: 2rem;

                        .ab-test-general-details-column-info-item {
                            flex-direction: column;
                        }
                    }
                }

                & > .alert-holder {
                    margin-right: 1rem;
                    width: unset;
                }

                .campaign-report-ab-test-charts-container {
                    .details-section {
                        width: 100%;

                        & > .module-header {
                            .module-title {
                                display: flex;
                                align-items: center;
                            }
                        }

                        & > .general-details-content {
                            padding: 1rem;
                            display: flex;
                            flex-direction: column;
                            gap: unset;

                            & > .percentage-info-section {
                                display: flex;
                                justify-content: space-between;
                                padding: 0 1rem 0.5rem 1rem;

                                .percentage-danger-zone {
                                    color: var(--status-error-color);
                                }
                                .percentage-normal-zone {
                                    color: var(--status-success-color);
                                }
                            }
                            & > .bar-chart {
                                min-height: unset;
                                margin-top: 0.5rem;

                                .highcharts-container {
                                    width: 100% !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
