.c-j-report-grand-wrapper {
    padding: unset !important;
    &.details-page-wrapper {
        overflow: unset;
    }

    .live-monitoring-section-wrapper {
        position: relative;
        min-height: 100px;
    }

    .live-monitoring-table-wrp {
        width: 100%;
        height: 100%;

        .live-monitoring-table-header {
            grid-template-columns: repeat(5, 1fr);
        }

        .live-monitoring-table-content {
            height: calc(100% - 232px);
        }

        .live-monitoring-table-content-inner {
            grid-template-columns: repeat(5, 1fr) !important;
        }

        .live-monitoring-t-row {
            &:first-child {
                padding-left: 52px;
            }
            button {
                padding-left: 30px;
                font-weight: bold;
                cursor: pointer;
                color: var(--hero);
            }
        }
    }
}
.track-link-click-count {
    color: var(--hero);
}

.c-j-report-page-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .table-holder {
        height: 496px;
    }
    .paper.with-shadow {
        box-shadow: unset;
    }
}

.c-j-report-page-inner {
    display: grid;
    grid-template-columns: 3fr 6fr;
    grid-column-gap: 20px;
    width: 100%;
    height: 100%;
}

.c-j-report-players-table {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-j-report-page-table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;

    .c-j-report-download {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
    }

    .download-all,
    .download-toolbar {
        margin-left: auto;
        z-index: 5;
    }

    .download-toolbar {
        position: unset;
        top: unset;
        right: unset;
        padding: unset;
    }

    .tracked-link-title {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--custom-color-1);
    }

    &:first-child {
        border-right: 1px solid var(--line-color);
    }
    &:last-child {
        border-left: 1px solid var(--line-color);
    }

    .crm-common-table.default-height {
        --table-max-height-additional-calc: 494px;
    }
    // .crm-common-table {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    // }
}

.c-tracked-modal-wrapper {
    .modal-body {
        position: relative;
        padding: 0;
        .paper.full-radius {
            border-radius: unset;
        }
    }
}
