.segment-list-modal {
    padding: 6.8vh 2.4vw;
    .modal-content.s-default {
        display: grid;
        grid-template-rows: auto 11fr auto;
        min-width: 79.8rem;
        max-height: 100%;
    }
    .modal-body {
        display: grid;
        grid-template-rows: auto 11fr;
        overflow: hidden;
        min-height: 380px;
    }
    .crm-common-table {
        --table-max-height-additional-calc: 530px;
    }
    .table-empty-holder {
        min-height: unset;
    }
    .left-content {
        .ellipsis-text {
            color: var(--custom-color-1);
        }
    }
    .title-holder {
        > li {
            > div {
                color: var(--custom-color-1);
                font-weight: 600;
            }
        }
    }

    .key-value-h {
        display: grid;
        grid-template-columns: 4fr 8fr;
        margin-bottom: 1.5rem;

        > li {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight: 600;
            font-size: 14px;
            margin: 0;

            &:first-child {
                opacity: 1;
                margin: 0;
            }

            &:last-child {
                color: var(--custom-color-1);
            }
        }
    }
    .empty-state-image {
        > img {
            display: block;
            height: 14vmin;
        }
    }
    .segment-modal-bottom {
        .segment-modal-b-content {
            .tabs-holder {
                .tabs-content-holder.tabs-content-holder {
                    height: unset;
                }
            }
        }
        .crm-dynamic-segment-filter-schema-wrapper {
            .crm-dynamic-segment-filter-schema {
                .title-holder {
                    min-height: 2rem;
                }
            }
        }
    }
}
.segment-list-modal:not(.crm-template-info-modal):not(.campaign-info-modal) {
    .modal-content {
        max-width: 120rem;
        width: 100%;
        max-height: 80rem;
        height: 100%;
        .segment-modal-b-content.c-s-b-content {
            padding: 0 2rem 0 2rem;
        }
        .modal-body {
            & > .loader-holder {
                margin: 25%;
            }
        }
        .crm-common-table {
            .loader-holder {
                margin: unset;
            }
        }
    }
}
.segment-list-modal {
    .modal-content {
        .crm-common-table {
            .loader-holder {
                margin: unset;
            }
        }
    }
}
.segment-modal-head {
    width: 100%;
    overflow-y: auto;
}

.segment-modal-bottom {
    .paper.with-shadow {
        box-shadow: unset;
        border: 0.1rem solid var(--line-color);
        border-radius: 1rem;
    }
    .segment-modal-info-inner {
        margin-bottom: 1.5rem;
        display: flex;
        width: 100%;
        .key-value-h {
            display: flex;
            flex-direction: column;
            height: unset;
            margin-bottom: unset;
            overflow: hidden;
            &:not(:last-child) {
                margin-right: 1rem;
            }
            span:first-child {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
                font-weight: 600;
            }
            li:first-child {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }
    }
}
.segment-modal-bottom {
    .segment-modal-head + .crm-common-table {
        .paper.with-shadow {
            box-shadow: unset;
            border: none;
            border-top: 0.1rem solid var(--line-color);
            border-radius: 0 0 1rem 1rem;
        }
        .paper {
            .scroll-holder.s-medium {
                height: 22.5rem !important;
            }
        }
    }
}
.segment-list-modal {
    .tabs-content-holder.c-type-box {
        padding: 2rem 0 0 0 !important;
    }
}
.segment-modal-bottom {
    display: grid;
    grid-template-rows: auto 11fr;
    width: 100%;
}
.segment-modal-b-content {
    width: 100%;
    max-height: 45vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 4px;
    &:only-child {
        grid-row: span 2;
    }
}
.segment-list-modal-head {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.segment-list-modal-h-text {
    font-weight: bold;
    color: var(--custom-color-1);
}
.preview-modal-contents {
    display: grid;
    margin: 18% 47.5%;
}
.preview-modal-info-message {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.36;
    color: var(--charcoal-grey);
    margin-left: -35%;
}
.preview-modal-info-icon {
    color: #2bc784;
    font-size: 550%;
}
.preview-modal-loader-message {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.36;
    color: var(--charcoal-grey);
}
.segment-list-modal-h-icons {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    right: 10px;
    top: 0;

    > button {
        margin-left: 1px;
    }
}
.report-popup-wrap {
    height: 100% !important;
    .modal-body {
        overflow: scroll;
    }
    .grid-holder {
        height: 100%;
    }
    .grid-items-group {
        height: 100%;
    }
    .grid-child {
        position: relative;
        height: 100%;
        &:first-child {
            .section-holder:first-child {
                width: calc(100% - 10px);
            }
        }
        .section-holder {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            overflow: hidden;
            .chart-lengend-block {
                display: flex;
                flex-direction: column;
            }
            .section-body {
                .table-holder:not(.content-empty) {
                    max-height: 500px !important;
                }
            }
        }
    }
}
.chart-custom-legend {
    padding: 0 2rem;
    ul {
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        li {
            width: 100% !important;
        }
    }
    button {
        display: none !important;
    }
    .legend-item {
        justify-content: flex-start;
        margin-bottom: 18px;
    }
    .count-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: var(--custom-color-1);
        font-size: 14px;
        font-weight: 600;
    }
    .item-name {
        width: 100%;
    }
}

.crm-info-segment-action-buttons-container {
    display: flex;
    justify-content: flex-end;
}
