.crm-customer-journey-info-modal {
    .modal-content {
        width: 1160px;
    }

    .modal-body {
        height: 630px;
        padding-left: 0;
        padding-right: 0;
        overflow-y: auto;

        .module-header,
        .paper-actions {
            display: none;
        }

        .loader-holder {
            height: 100%;
            justify-content: center;
        }

        .crm-custom-tabs-content-wrapper {
            padding: 0 2rem;

            .crm-tab-content {
                .crm-data-table {
                    .table-holder {
                        max-height: 525px;
                    }
                }
            }

            .general-details-content {
                padding: unset;
            }

            .key-value-items-details-section {
                padding-left: unset;
            }
        }

        .customer-journey-visualization-tab {
            .key-value-items-subtitle {
                display: flex;
            }

            .languages-container {
                display: flex;
                column-gap: 8px;
            }

            .tabs-content-holder {
                padding-bottom: 0 !important;
            }
        }

        .general-details-column {
            .crm-keyvalue-items-wrapper {
                .customer-journey-used-in-section {
                    .kv-label {
                        display: none;
                    }
                }
            }
        }
    }
}
