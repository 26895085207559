.crm-button-with-dropdown {
    position: relative;

    .btn:not(.c-icon) {
        padding: 0 4.2rem 0 2rem;
    }

    &_icon-box {
        position: absolute;
        background: transparent;
        width: 40px;
        height: 26px;
        top: 5px;
        right: 0;
        color: white;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        cursor: pointer;

        i {
            width: 25px;
            margin-left: 2px;
            padding-top: 1px;
        }
    }

    &_dropdown-box {
        min-width: 150px;
        position: absolute;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px rgba(112, 112, 112, 0.2);
        background: var(--background);
        right: 0;
        top: 40px;
        z-index: 6;
        display: flex;
        flex-direction: column;
        height: auto;

        p {
            font-size: 14px;
            font-weight: 600;
            color: var(--custom-color-1);
        }

        &_content-box {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            width: 100%;
            margin: 0 0 10px 0;
            cursor: pointer;
            height: 39px;
            padding: 0 2rem !important;
            white-space: nowrap;

            &:first-child {
                margin: 10px 0;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.03) !important;
            }
        }
    }
}
