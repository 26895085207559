.download-toolbar {
    position: absolute;
    top: 0.7rem;
    right: 0;
    z-index: 5;
    padding: 0 2rem;
}
.download-toolbar-inner {
    position: relative;
}
.d-toolbar-menu,
.d-toolbar-content {
    position: absolute;
    top: 4rem;
    right: 0;
    min-height: 9.5rem;
    background-color: var(--background);
    border-radius: 1rem;
    border: 0.1rem solid var(--line-color);
    padding: 0.5rem 0;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
}
.download-toolbar-info-group {
    display: flex;
}
.d-toolbar-menu {
    width: 15.2rem;
}
.d-toolbar-menu-row {
    display: flex;
    align-items: center;
    img {
        margin-right: 1rem;
    }
    i {
        margin-left: auto;
    }
    .option-container {
        &:hover {
            background: unset;
        }
    }
}
.d-toolbar-content {
    width: 20.6rem;
    .d-toolbar-menu-row {
        padding: 0 1rem;
    }
    .download-disable {
        .d-toolbar-menu-row {
            pointer-events: none;
            cursor: default;
            user-select: none;
            opacity: 0.5;
            transition: opacity 240ms linear;
        }
    }
}
.d-toolbar-icon-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8rem 1.4rem 1rem 1.4rem;
    border-bottom: 0.1rem solid var(--line-color);
    span {
        font-size: 1.4rem;
        font-weight: 600;
        color: #3c4043;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
}
.d-toolbar-icon {
    display: block;
    cursor: pointer;
    margin-right: 1rem;
}
.d-toolbar-menu {
    .d-toolbar-menu-row {
        padding: 1rem;
        min-height: 4.4rem;
        width: 100%;
    }
}
.d-toolbar-menu {
    .d-toolbar-menu-row {
        & > .loader-holder {
            margin: 0 10px 0 0;
        }
    }
}
.d-toolbar-menu-row {
    transition: background-color 180ms linear;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    span {
        font-size: 1.4rem;
        font-weight: 600;
        color: #3c4043;
    }
}
.d-toolbar-btn {
    span {
        display: flex;
        align-items: center;
    }
}
