@import './mixins';

.crm-report-list-page-wrapper {
    @include crm-list-column-title-icon-components;
}

.communication-list-wrapper {
    .crm-common-table {
        --table-max-height-additional-calc: 130px !important;
    }

    .table-header-filter-switcher-container {
        z-index: 0 !important;
        display: flex;
        align-items: center;
        margin-right: unset !important;
        column-gap: 10px;
        .crs-holder {
            .label-holder {
                .crs-label {
                    min-width: 60px;
                }
            }
        }
        .table-header-filter-container {
            display: flex;
            column-gap: 10px;
        }
    }
}
