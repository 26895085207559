.html-editors {
    padding: 0 2rem;
    margin-top: 2rem;

    .editor-tabs-container {
        display: inline-block;
        z-index: 10;
        position: relative;
        margin-bottom: 2.3rem;
    }

    .editor-wrapper {
        height: 600px;
        position: relative;
        padding: 0 0 35px;

        .error-text {
            color: var(--danger);
            position: absolute;
            bottom: 10px;
        }

        .editor-border-wrapper {
            border: 1px solid rgba(var(--background-sc-rgb), 0.1);
            height: 100%;

            > :first-child {
                height: 100%;
            }

            &.joditEditor {
                border: 1px solid transparent;
                border-radius: 0.9rem;
            }

            &.htmlCodeEditor {
                border: 1px solid transparent;
                border-radius: 1rem;
            }

            &.error {
                border-color: var(--danger);
            }
        }
    }
}
