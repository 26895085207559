.scheduler-modal-wrap {
    &.disable-scheduler {
        & > div:not(.switcher-label-holder) {
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
            transition: opacity 120ms linear;
        }
    }
    .modal-content {
        .modal-body {
            width: 100%;
            max-width: 1187px;
            max-height: 800px;
            padding: 20px 0 0 0;
            overflow-x: auto;

            .loader-holder {
                width: 100%;
                height: 100%;
                justify-content: center;
            }
        }

        .modal-head {
            height: 50px;
            margin: 0;
            color: var(--custom-color-1);
        }
    }

    .scheduler-modal-wrap-inner {
        display: flex;
        min-width: 978px;
        flex-direction: row;
        box-sizing: border-box;
        padding: 2rem 0;

        .scheduler-modal-wrap-inner-left {
            display: flex;
            flex-direction: column;
            max-width: 338px;
            width: 100%;
            border-right: 1px solid var(--line-color);
            color: var(--custom-color-1);
            font-size: 14px;
            font-weight: 600;
            padding: 0 2rem;

            > div {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .information-message {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 225px;
        }

        .repeat-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;

            .dropdown-holder {
                max-width: 14rem;
            }

            .crs-item {
                padding: 0 10px;
            }
        }

        .scheduler-modal-wrap-inner-right {
            display: flex;
            flex-direction: column;
            padding: 0 2rem;
            color: var(--custom-color-1);
            font-size: 14px;
            font-weight: 600;

            .scheduler-modal-wrap-inner-right-inside {
                display: flex;
                flex-direction: column;

                > div {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .days-button {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 0 20px;

                    button {
                        padding: 0 20px;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        margin-bottom: 6px;
                        border-radius: 21px;
                        background-color: rgba(20, 115, 230, 0.06);

                        &:hover {
                            background-color: var(--hero);
                            color: #ffffff;
                        }

                        &.active {
                            background: var(--hero);
                            color: var(--hero-sc);
                        }
                    }

                    button {
                        margin-bottom: 10px;
                    }
                }
            }

            .cha-ra-group.t-tab {
                box-shadow: none;
            }

            .crs-holder {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .crs-label {
                text-align: center !important;
                color: var(--hero);
                border-radius: 21px;
                background-color: #f9fbfc;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 150px;
            }
        }

        .date-block {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .c-date-picker {
            width: 100%;
            min-width: 130px;
        }
        .start-date-block {
            display: flex;
            flex-direction: row;
            font-weight: 600;
            height: 70px;

            &-heading {
                flex: 0 0 auto;
                margin-right: 10px;
                font-weight: inherit;
                line-height: 3.6rem;
            }

            .information-message {
                padding: 0 2rem;
                white-space: unset;
            }

            .input-holder {
                max-width: 225px;
            }

            .popover-track {
                width: auto;
            }
        }

        .start-time-block {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            height: 50px;

            .time-picker-holder {
                width: unset;
            }

            .input-holder {
                width: 14rem;
            }

            .input-element-relative {
                display: flex;
                justify-content: center;

                input {
                    text-align: center;
                }
            }
        }
    }

    .scheduler-modal-wrap-inner-daily {
        display: flex;
        align-items: center;
        flex-direction: column;

        .input-holder {
            width: 100px;
            color: var(--custom-color-1);

            .input-element::-webkit-input-placeholder {
                color: var(--custom-color-1);
            }
        }

        .dropdown-holder {
            width: 100%;
            max-width: 256px;
            height: 42px;
            margin-top: 25px;

            .input-holder {
                width: 100%;
                height: 100%;
                --input-element-border-color: rgba(var(--background-sc-rgb), 0.3);

                .input-structure {
                    height: 100%;
                }
            }
        }
    }

    .scheduler-modal-wrap-inner-weekly {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 50px;

        .dropdown-holder {
            width: 100%;
            max-width: 256px;
            height: 42px;
            margin-top: 25px;

            .input-holder {
                width: 100%;
                height: 100%;
                color: var(--custom-color-1);
                --input-element-border-color: rgba(var(--background-sc-rgb), 0.3);

                .input-element::-webkit-input-placeholder {
                    color: var(--custom-color-1);
                }

                .input-structure {
                    height: 100%;
                }
            }
        }
    }

    .scheduler-modal-wrap-inner-monthly {
        display: flex;
        align-items: center;
        flex-direction: column;

        .input-holder {
            width: 100px;
            color: var(--custom-color-1);

            .input-element::-webkit-input-placeholder {
                color: var(--custom-color-1);
            }
        }

        .dropdown-holder {
            width: 100%;
            max-width: 256px;
            height: 42px;
            margin-top: 25px;

            .input-holder {
                width: 100%;
                height: 100%;
                --input-element-border-color: rgba(var(--background-sc-rgb), 0.3);

                .input-structure {
                    height: 100%;
                }
            }
        }

        .scheduler-days-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .scheduler-days-inner {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .scheduler-modal-wrap-inner-annually {
        display: flex;
        align-items: center;
        flex-direction: column;

        .input-holder {
            width: 100px;
            color: var(--custom-color-1);

            .input-element::-webkit-input-placeholder {
                color: var(--custom-color-1);
            }
        }

        .dropdown-holder {
            width: 100%;
            max-width: 256px;
            height: 42px;
            margin-top: 25px;

            .input-holder {
                width: 100%;
                height: 100%;
                --input-element-border-color: rgba(var(--background-sc-rgb), 0.3);

                .input-structure {
                    height: 100%;
                }
            }
        }

        .scheduler-days-wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 50px;

            .scheduler-days-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 40px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .scheduler-modal-info-wrapper {
            font-size: 1.3rem;
            color: var(--custom-color-1);
            font-weight: 600;
            padding: 10px 0;
            width: calc(90%);
            margin: 0 auto;
            text-align: center;
            overflow-wrap: anywhere;

            &.width-sm {
                max-width: 350px;
            }

            &.width-md {
                max-width: 650px;
            }

            &.width-lg {
                max-width: 950px;
            }

            .scheduler-modal-run-info-wrapper {
                .scheduler-modal-run-info {
                    display: flex;
                    justify-content: center;

                    p {
                        margin-right: 10px;
                    }

                    button {
                        color: var(--hero);
                    }
                }
            }
        }
    }

    .days-wrap {
        width: 226px;
        height: 226px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 20px;
        margin-top: 15px;
        margin-bottom: 20px;
        border: solid 1px rgba(112, 112, 112, 0.2);
        padding: 14px;
        transition: 240ms all;
        gap: 2px;
        box-sizing: content-box;
        font-weight: 600;
        background-color: rgba(var(--background-sc-rgb), 0.03);
        color: rgba(60, 64, 67, 0.5);

        &.active {
            background-color: var(--background);
            color: var(--custom-color-1);
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            font-size: 16px;
            line-height: 22px;
            border-radius: 50%;
            transition: 240ms all;

            &:hover {
                background: rgba(var(--background-sc-rgb), 0.03);
            }

            &.active {
                background-color: var(--hero);
                color: #fff;
            }
        }

        &.disabled {
            background-color: rgba(60, 64, 67, 0.5);
            pointer-events: none;
        }
    }

    .months-wrap {
        width: 226px;
        height: 226px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 20px;
        margin-top: 15px;
        margin-bottom: 20px;
        border: solid 1px rgba(112, 112, 112, 0.2);
        padding: 15px;
        transition: 240ms all;
        grid-gap: 0 11px;
        gap: 27px 11px;
        box-sizing: content-box;
        font-weight: 600;
        background-color: rgba(var(--background-sc-rgb), 0.03);
        color: rgba(60, 64, 67, 0.5);

        &.active {
            background-color: var(--background);
            color: var(--custom-color-1);
        }

        button {
            width: 68px;
            height: 36px;
            font-size: 16px;
            line-height: 22px;
            border-radius: 20px;

            &:hover {
                background: rgba(var(--background-sc-rgb), 0.03);
            }

            &.active {
                background-color: var(--hero);
                color: #ffffff;
            }
        }

        &.disabled {
            background-color: rgba(60, 64, 67, 0.5);
            pointer-events: none;
        }
    }

    .years-wrap {
        position: relative;
        width: 226px;
        height: 226px;
        border-radius: 20px;
        margin-top: 15px;
        margin-bottom: 20px;
        border: solid 1px rgba(112, 112, 112, 0.2);
        padding: 15px;
        box-sizing: content-box;
        overflow: hidden;

        &.active .years-wrap-inner {
            background-color: var(--background);
            color: var(--custom-color-1);
        }

        .years-wrap-inner {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding-top: 15px;
            padding-left: 15px;
            overflow-x: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-radius: 20px;
            transition: 240ms all;
            gap: 0 11px;
            font-weight: 600;
            background-color: rgba(var(--background-sc-rgb), 0.03);
            color: rgba(60, 64, 67, 0.5);

            button {
                width: 68px;
                height: 36px;
                font-size: 16px;
                line-height: 22px;
                border-radius: 20px;

                &:hover {
                    background: rgba(var(--background-sc-rgb), 0.03);
                }

                &.active {
                    background-color: var(--hero);
                    color: #ffffff;
                }
            }

            &.disabled {
                background-color: rgba(60, 64, 67, 0.5);
                pointer-events: none;
            }
        }
    }

    .every-days-block {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .input-holder {
            margin: 0 15px;
        }
    }

    .title-text {
        margin-top: 20px;
        color: var(--custom-color-1);
        font-size: 14px;
        font-weight: 600;
    }

    .title-next-text {
        margin-top: 10px;
        color: rgba(var(--background-sc-rgb), 0.75);
        font-size: 12px;
        font-weight: normal;
    }

    .or-text {
        position: relative;
        margin-top: 15px;
        color: var(--custom-color-1);
        font-size: 14px;
        font-weight: 600;

        &:before {
            content: '';
            position: absolute;
            width: 110px;
            height: 1px;
            top: 8px;
            left: -120px;
            background-color: var(--line-color);
        }

        &:after {
            content: '';
            position: absolute;
            width: 110px;
            height: 1px;
            top: 8px;
            right: -120px;
            background-color: var(--line-color);
        }
    }

    .every-text {
        color: var(--custom-color-1);
        font-size: 14px;
        font-weight: 600;
    }

    .choose-text {
        margin-top: 15px;
        //color: rgba(60, 64, 67, 0.5);
        color: var(--custom-color-1);
        font-size: 14px;
        font-weight: 600;
    }

    .weeks {
        max-width: 292px;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px 20px;

        button {
            width: 58px;
            height: 36px;
            opacity: 1;
            border-radius: 21px;
            background-color: rgba(var(--background-sc-rgb), 0.03);
            color: var(--custom-color-1);
            font-weight: 600;
            font-size: 14px;

            &.active {
                background-color: var(--hero);
                color: #ffffff;
            }
        }
    }
    .sheduler-info-message {
        font-weight: 600;
    }
}
.scheduler-info-message-without-switcher {
    font-weight: 600;
    padding-left: 2rem;
    padding-top: 2rem;
}

.providers-wrap {
    padding: 0 2rem;

    input {
        height: 4.2rem;
    }
}

.providers-wrap-row {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 2rem;
}

.providers-wrap-inner {
    margin-bottom: 6px;

    .input-holder {
        margin-top: 2px;
    }

    &:last-child {
        margin-right: 0;
    }

    .ellipsis-text {
        padding-left: 10px;
        font-weight: 600;
        color: var(--hero);
    }

    .tag-c {
        background-color: rgba(20, 115, 230, 0.1);
    }
}

.weekly-wrap {
    flex-direction: column;
    text-align: center;
}

.shedule-title {
    font-weight: bold;
    color: var(--custom-color-1);

    i {
        position: absolute;
        left: 20px;
        top: 0;
        display: flex;
        align-items: center;
        height: 100%;
        color: var(--custom-color-1);
        cursor: pointer;
    }
}
.sheduler-info-container {
    display: flex;
    .upcoming-executions {
        margin-left: 2rem;
        display: flex;
        span {
            margin-left: 1rem;
            font-weight: 600;
        }
    }
    .recur-count {
        display: flex;
        font-weight: 600;
        color: var(--custom-color-1);
        button {
            color: var(--hero);
            margin-left: 1rem;
            font-weight: 600;
        }
    }
}

.next-run-date-popover-container {
    .popover-content {
        .popover-body {
            .scroll-content {
                display: flex;
                flex-direction: column;

                p {
                    padding: 0.7rem 1rem;
                    font-weight: 600;
                    border-radius: 8px;
                    &:hover {
                        background: rgba(0, 0, 0, 0.03);
                    }
                }
            }
        }
    }
}
.report-schedule-info-title-container {
    display: flex;
    p {
        margin-left: 10px;
    }
}
