.crm-static-segment-popup-body.non-customer-body {
    .crm-common-table {
        &.default-height {
            --table-max-height-additional-calc: unset;
        }
    }
    .title-wrapper {
        padding-left: 2rem;
    }
    .non-customer-inputs-group {
        display: flex;
        gap: 2rem;
        padding: 0 2rem 3rem 2rem;
    }
    .non-customer-input {
        width: 100%;
    }
    .table-holder:not(.content-empty) {
        --table-max-height-additional-calc: 260px;
        max-height: calc(100vh - 260px - var(--table-max-height-additional-calc, 0px));
    }
    .form-row-wrap {
        padding: 0 20px;
        margin-top: 20px;
    }
    .form-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid var(--line-color);
        border-radius: 20px;
        background: var(--background);
        .left-content {
            display: flex;
            align-items: center;
            flex: auto;
            position: relative;
            overflow: hidden;
            .left-inner {
                max-width: 100%;
            }
        }
    }
    .form-row-header {
        height: 5rem;
        border-bottom: 1px solid #e6e6e6;
        > div {
            padding: 0 20px;
            cursor: pointer;
        }
    }
    .form-row-inner {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 3rem 2rem;
        .dropdown-holder {
            max-width: 270px;
        }
    }
}
.crm-page-headline + .crm-static-segment-popup-body .crm-overlay-custom-height {
    height: 246px !important;
    & + .crm-static-segment-data-table {
        --table-max-height-additional-calc: 465px;
    }
}
.crm-static-segment-data-table {
    position: relative;
    height: 100%;
    .ta-cell.a-square:empty {
        display: none;
    }
}
