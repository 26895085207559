.input-holder .input-swap-label .input-swap .ellipsis-text {
    font-size: 12px;
}

.ellipsis-text {
    font-weight: 600;
    font-size: 14px;
}

.dropdown-item {
    > p {
        > span {
            font-weight: 600;
        }
    }
}

// Filters STart
// .holder-wrapper {
//     .input-element {
//         height: 42px;
//     }
// }

.holder-footer {
    .ellipsis-text {
        font-weight: 600;
    }

    > button {
        &:first-child {
            margin-right: 4px;
        }

        &:last-child {
            margin-left: 4px;
        }
    }
}

// Filters END

// Module title START
.module-title {
    > span {
        font-weight: 600;
    }
}

// Module title END

// Table styles START
.table-heading-text {
    font-weight: bold !important;
}

.ta-row {
    .ellipsis-text {
        font-weight: 600;

        .bc-icon-image {
            color: var(--c-denim);
            opacity: 0.7;
        }
    }
}

// Table styles END

// live And Archive popover START
.crm-data-table-top-action-bar-popover {
    .option-container {
        border-right: 30px solid transparent;
        overflow: unset;

        &:before {
            content: '\e995';
            font: 28px betcore;
            width: 28px;
            height: 28px;
            line-height: 28px;
            right: -24px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            display: none;
        }

        &.active {
            &:before {
                display: block;
            }
        }
    }
}

// StaticSegmentInfo Popup
.segment-list-modal {
    .modal-body {
        padding: 2rem 0 0 0;
        //height: 581px;

        .key-value-h {
            height: 20px;
        }
    }

    .segment-modal-head {
        padding: 0 2rem;

        p.label {
            font-weight: 600;
        }
    }

    .key-value-h {
        li {
            display: flex;
            align-items: center;
            height: 20px;

            .btn {
                i {
                    order: 1;
                }
            }
        }
    }

    .segment-modal-bottom {
        > .title-wrapper {
            padding: 0 2rem;
        }

        .full-radius {
            border-radius: 0;
            border: none;
            border-top: 1px solid rgba(var(--background-sc-rgb), 0.1);
        }

        .paper-actions {
            display: none;
        }

        .segment-modal-b-content {
            width: 100%;
            max-height: 44vh;
            padding: 0;
        }

        .ta-pagination-holder {
            display: none;
        }
    }
}

.segment-info-holder {
    padding: 0 20px 20px 20px;
}

.static-segment-info-item {
    display: flex;

    i {
        line-height: 21px;
        margin-right: 6px;
        font-size: 2.4rem;
    }

    p {
        font-weight: 600;
    }
}

.popoverTagSection-bc:not(:last-child) {
    margin-bottom: 1.3rem;
}

.popoverTagSectionLabel-bc {
    color: var(--custom-color-1);
    font-weight: 600;
    margin-bottom: 1.3rem;
}

.used-in-popover {
    .used-in-content {
        width: 40rem;
        height: 26.7rem;
        padding: 1.5rem;
        .popover-body {
            height: 100%;
            overflow: auto;
        }
    }
}

.key-value-h:not(.card-list-col) > li.kv-label {
    opacity: 1 !important;
    margin: 0 !important;
}

.used-in-num {
    margin-right: 15px;
}

.used-in-button {
    font-weight: 600;
    display: flex;
    align-items: center;
    transition: color 300ms;

    &:hover {
        color: var(--hero);
    }

    i {
        font-size: 2.4rem;
        margin-left: 5px;
    }
}

.client-count-block {
    .chart-title {
        display: none;
    }

    .highcharts-title {
        font-size: 2.8rem !important;
        div {
            font-weight: 600 !important;
        }

        span {
            font-weight: 600 !important;
        }
    }

    .custom-legends-content {
        width: 100%;
    }
}

.crm-segment-custom-line {
    width: 100%;
    height: 1px;
    background: rgba(var(--background-sc-rgb), 0.05);
}

.modal-content {
    .section-holder {
        padding: 0 0 2rem 0;

        .section-head {
            padding: 0 2rem;
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                height: 0.3rem;
                width: 3.4rem;
                bottom: 0;
                background: var(--hero);
            }
            .title-text {
                color: var(--custom-color-1);
                position: relative;
            }
        }
    }
}

.modal-splash {
    &.custom-modal-content {
        top: 0;
        height: 100%;
        .modal-head {
            .ellipsis-text {
                position: relative;
                color: var(--custom-color-1);
            }
        }
    }
}

.kv-value-css {
    color: var(--custom-color-1);
}

.tagList-bc ul {
    flex-direction: row;
    flex-wrap: wrap;
    .st-c {
        margin-right: 1rem;
        margin-bottom: 1rem;
        &:nth-child(2) {
            min-height: auto;
        }
    }
}
.custom-modal-content {
    .modal-body {
        height: 590px;
    }
}
.custom-modal-content {
    .loader-holder {
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .scroll-content {
        margin-bottom: -16px !important;
    }
    .th-divider-holder {
        padding: 9px 0 !important;
    }
}
// live And Archive popover END

.crm-wrapper {
    .modal-splash.modal-splash {
        top: 0;
        height: 100%;
    }
}

.crm-internal-message-editor {
    .wrapped-editor {
        * {
            font-weight: unset;
        }
        .rdw-editor-toolbar {
            .rdw-image-wrapper {
                .rdw-image-modal {
                    padding: 16px 16px 0 16px;

                    .rdw-image-modal-upload-option {
                        margin-bottom: 20px;

                        .rdw-image-modal-upload-option-label {
                            height: unset;
                            overflow-y: auto;
                        }
                    }
                }
            }
        }
    }
}
