html,
body,
#root {
    height: 100%;
}

#root {
    position: relative;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type='button'],
input[type='submit'],
input[type='file'],
button {
    cursor: pointer;
}

.invalid-tab {
    width: 100%;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: rgba(223, 47, 77, 0.06);

    p {
        width: 100%;
    }

    &.active:before {
        border-bottom: 4px solid red !important;
    }
}

body {
    overflow: hidden;
    zoom: var(--zoom);

    &.view-mode-small {
        --zoom: 0.8;
    }

    &.view-mode-normal {
        --zoom: 1;
    }

    &.view-mode-large {
        --zoom: 1.1;
    }
}

.crm-header {
    .skeleton-holder {
        margin-right: 50px;
    }
}

.crm-wrapper {
    display: grid;
    grid-template-rows: auto 11fr;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & > .loader-holder {
        height: 100vh;
        justify-content: center;
    }
}

.crm-content {
    position: relative;
    overflow: hidden;

    &:only-child {
        grid-row: span 2;
    }

    & > .loader-holder {
        height: 100%;
    }
    //TODO: Schedule CSS
    overflow-y: scroll;
    overflow-x: auto;
}

.crm-segment-list-page-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .right-actions-holder {
        margin-left: auto;
    }
}

.crm-page-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.crm-section-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
}

.crm-section {
    border: 20px solid transparent;
    border-right: 2px solid transparent;
    padding-right: 18px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    &.crm-section-full-height {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .crm-common-table.crm-data-table {
            flex: 1;
        }

        .crm-common-table.crm-data-table,
        .table-holder {
            overflow-y: auto;
        }

        .paper {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .table-holder {
                max-height: unset;
                flex: 1;

                > div {
                    flex: 1;
                }
            }
        }

        .manage-label-popup-content {
            .paper {
                .table-holder {
                    flex: unset;
                }
            }
        }
    }

    .segment-composite-wrap {
        height: 100%;
    }

    .segment-compozite-list {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}

.crm-common-table {
    width: 100%;
    max-height: 100%;
    --header-height: 56px;

    &.default-height {
        --table-max-height-additional-calc: 168px;
    }

    &.with-filter-tags-height {
        --table-max-height-additional-calc: 254px;
    }

    &.without-filter-tags-height {
        --table-max-height-additional-calc: 206px;
    }

    &.with-filter-tags-and-page-headline-height {
        --table-max-height-additional-calc: 324px;
    }

    .table-holder {
        .ta-header {
            .ta-row {
                .ta-cell.ta-header-cell {
                    justify-content: center;
                }
            }
        }
    }

    .scroll-content {
        margin-bottom: -22px !important;
    }

    .ta-group {
        &:last-child {
            .ta-row {
                border-bottom: 0 !important;
            }
        }
    }
}

// CRM table types block START
.crm-segment-type-label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    > span {
        font-weight: 600;
    }
}

.crm-segment-type-text {
    flex: 1;
    font-weight: 600;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.crm-segment-type-text {
    flex: 1;
    font-weight: 600;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.crm-segment-type-icon {
    margin-right: 12px;
    flex: 0 0 auto;
    font-size: 24px;
    font-weight: 100;

    &.static {
        color: #b10f78;
    }

    &.dynamic {
        color: #007e74;
    }

    &.composite {
        color: #f48a3a;
    }
}

.crm-template-type-label {
    display: flex;
    align-items: center;

    > i {
        margin-right: 12px;
        font-size: 18px;
    }

    > span {
        font-weight: 600;
    }
}

.crm-template-type-icon {
    &.email {
        color: #b763c6;
    }

    &.internal {
        color: #ffca5f;
    }

    &.sms {
        color: #7dc681;
    }

    &.popup {
        color: #000;
    }

    &.telegram {
        color: #5fb4f6;
    }
}

.bc-icon-crm-free-spin {
    color: lightseagreen;
}

// CRM table types block END

// CRM table row expandable START
.crm-expandable-row-data-table .table-holder .ta-body .ta-row.selected + .ta-nested-child-holder {
    min-height: 100px;
    max-height: 300px;
}

// CRM table row expandable END

.crm-communication-state-badge-cnt,
.crm-used-or-not-used-badge-cnt,
.crm-status-badge-cnt,
.crm-mode-badge-cnt,
.crm-report-state-badge-cnt,
.crm-state-badge-cnt {
    position: relative;
    padding-left: 20px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }
}

.crm-customer-journey-view-page,
.crm-segment-list-page-wrapper {
    .crm-status-badge-cnt {
        &.crm-cj-status-label-1 {
            // InProgress
            &:before {
                background-color: var(--status-label-in-progress-color);
            }
        }

        &.crm-cj-status-label-2 {
            // Completed
            &:before {
                background-color: var(--status-label-completed-color);
            }
        }

        &.crm-cj-status-label-3 {
            // Suspended
            &:before {
                background-color: var(--status-label-suspended-color);
            }
        }

        &.crm-cj-status-label-4 {
            // NonStarted
            &:before {
                background-color: var(--status-label-non-started-color);
            }
        }

        &.crm-cj-status-label-5 {
            // Failed
            &:before {
                background-color: var(--status-label-failed-color);
            }
        }

        &.crm-cj-status-label-6 {
            // Archived
            &:before {
                background-color: var(--status-label-archived-color);
            }
        }

        &.crm-cj-status-label-7 {
            // Invalid
            &:before {
                background-color: var(--status-label-invalid-color);
            }
        }

        &.crm-cj-status-label-8 {
            // Starting
            &:before {
                background-color: var(--status-label-starting-color);
            }
        }

        &.crm-cj-status-label-9 {
            // Updating
            &:before {
                background-color: var(--status-label-updating-color);
            }
        }

        &.crm-cj-status-label-10 {
            // Resuming
            &:before {
                background-color: var(--status-label-resuming-color);
            }
        }

        &.crm-cj-status-label-11 {
            // Suspending
            &:before {
                background-color: var(--status-label-suspending-color);
            }
        }
    }
}

.crm-campaigns-list-page-wrapper {
    .crm-status-badge-cnt {
        &.crm-cj-status-label-0 {
            // Scheduled
            &:before {
                background-color: var(--status-label-scheduled-color);
            }
        }

        &.crm-cj-status-label-1 {
            // Idle
            &:before {
                background-color: var(--status-label-idle-color);
            }
        }

        &.crm-cj-status-label-2 {
            // InProgress
            &:before {
                background-color: var(--status-label-in-progress-color);
            }
        }

        &.crm-cj-status-label-3 {
            // Completed
            &:before {
                background-color: var(--status-label-completed-color);
            }
        }

        &.crm-cj-status-label-4 {
            // Sent
            &:before {
                background-color: var(--status-label-sent-color);
            }
        }

        &.crm-cj-status-label-5 {
            // Stopped
            &:before {
                background-color: var(--status-label-stopped-color);
            }
        }

        &.crm-cj-status-label-7 {
            // Error
            &:before {
                background-color: var(--status-label-error-color);
            }
        }
    }
}

.create-campaign-page-wrapper,
.crm-customer-journey-view-page,
.crm-segment-list-page-wrapper,
.crm-campaigns-list-page-wrapper,
.crm-template-list-page-wrapper,
.crm-report-list-page-wrapper {
    .crm-mode-badge-cnt {
        &.crm-cj-mode-label-1 {
            // Live
            &:before {
                background-color: var(--mode-label-live-color);
            }
        }

        &.crm-cj-mode-label-2 {
            // Test
            &:before {
                background-color: var(--mode-label-test-color);
            }
        }

        &.crm-cj-mode-label-3 {
            // Draft
            &:before {
                background-color: var(--mode-label-draft-color);
            }
        }

        &.crm-cj-mode-label-4 {
            // Archived
            &:before {
                background-color: var(--mode-label-archived-color);
            }
        }
    }
}

.create-campaign-page-wrapper,
.crm-segment-list-page-wrapper,
.crm-template-list-page-wrapper {
    .crm-used-or-not-used-badge-cnt {
        &.crm-used-or-not-used-label-true {
            // Used
            &:before {
                background-color: var(--used-or-not-label-used-color);
            }
        }

        &.crm-used-or-not-used-label-false {
            // Not used
            &:before {
                background-color: var(--used-or-not-label-not-used-color);
            }
        }
    }
}

.crm-report-list-page-wrapper {
    .crm-report-state-badge-cnt {
        &.crm-report-state-label-0 {
            // Standart
            &:before {
                background-color: var(--report-state-label-standart-color);
            }
        }

        &.crm-report-state-label-1 {
            // Scheduled
            &:before {
                background-color: var(--report-state-label-scheduled-color);
            }
        }
    }
}

.crm-communication-state-badge-cnt {
    &.crm-communication-state-label-0 {
        // Success
        &:before {
            background-color: var(--communication-state-label-success-color);
        }
    }

    &.crm-communication-state-label-1 {
        // Failed
        &:before {
            background-color: var(--communication-state-label-failed-color);
        }
    }
}

// CRM used or note used block END

// CRM table quick view column START

.table-quick-view-column {
    position: relative;

    & .icon:hover {
        & + div {
            display: block;
        }
    }
}

// CRM table quick view column END

// CRM set category block START
.set-category {
    width: 100%;
    display: flex;
    align-items: center;
}

.tittle-text,
.set-category-text {
    font-weight: 600;
    color: var(--tittle-link-text-color);
}

//CRM scroll START
.crm-scroll-holder {
    position: relative;
    width: 100%;
    height: 100%;
}

.selected-items-container-item {
    white-space: pre;

    .tooltip-content {
        max-width: unset;
    }
}

.skeleton-content {
    display: flex;
    flex-wrap: wrap;

    span {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
}

.crm-scrollable-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-right: 4px;
}

.cascade-form {
    display: flex;
    flex-direction: row;

    .cascade-form-items {
        flex: 0 0 auto;
        width: 50%;
        justify-content: initial;

        .cascade-form-item {
            width: 100%;

            .dropdown-holder {
                width: 100%;
            }
        }
    }

    .cascade-selected-items-container {
        flex: 1;
        padding: 2rem;

        .crm-multi-select-country-section-body {
            margin-top: 2rem;
        }
    }
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    //border: 0 !important;
    cursor: pointer;
}

.crm-scrollable-container:hover {
    &::-webkit-scrollbar-thumb {
        border: 0 !important;
    }
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

//CRM scroll END

// CRM custom components START
.text-has-underline {
    font-size: 14px;
    text-transform: capitalize;
    color: var(--custom-color-1);
    font-weight: bold;
    padding: 0 10px 3px 10px;
    cursor: pointer;
    margin: 0 1px;

    &.active {
        box-shadow: 0 3px 0 0 var(--hero);
    }
}

.crm-create-template-page-c-c-head-c-add-translation-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: capitalize;
    color: var(--hero);
    font-weight: 600;

    > i {
        font-size: 20px;
        padding: 0 4px;
        font-weight: inherit;
    }

    > span {
        font-weight: inherit;
    }
}

.crm-custom-btn {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    outline: none;
    height: 30px;
    padding: 0 15px;
    border-radius: 6px;
    border: solid 1px var(--hero);
    background-color: rgba(20, 115, 230, 0.1);
    color: var(--hero);
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
}
.crm-greyed-out-custom-btn {
    opacity: 0.6;
}

.crm-messaging-row {
    display: grid;
    grid-template-rows: auto 11fr;
    grid-gap: 15px;
    width: 100%;
}

.crm-messaging-row-head {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 14px;

    > p {
        font: inherit;
        color: var(--custom-color-1);
        margin-right: 10px;
    }

    > span {
        font: inherit;
        color: #2bc784;
    }
}

//////// CUSTOM //////////
.c-crm-s-header {
    align-items: center;

    .static-title-holder {
        margin-left: 20px;
    }
}

.c-s-b-content {
    .scroll-content {
        margin-bottom: -16px !important;
    }
}

.c-tagList-bc {
    display: flex;
    flex-wrap: wrap;
}

.crm-template-info-modal {
    .tabs-holder {
        height: 100%;
    }

    .tabs-content-holder {
        height: calc(100% - 80px);
    }

    .modal-body {
        .tabs-content-holder {
            .key-value-h {
                .input-structure {
                    max-width: 710px;
                    padding: unset;
                    .input-element {
                        padding: unset;
                        .textarea-element-content {
                            max-height: 160px;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.segment-modal-bottom {
    grid-template-areas:
        'segmentInfo'
        'segmentBody';

    .title-holder {
        grid-area: segmentInfo;
    }
}

.segment-list-modal .segment-modal-bottom .segment-modal-b-content.c-s-b-content {
    grid-area: segmentBody;
    height: 100%;
    position: relative;

    & > div {
        &:not(.download-toolbar) {
            width: 100%;
            height: 100%;
        }
    }

    .tabs-content-holder {
        height: calc(100% - 80px);
    }
}

.c-s-b-content {
    .scroll-content {
        margin-bottom: -16px !important;
    }
}

.c-tagList-bc {
    display: flex;
    flex-wrap: wrap;
}

.currency-tabs {
    .crm-custom-tabs-btn-panel {
        padding: 0 2rem;
        overflow-y: auto;
    }

    margin-top: 2em;

    .currency-min-max-section {
        padding: 8px 20px 20px;
        border: 1px solid;
        width: 100%;
        height: 100px;
        border-radius: 25px;
        margin-top: 10px;
        border-color: rgba(var(--background-sc-rgb), 0.2);
    }

    .min-max-currency {
        display: flex;
        width: 70%;
        flex-direction: row;
        padding-bottom: 20px;

        > div {
            margin-top: 20px;
            flex: 1;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}
.autofill-section-container {
    margin-top: 20px;
    .alert-holder {
        margin-top: 10px;
    }
}

.min-max-currency-autofill-section-container {
    display: flex;
    height: 82px;
    .input-holder {
        width: 318px;
        margin: 0 8px 0 8px;
    }
    .dropdown-holder {
        width: 320px;
    }
    .btn {
        height: 34px;
        margin: 0 18px 0 18px;
        .ellipsis-text {
            color: var(--hero-sc);
        }
    }
}

.award-description {
    height: 160px;
    margin-bottom: 25px;
}

.template-info-modal {
    .tabs-holder {
        height: 100%;
    }

    .tabs-content-holder {
        height: calc(100% - 80px);
    }
}

.c-create-label {
    max-width: 540px;

    .input-holder.a-outline.filled:not(:focus-within) {
        border: 1px solid transparent;
    }
}

.c-add-lang {
    div[title='Language'] {
        & > .th-divider-holder {
            display: none;
        }
    }

    .right-actions-holder.a-square {
        display: none;
    }

    div[data-id*='Language'] {
        width: 458px !important;
    }
}

.crm-create-template-page-wrapper-container {
    .add-language {
        .modal-content {
            height: auto;
            min-height: 480px;
            max-height: 100%;
            padding-bottom: 63px;
            position: relative;
        }

        .modal-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .ta-row {
            .a-square {
                display: none;
            }
        }

        .modal-body {
            .paper.full-radius {
                border-radius: 0;
            }
            .crm-common-table {
                height: calc(100% - 41px);
            }
            .crm-common-table > .paper {
                height: 100%;
                .table-holder {
                    height: calc(100% - 84px);
                }
            }
        }

        .paper.with-shadow {
            box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.05);
        }
    }
}

.ta-pagination-holder {
    .dropdown-holder {
        .input-holder {
            border: none;
        }
    }
}

.segment-list-table-wrapper {
    .ta-header {
        .a-square {
            min-height: 3.5rem;
        }
    }
}

.title-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    font: 600 1.4rem/1.8rem var(--font-family);
    min-height: 3.2rem;
    margin-bottom: 1rem;

    > .title-wrapper-text {
        flex: 0 0 auto;
        color: var(--custom-color-1);
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.active-state {
            color: var(--hero);
        }
    }

    > .title-wrapper-line {
        flex: 1;
        margin-left: 1rem;
        height: 1px;
        background: rgba(var(--background-sc-rgb), 0.1);
    }
}

.segment-list-modal .segment-modal-bottom .segment-modal-b-content.c-s-b-content {
    grid-area: segmentBody;
}

.c-add-lang {
    div[title='Language'] {
        width: 458px !important;

        & > .th-divider-holder {
            display: none;
        }
    }
}

.crm-create-template-page-wrapper-container {
    .add-language {
        .c-add-language-from-default {
            padding-top: 2rem;
            padding-left: 2rem;
        }

        .modal-content {
            height: auto;
            min-height: 480px;
            max-height: 100%;
            padding-bottom: 63px;
            position: relative;
        }

        .modal-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
}

.ta-pagination-holder {
    .dropdown-holder {
        .input-holder {
            border: none;
        }
    }
}

.segment-list-table-wrapper {
    .ta-header {
        .a-square {
            min-height: 3.5rem;
        }
    }
}

.validation-popup-desc {
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0;
}

.validation-popup-body {
    .alert-holder {
        margin-top: 2rem;
    }
    .pre-save-warning-alert {
        height: 100%;
        .ab-c-c {
            width: 100%;

            overflow: hidden;
            .alert-box-title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .alert-text {
                max-width: fit-content;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    position: relative;

    .information-message {
        position: absolute;
        left: 0;
    }

    & > .input-holder + .input-holder {
        margin-top: 2.5rem;
    }
}

.information-message {
    margin-bottom: 0.6rem !important;
}

.create-dynamic-segment-page-wrapper {
    --header-height: 0;
}

.crm-page-headline {
    .modal-splash.p-top {
        height: 100%;
    }
}

// CRM custom components END

// Popover styles START
.popover-content {
    .scroll-content {
        margin-bottom: -16px !important;
    }
}

.popover-top-bottom-padding {
    padding: unset !important;
}

// Popover styles END

.overlay-head + .overlay-body .overlay-footer > * {
    margin: unset;
}

// Tables height for zoom START
.view-mode-large {
    .crm-segment-list-page-wrapper {
        .table-holder {
            max-height: 625px;
        }
    }

    .crm-static-segment-popup-body.static-segment-body
        .crm-static-segment-data-table
        .table-holder:not(.content-empty) {
        --table-max-height-additional-calc: 250px;
        max-height: calc(100vh - 244px - var(--table-max-height-additional-calc, 0px));
    }

    .crm-static-segment-popup-body.non-customer-body .table-holder:not(.content-empty) {
        --table-max-height-additional-calc: 250px;
        max-height: calc(100vh - 355px - var(--table-max-height-additional-calc, 0px));
    }
}

.view-mode-small {
    .crm-static-segment-popup-body.non-customer-body .table-holder:not(.content-empty) {
        --table-max-height-additional-calc: 100px;
        max-height: calc(100vh - 230px - var(--table-max-height-additional-calc, 0px));
    }

    .crm-static-segment-popup-body.static-segment-body
        .crm-static-segment-data-table
        .table-holder:not(.content-empty) {
        --table-max-height-additional-calc: 80px;
        max-height: calc(100vh - 140px - var(--table-max-height-additional-calc, 0px));
    }
}

// Tables height for zoom END

// Translation button START
.add-translation-btn {
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    transition: background-color 240ms;

    &:hover {
        background-color: rgba(var(--background-sc-rgb), 0.03);
    }
}

// Translation button END

.input-element-back {
    transition: 300ms border-color, 300ms opacity !important;
}

.alert-text {
    white-space: pre-line;
}

.option-icon-value {
    display: flex;
    align-items: center;

    span {
        &:first-child {
            margin-right: 5px;
        }
    }
}

.key-value-info-content {
    display: grid;
    grid-template-columns: 4fr 8fr;
    margin-bottom: 1.5rem;
}

.key-value-items-vertical {
    display: inline-block;
    grid-template-columns: 4fr 8fr;
    margin-bottom: 1.5rem;
}

.key-value-items-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

.key-value-items-subtitle {
    color: rgba(60, 64, 67, 1);
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

.key-value-items-skeleton {
    span.react-loading-skeleton {
        height: 20px !important;
    }

    width: 250px;
}

.key-value-items-details-section {
    padding-left: 20px;
    height: 100%;

    .table-empty-holder {
        min-height: 39rem;
    }
}

.crm-keyvalue-items-wrapper {
    .key-value-items-vertical {
        padding-right: 30px;
    }
}

.key-value-info-wrapper {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    span {
        display: block;
        font-weight: 600;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.notifications-events-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 100%;
    padding: 40px 40px 40px 0;
    overflow-y: auto;
    overflow-x: hidden;
}
.notifications-group-container {
    h3 {
        margin-left: 20px;
    }
}

.notification-events-section {
    display: grid;
    grid-template-columns: 12fr;
    grid-template-rows: auto 11fr;
    grid-gap: 24px;
    padding-right: 24px;
    border-right: 1px solid var(--line-color);
}

.channel-labels-wrapper {
    p {
        margin-top: 10px;

        &:not(:first-child) {
            margin-left: 4px;
        }
    }
}

.channels-label-is-live {
    display: flex;
    justify-content: space-between;
}

.notification-main-holder {
    display: grid;
    grid-template-rows: auto 11fr;
    width: 100%;
    height: 100%;
}

.notification-container {
    width: 100%;
    height: 100%;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1480px;
}

.notification-inner {
    display: grid;
    grid-template-columns: 370px 11fr;
    grid-gap: 24px;
    width: 100%;
    height: 100%;
    padding: 24px;
    border-radius: 20px;
    background: var(--background);

    .table-holder:not(.content-empty) {
        max-height: calc(100vh - 120px - var(--table-max-height-additional-calc, 0px));
    }
}

.channel-tabs-heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-transform: capitalize;
}

.pre-save-checkbox-row-wrapper {
    display: flex;
    align-items: center;

    .icon {
        margin-left: 12px;
    }
}

.selected-channels-container {
    margin-top: 16px;
}

.crm-cj-visualization-step-dropdown-item {
    width: 100%;
}

.filter-holder-autocomplete-option-list-content-wrapper {
    .btn {
        background: var(--background);
    }
    .crm-option-list-content__toggle-button {
        background: var(--background) !important;
        color: rgba(var(--background-sc-rgb), 0.75) !important;
        .btn {
            min-height: unset;
        }
    }

    .crm-option-list-content {
        width: 100%;
        .ReactVirtualized__Grid {
            width: 100% !important;
            .ReactVirtualized__Grid__innerScrollContainer {
                max-width: 100% !important ;
            }
        }

        .option-left-elements,
        .option-title {
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;

            span {
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:not(:last-child) {
                    margin-right: 1rem;
                }

                &:first-child {
                    text-align: left;
                    flex: 1.5;
                }

                &:last-child {
                    flex: 0.8;
                    text-align: left;
                }

                &:not(:first-child):not(:last-child) {
                    text-align: left;
                    flex: 1.5;
                }
            }
        }
    }
}

.filter-holder-autocomplete-option-list-button-wrapper {
    width: 100%;

    .ellipsis-text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin-left: auto;
        }
    }

    .autocomplete-option-list-placeholder {
        font-family: var(--font-family);
        font-weight: 600;
        background-color: transparent;
        color: rgba(var(--background-sc-rgb), 0.75);
        transition: 150ms opacity;
        opacity: 0.6;
        display: flex;
        text-align: left;
        flex: 1.5;
    }
}

.crm-option-dropdown {
    width: 100%;
    max-width: 26rem;

    .option-title {
        gap: 10px;
        align-items: baseline;
        .input-holder {
            transform: translate3d(0, 0, 0);
            padding-top: 1rem;
            min-width: 20rem;
            .input-structure {
                border: 1px solid var(--input-element-border-color, transparent);
                border-radius: var(--input-element-border-radius, 4.2rem);
                overflow: hidden;
                .input-swap-label {
                    top: 0;
                    left: 0;
                    position: fixed;
                    z-index: 1;
                }
            }
        }
    }
    .option-left-elements {
        width: 100%;
        gap: 10px;
        > span {
            flex: 1;
        }
    }
    .option-container {
        padding: 1rem 1.4rem;
        &:hover {
            background: unset;
        }
    }
    .crm-option-list-content {
        min-width: 100%;
        padding-top: 0;
        .loader-holder {
            width: 100%;
            height: 100%;
            justify-content: center;
        }
    }
    .switcher-element {
        left: 12px;
        top: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
    }
    button.btn.a-minimal {
        position: absolute;
        top: 0;
        left: unset;
        right: 26px;
    }
    .crm-option-list-content-wrapper {
        .crm-option-list-content {
            &__toggle-button {
                min-width: 18rem !important;
                span.ellipsis-text {
                    display: flex;
                    align-items: center;
                    .bc-icon-clear-small {
                        font-size: 2.6rem;
                        border-radius: 2.6rem;
                        margin-left: 0.5rem;
                        &:hover {
                            background: rgba(var(--background-sc-rgb), 0.03);
                        }
                    }
                }

                color: rgba(var(--background-sc-rgb), 0.75);
                background: var(--background);
                border: 1px solid rgba(0, 0, 0, 0.3);
                margin: 2px;

                &:hover {
                    background: var(--background);
                    color: rgba(var(--background-sc-rgb), 0.75);
                }

                .crm-option-icon-column {
                    font-size: 2rem;
                }
            }

            &__is-not-valid {
                border: 1px solid var(--danger);
            }

            &__option-list {
                .crm-option-icon-column {
                    font-size: 2rem;
                }
            }
        }
    }

    .crm-option-list-content-wrapper__error-text {
        position: absolute;
        white-space: nowrap;
    }
    .option-placeholder {
        font-family: var(--font-family);
        font-weight: 600;
        background-color: transparent;
        color: rgba(var(--background-sc-rgb), 0.75);
        transition: 150ms opacity;
        opacity: 0.6;
        display: flex;
        text-align: left;
        flex: 1.5;
    }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    .ReactVirtualized__Grid {
        width: 100% !important;
        .ReactVirtualized__Grid__innerScrollContainer {
            position: initial !important;
        }
    }
}
.crm-cj-filter-condition-inputs {
    align-items: center;
}

.client-info-modal {
    .client-info-modal-h-text {
        font-weight: bold;
        color: var(--custom-color-1);
    }
    .modal-content {
        max-width: 1122px;
        min-width: 1200px;
        width: 80%;
    }
    .modal-body {
        overflow-y: scroll;
        min-height: 380px;
        max-height: 600px;
        height: 80%;
        width: 100%;
        display: flex;
        .loader-holder {
            display: flex;
            justify-content: center;
        }
        .collapse-items-group {
            height: 100%;
            width: 100%;
        }
    }
}

.crm-preparing,
.crm-under-maintenance,
.crm-not-active {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    p:first-of-type {
        font-weight: 600;
        font-size: 38px;
        line-height: 52px;
    }

    p:last-of-type {
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        color: rgba(var(--background-sc-rgb), 0.62);
    }
    p {
        margin: 8px;
    }

    img {
        padding-bottom: 25px;
    }

    button {
        margin-top: 25px;
    }
    padding-bottom: 25px;
}

.crm-under-maintenance {
    .countdown-timer-container {
        margin-top: 2rem;
        display: flex;
        column-gap: 2rem;
        .countdown-timer-section {
            background-color: rgba(var(--background-sc-rgb), 0.62);
            border-radius: 1rem;
            padding: 0.8rem 1.6rem;
            display: flex;
            flex-direction: column;
            row-gap: 0.4rem;
            align-items: center;
            min-width: 12rem;
            .countdown-timer-title {
                font-size: 1.6rem;
                color: var(--background);
            }
            .countdown-timer-value {
                font-size: 4rem;
                color: var(--background);
                padding: 0.8rem 0;
            }
        }
    }
}

.set-default-language-warning-message {
    margin-top: 20px;
}

.bonus-calculation-static-tab-container {
    max-width: 880px;
    .alert-holder {
        margin-bottom: 20px;
    }
    .amount-wrap {
        margin-right: 20px;
    }
}

.crm-not-active-footer {
    height: 30;
    line-height: 30px;
    background-color: #f00;
    background-color: red;
    opacity: 0.8;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    bottom: 2px;
    left: 50%;
    width: 300px;
    height: 50px;
    line-height: 50px;
    transform: translateX(-50%);
    border: 1px solid var(--line-color);
    border-radius: 20px;
    z-index: 1000;
    text-align: center;
    box-shadow: 0 -0.3rem 0.6rem 0 rgba(0, 0, 0, 0.05);
    span {
        font-weight: bold;
    }
}
.crm-data-table-row-archive {
    color: #999;
    opacity: 0.9;
}

.client-filter-icon {
    margin-left: 15px;
    background-color: var(--profile-segmentation-color);
}

.crm-filter-field-comparison-popover-container {
    display: flex;
    align-items: center;
    width: 285px;
}

.popover-content-pair {
    display: flex;
    .crm-dynamic-segment-list-item-icon {
        background-color: var(--profile-segmentation-color);
    }
}

.crm-gallery-popover-container {
    .crm-gallery-popover-content-container {
        width: 120px;
        button {
            height: 40px;
            p {
                font-size: 12px;
            }
            .icon {
                font-size: 18px;
            }
        }
        padding: 10px;
        :hover {
            color: var(--hero);
        }
        display: flex;
        align-items: center;
        height: 85px;
        justify-content: space-around;
    }
}

.blue-color-button {
    color: var(--hero);
    font-weight: 600;
}

.crm-copy-to-clipboard-fixed-position-wrapper {
    display: flex;
    align-items: center;

    .copy.copy-icon {
        margin-right: 0;
    }
    .copy-icon {
        .icon {
            font-size: 2rem;
        }
    }
    &.space-preserved-left {
        margin-left: -1.3em;
        transition: margin-left 0.2s ease;
        transition-delay: 100ms;
    }
    &.space-preserved-left:hover {
        margin-left: 0;
    }
    &.space-preserved-right {
        margin-right: -1.3em;
        transition: margin-right 0.2s ease;
        transition-delay: 100ms;
    }
    &.space-preserved-right:hover {
        margin-right: 0;
    }
}

.crm-segment-report-preview-modal {
    .modal-body {
        height: 62rem;
        display: flex;

        .section-holder {
            .section-body {
                .table-holder {
                    max-height: 50rem;
                }
            }
        }
    }
}

.padding-from-window {
    padding: 0 6rem;
}

.option-dropdown-popover-container {
    .crm-option-list-content {
        min-width: 100%;
        padding-top: 0;
        .loader-holder {
            width: 100%;
            height: 100%;
            justify-content: center;
        }
        &__toggle-button {
            min-width: 18rem !important;
            span.ellipsis-text {
                padding-right: 20px;
            }

            color: rgba(var(--background-sc-rgb), 0.75);
            background: var(--background);
            border: 1px solid rgba(0, 0, 0, 0.3);
            margin: 2px;

            &:hover {
                background: var(--background);
                color: rgba(var(--background-sc-rgb), 0.75);
            }

            .crm-option-icon-column {
                font-size: 2rem;
            }
        }

        &__is-not-valid {
            border: 1px solid var(--danger);
        }

        &__option-list {
            .crm-option-icon-column {
                font-size: 2rem;
            }
        }
        .option-container {
            padding: 1rem 1.4rem;
        }
        .option-container.border-bottom {
            cursor: default;
        }

        .option-title {
            display: flex;
            gap: 10px;
            align-items: baseline;
            .input-holder {
                transform: translate3d(0, 0, 0);
                padding-top: 1rem;
                min-width: 20rem;
                .input-structure {
                    border: 1px solid var(--input-element-border-color, transparent);
                    border-radius: var(--input-element-border-radius, 4.2rem);
                    overflow: hidden;
                    .input-swap-label {
                        top: 0;
                        left: 0;
                        position: fixed;
                        z-index: 1;
                    }
                }
            }
        }

        .crm-option-list-header-item {
            width: 100%;
        }

        .ReactVirtualized__Grid {
            width: 100% !important;
            .ReactVirtualized__Grid__innerScrollContainer {
                position: initial !important;
            }
        }
        .option-left-elements {
            width: 100%;
            gap: 10px;
            > span {
                flex: 1;
            }
        }
        .input-element-back {
            border: none;
            border-bottom: 1px solid var(--line-color);
        }
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px rgba(112, 112, 112, 0.2);
        background-color: var(--background);
        color: rgba(var(--background-sc-rgb), 0.75);

        .switcher-element {
            left: 12px;
            top: 4px;
            margin-bottom: 10px;
            margin-right: 10px;
        }
    }
}
