.d-a-d-popup {
    .modal-content {
        .modal-body {
            height: 46rem;
            width: 74rem;
            padding: 0;

            .loader-holder {
                height: 100%;
                justify-content: center;
            }
        }
    }
    .modal-close {
        display: none;
    }
}
