//variables
$input-label-color: #3c4043;
$form-title-color: var(--hero);
$arrow-inactive-color: rgba(60, 64, 67, 0.5);
$nested-collapse-panel-background: #fff;
$nested-collapse-panel-selected-icon-color: var(--hero);
.crm-date-form-simple-tab {
    &__has-time {
        position: relative;

        i {
            font-size: 24px;
            left: 140px;
            position: absolute;
            top: 22px;
        }
    }

    //&__input-title {
    //  padding: 20px 0 20px 10px;
    //  font-size: 16px;
    //  font-weight: 600;
    //  font-stretch: normal;
    //  font-style: normal;
    //  line-height: 1.38;
    //  letter-spacing: normal;
    //  text-align: left;
    //  color: $input-label-color;
    //}

    .cha-ra-group .crs-holder {
        padding: 20px 30px 20px 10px;
    }
}

.crm-dynamic-segment-form {
    display: grid;
    grid-template-rows: auto 11fr auto;
    grid-template-areas: 'head' 'content' 'bottom';
    width: 100%;
    height: 100%;
    .tabs-content {
        li {
            width: 50%;
        }
    }
    min-height: 500px; //TODO: Schedule CSS
    &__content {
        height: 100%;
    }

    &__content-second-type {
        height: 100%;

        .dropdown-holder {
            width: 49%;
        }

        .crm-multi-select-country-section {
            width: 49%;
            padding-right: 30px;

            &__body {
                border: 1px solid rgba(var(--background-sc-rgb), 0.1);
                border-radius: 10px;
                max-height: 340px;
                min-height: 58px;
                overflow-y: scroll;
            }

            &__tag {
                margin: 0 10px 10px 0;
            }
        }
    }

    &__radio-is-equivalent {
        padding-left: 10px;

        .cha-ra-group .crs-holder:first-child {
            padding: 10px 0 10px 20px;
        }
    }

    .cha-ra-group {
        flex-direction: row;

        .crm-date-form-simple-tab .crs-holder {
            padding: 10px 20px;

            &:first-child {
                padding: 45px 20px 10px;
            }
        }
    }
}
.crm-dynamic-segment-form-head {
    grid-area: head;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--line-color);
    color: var(--custom-color-1);
    font-weight: 600;
}
.crm-dynamic-segment-form-content {
    grid-area: content;
    width: 100%;
    .from-to-wrapper + .crm-dynamic-date-form-equal {
        padding: 0;
        .crm-dynamic-segment-form-equal {
            padding: 0 !important;
        }
    }
}
.crm-dynamic-segment-form-bottom {
    grid-area: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 66px;
    width: 100%;
    border-top: 1px solid var(--line-color);
    > button {
        margin: 0 5px;
    }
}

.crm-drop-down-multi-select {
    margin-right: 10px;
    .dropdown-item {
        display: none;
    }
}

.crm-static-and-dynamic-simple-form {
    .crm-date-form-simple-tab {
        &_time-picker {
            width: 175px;
            .shrink-auto {
                .input-holder {
                    padding: 0;
                    width: 100%;
                }
            }
        }

        .cha-ra-group {
            flex-direction: row;
        }
    }
}

.crm-static-from-to-form {
    .crm-date-form-simple-tab {
        width: 50%;
    }
}

.crm-activity-login-source-radio,
.crm-profile-locked {
    .cha-ra-group {
        padding-top: 10px;

        .crs-holder {
            padding: 20px 20px 0;
        }
    }
}

.crm-sports-book-loss-activity {
    .cha-ra-group {
        .crs-holder {
            padding: 10px 20px 0;
        }
    }
}
.from-to-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin-bottom: 2rem;
}
.crm-from-to-title {
    color: $form-title-color;
    font-size: 16px;
    font-weight: 700;
}

.crm-collapse-region-input {
    width: 90%;
    position: relative;

    .input-holder {
        width: 100%;
    }

    &__input.disabled {
        opacity: 1;
    }

    i {
        position: absolute;
        top: 6px;
        right: 16px;
    }
}

.crm-sports-book-match-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    &.crm-dynamic-segment-form__content {
        height: unset;
    }

    &__dropdown-with-icon {
        width: 50%;
        position: relative;
        padding: 1rem;

        .bc-icon-arrow-right {
            position: absolute;
            right: 10px;
            top: 17px;
        }

        .is-inactive {
            color: $arrow-inactive-color;
        }
    }

    .dropdown-holder {
        width: 90%;

        .input-holder {
            width: 100%;
        }
    }

    &__search-dropdown {
        position: absolute;
        right: 47px;
        max-height: 265px;
        overflow-y: scroll;
        width: 86%;
        background: white;
        z-index: 100000000;
        top: 61px;
        border: 1px solid rgba(112, 112, 112, 0.2);
        border-radius: 10px;

        .input-holder {
            width: 100%;
            padding-top: 10px;
            padding-left: 0;

            .input-element-back {
                border: none;
            }

            i {
                display: none;
            }
        }

        .accordion-holder.a-default {
            border: none;
        }

        .crm-nested-drop-down-simple-tab {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            position: relative;

            p {
                font-size: 14px;
                font-weight: 600;
                padding-left: 22px;
            }

            i {
                position: absolute;
                right: 16px;
                top: 7px;
                color: $nested-collapse-panel-selected-icon-color;
            }
        }

        .accordion-body {
            overflow-y: scroll;
            padding: 0 20px 10px;
        }

        .accordion-head {
            display: flex;
            flex-direction: row-reverse;

            .ellipsis-text {
                position: relative;
                margin: 0;

                .crs-holder {
                    padding-top: 4px;
                    position: absolute;
                    left: 5px;
                }

                p {
                    margin-left: 35px;
                }
            }

            .line {
                display: none;
            }
        }
    }
}

.crm-number-simple-input-form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    &__single-input-container {
        padding-left: 20px;
        padding-top: 20px;
        width: 50%;
    }

    &__input-container {
        display: flex;
        padding-left: 20px;
        justify-content: space-between;

        .simple-number-input {
            width: 48%;
        }
    }
}

.crm-month-day-picker-form {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__box {
        width: 100%;

        .dropdown-holder:first-child {
            margin-bottom: 20px;
        }
    }
}

.crm-dynamic-segment-form-equal,
.crm-dynamic-date-form-equal {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;
}

.crm-dynamic-date-form-equal {
    > div {
        display: initial;
    }
}

.crm-form-single-input-custom {
    .input-holder.cr-smooth-radius {
        --input-element-border-radius: 1rem;
        --textarea-border-radius: 1rem;
    }
}

.crm-form-limit-currency-drop,
.crm-form-limit-input {
    padding-bottom: 20px;
}

.inner-crm-form-limit-result {
    position: relative;
    padding-left: 10px;
    i {
        font-size: 24px;
        left: 93px;
        position: absolute;
        top: 0;
    }
    p {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: var(--custom-color-1);
    }
}

.crm-form-limit-equivalent {
    display: flex;
    flex-direction: column;
    .crs-holder {
        padding-bottom: 0;
    }
}

.crm-dynamic-segment-form-title {
    display: flex;
    align-items: center;
    p {
        padding: 20px 10px 15px 10px;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: var(--custom-color-1);
    }
}

.from-to-input,
.crm-static-from-to-form {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    > div {
        flex: 1;
        //overflow: hidden;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}

.crm-date-form-simple-tab-input-title {
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
}
.headine-text {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 20px;
    color: var(--custom-color-1);
}

.crm-restriction {
    .cha-ra-group {
        flex-direction: column;
        padding: 20px 0 0 20px;
    }
    .crs-holder {
        padding: 10px;
    }
}

.crm-justify-content-around {
    padding-bottom: 20px;
}

.crm-multi-select-country-section-body {
    position: relative;
    padding: 10px;
    min-height: 56px;
    border-radius: 10px;
    border: solid 1px var(--line-color);
    .static-title-holder {
        padding: 4px 5px;
    }
    p {
        position: absolute;
        left: 1.5rem;
        font-weight: 600;
        color: rgba(var(--background-sc-rgb), 0.75);
        &:only-child {
            top: 50%;
            font-size: 14px;
            transform: translateY(-50%);
            color: rgba(var(--background-sc-rgb), 0.2);
        }
        &:not(:only-child) {
            top: -9px;
            font-size: 11px;
            background-color: var(--background);
            padding: 0 5px;
            transition: top 60ms linear;
            color: rgba(var(--background-sc-rgb), 0.75);
        }
    }
}

.scheduler-modal-wrap {
    .crm-multi-select-country-inner {
        overflow-y: auto;
        max-height: 94px;
    }
}

.crm-dynamic-segment-form__content.crm-static-and-dynamic-simple-form {
    .tabs-content-holder {
        > div:not([class]) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 1rem 2rem;
            > div {
                &:not(:last-child) {
                    margin-right: 2rem;
                }
                flex: 1;
            }
        }
    }
}
.crm-dynamic-segment-form {
    .crm-month-day-picker-form {
        .crm-month-day-picker-form__box {
            padding: 1rem;
            > div {
                padding: 1rem;
            }
        }
        .information-message {
            margin-left: 30px;
            margin-top: -22px;
            color: var(--danger);
        }
    }
}

.from-to-wrapper + .crm-static-from-to-form,
.crm-dynamic-segment-form-equal {
    .crm-date-form-simple-tab {
        div:not([class]) {
            position: relative;
            .popover-track {
                & + p {
                    position: absolute;
                    left: 20px;
                    color: var(--danger);
                    margin: 0.6rem 0 0;
                    padding: 0 var(--input-message-padding-side);
                    font: 600 1.2rem/1.42 var(--font-family);
                }
            }
        }
    }
}
.input-holder.error-color,
.crm-invalid-dropdown {
    position: relative;
}
.crm-invalid-dropdown {
    > p {
        color: var(--danger);
        position: absolute;
        margin-top: 0.6rem;
        left: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: var(--font-family);
        font: 600 1.2rem/1.42 var(--font-family);
    }
}

.crm-sports-book-match-form__dropdown-with-icon:not(:last-child) {
    margin-bottom: 0.5rem;
}
