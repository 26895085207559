.crm-client-list-page {
    padding: 2rem 1.5rem 2rem 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .crm-list-filter-section-container {
        margin-bottom: 2rem;
        .section-holder {
            min-height: 160px;
            padding: 0 1rem 1rem 0;
            .section-head {
                border-bottom: 1px solid var(--line-color);
                .title-text {
                    color: var(--custom-color-1);
                    .module-header {
                        border-radius: 2rem;
                    }
                }
            }
            .section-body {
                margin-top: 15px;
                .crm-horizontal-filters-container {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    row-gap: 15px;
                    column-gap: 31px;
                }
                .crm-filters-action-buttons {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 30px;
                }
            }
        }
    }
    .client-list {
        overflow-y: hidden;
        flex: 1;
        .crm-common-table.crm-data-table {
            height: 100%;
            .paper {
                height: 100%;
                .paper-right-actions {
                    flex-direction: row-reverse;
                    justify-content: start;
                    .crm-data-table-header-action-bar-left-actions {
                        .crm-data-table-header-bulk-actions {
                            margin-left: 20px;
                        }
                    }
                    .crm-data-table-header-action-bar-right-actions {
                        width: unset;
                        margin-left: unset !important;
                    }
                }
                .table-holder {
                    overflow-y: hidden;
                }
            }
        }
        .crm-data-table-header-action-bar-right-actions {
            width: 100%;
        }
    }
}

.crm-list-filter-section-container {
    margin-bottom: 2rem;
    .section-holder {
        min-height: 160px;
        padding: 0 1rem 1rem 0;
        .section-head {
            border-bottom: 1px solid var(--line-color);
            .title-text {
                color: var(--custom-color-1);
                .module-header {
                    border-radius: 2rem;
                }
            }
        }
        .section-body {
            margin-top: 15px;
            .crm-horizontal-filters-container {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;
            }
            .crm-filters-action-buttons {
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;
            }
        }
    }
}
.client-list {
    .crm-common-table.crm-data-table {
        .paper-right-actions {
            flex-direction: row-reverse;
            justify-content: start;
            .crm-data-table-header-action-bar-left-actions {
                .crm-data-table-header-bulk-actions {
                    margin-left: 20px;
                }
            }
            .crm-data-table-header-action-bar-right-actions {
                width: unset;
                margin-left: unset !important;
            }
        }
    }
    .crm-data-table-header-action-bar-right-actions {
        width: 100%;
    }
}
