.partners-group-wrapper {
    height: 240px;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 260px;
    & > .partner-group {
        & > .div {
            padding-left: 30px !important;
        }
    }
}
.partner-group {
    & > .div {
        padding-left: 30px !important;
    }
    > .partner-group-label {
        min-height: 3rem;
        > .option-left-elements {
            span {
                text-align: left;
                flex: none;
                font-size: 12px;
                font-weight: 600;
                opacity: 0.8;
            }
        }
    }
    .partner-group-label {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: var(--background);
        z-index: 1; // Ensure it stays on top of the scrolling content /
        padding: 10px;
        border-bottom: 1px solid var(--line-color);
    }

    .option-container.partner-group-label:hover {
        background-color: var(--background);
    }
}

.virtualized-partner-list {
    overflow-y: scroll !important;
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(180, 172, 172, 0.6);
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(180, 172, 172);
    }

    .option-container {
        padding-left: 20px;
        padding-right: unset;
        .partner-row-elements-container {
            display: flex;
            align-items: center;
        }
    }
}

.partner-active {
    color: var(--partner-active-color);
}

.partner-not-active {
    color: var(--partner-not-active-color);
}
