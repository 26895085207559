.crm-input-with-dropdown-wrapper {
    position: relative;
    &_option-list-content-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 21px 21px 0;
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .crm-option-list-content {
            width: 100%;
            .ReactVirtualized__Grid {
                width: 100% !important;
                .ReactVirtualized__Grid__innerScrollContainer {
                    max-width: 100% !important ;
                }
            }
        }
        .button-wrapper {
            width: 50px;
        }
        .btn {
            border-radius: 0 21px 21px 0;
            border-color: transparent;
            background: transparent;
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            // color: #3c4043;
            // border-left: 1px solid rgba(0, 0, 0, 0.2);
            // width: 50px;
            cursor: pointer;
            z-index: 1000;
            &:hover {
                background: transparent;
                color: #3c4043;
            }
        }
    }
}
