.crm-filter-tag-container {
    display: flex;
    width: 100%;
    height: 62px;
    box-sizing: border-box;
    padding: 15px 20px;
    margin-bottom: 20px;
    vertical-align: middle;
    border-radius: 20px;
    background-color: var(--background);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

    .tags-section {
        display: flex;
        position: relative;
        width: 100%;
        overflow-x: hidden;

        & > div {
            display: flex;
            width: 100%;
            position: absolute;
            overflow-x: auto;
        }

        .filter-tag {
            height: 30px;
            max-width: 200px;
            padding: 3px 10px 3px 15px;
            border-radius: 18px;
            background-color: rgba(20, 115, 230, 0.1);
            display: flex;
            align-items: center;
            margin-right: 10px;

            p {
                font-size: 14px;
                font-weight: 600;
                color: var(--hero);
                white-space: nowrap;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            i {
                color: rgba(20, 115, 230, 0.5);
                cursor: pointer;
            }
        }

        // Overwrite ui lib style
        .skeleton-holder {
            width: 200px !important;
            margin-right: 20px;

            .react-loading-skeleton {
                height: 30px !important;
            }
        }
    }

    .buttons-section {
        display: flex;
    }

    .controls-section {
        display: flex;
        margin-left: auto;
    }

    // overwrite
    .divider {
        margin: auto 1.5rem;
        background-color: var(--line-color);
    }
}
