.page-h-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.page-h-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-left: 50px;
}

.crm-page-headline {
    width: 100%;
    // overflow: hidden;
    height: 70px;
    background: var(--background);
    padding: 10px 15px 10px 0;
    margin-bottom: 1px;
    border-bottom: 1px solid var(--line-color);
    display: flex;
    justify-content: space-between;
    .crm-button-with-dropdown_icon-box {
        width: 30px !important;
        right: 5px !important;
    }
    &_back-button-part {
        width: 70px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        border-right: 1px solid var(--line-color);

        i {
            cursor: pointer;
        }
    }
    &_page-name-part {
        display: flex;
        align-items: center;
        // border-left: 1px solid var(--line-color);
        border-right: 1px solid var(--line-color);
        padding: 0 20px;
        //width: 190px;
        //flex-grow: 0;
        flex: 0 0 auto;

        p {
            font-size: 16px;
            line-height: 22px;
            color: var(--custom-color-1);
            font-weight: 600;
        }
    }
    &_content-part {
        // overflow: hidden;
        flex: 1;
        display: flex;
        //flex-direction: column;
        position: relative;
        padding-left: 10px;
        justify-content: center;
        .skeleton-holder {
            &:first-child {
                margin-bottom: 5px;
            }
        }
        .page-h-left {
            .input-element.read-only {
                .ellipsis-text {
                    position: absolute;
                }
            }
        }
    }
    //Validation Popup
    .c-validation-popup {
        .input-holder.a-outline {
            --input-element-border-color: var(--line-color);
        }
        .input-holder.a-outline.filled:not(:focus-within) {
            --input-element-border-color: var(--line-color);
        }
        .input-holder.a-outline:not(.read-only):focus-within {
            --input-element-border-color: var(--line-color);
            --swap-label-color: var(--line-color);
        }

        .textarea-element {
            height: 88px;
        }
    }

    .validation-popup-info {
        display: flex;
        align-items: center;
        width: 384px;
        margin-bottom: 2rem;
        > .v-popup-info-icon-cont {
            flex: 0 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            .v-popup-info-icon {
                font-size: 40px;
                color: #7dc681;
            }
        }
        > p {
            flex: 1;
            font-size: 14px;
            font-weight: 600;
            color: var(--custom-color-1);
            margin-left: 25px;
        }
        .modal-head {
            border-bottom: none;
        }
    }
    .v-popup-checkbox {
        margin-top: 2rem;
        display: flex;
        label.ellipsis-text {
            font-weight: 600;
        }
    }

    &_button-box {
        display: flex;
        align-items: center;
        &_button {
            display: flex;
            flex: 1;
            height: 36px;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
    &_label {
        color: var(--hero);
        display: flex;
        align-items: center;
        margin-right: 40px;
        cursor: pointer;

        p {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.36;
        }

        i {
            margin-right: 15px;
            font-size: 2.3rem;
        }
    }

    &_description {
        margin-bottom: 3px;
        padding-top: 3px;

        .input-element {
            font-size: 16px;
            height: 22px;
            line-height: 1.38;

            &::placeholder {
                font-size: 16px;
                color: var(--custom-color-1);
            }
        }
    }

    &_name {
        .input-element {
            font-size: 12px;
            height: 17px;
            line-height: 1.42;
            font-weight: normal;
            font-style: italic;

            &::placeholder {
                color: var(--custom-color-1);
                font-size: 12px;
                font-weight: normal;
            }
        }
    }

    .input-holder.a-outline {
        --input-element-border-color: rgba(0, 0, 0, 0);
    }
    .input-holder.a-outline.filled:not(:focus-within) {
        --input-element-border-color: rgba(0, 0, 0, 0);
    }
    .input-holder.a-outline:not(.read-only):focus-within {
        --input-element-border-color: rgba(0, 0, 0, 0);
        --swap-label-color: rgba(0, 0, 0, 0);
    }

    .crm-view-page-headline-action-buttons {
        display: flex;
    }
}
