.crm-label-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    text-transform: capitalize;
    color: var(--label-tag-color);
    background-color: var(--label-tag-bg-color);
    border-radius: 6px;
    border: 1px solid;
    line-height: 18px;
    font-weight: 600;
    font-size: 1.3rem;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & > button:first-child {
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & > button:last-child i {
        line-height: 18px;
    }
}

.crm-category-modal-wrap {
    .manage-label-popup-content {
        .paper.with-shadow {
            box-shadow: unset;
        }
    }
    .table-holder {
        width: 900px;
        height: 464px;
    }
    .scroll-holder {
        height: 100% !important;
    }
    .edit-create-label {
        width: 100%;
        height: 100%;
        padding: 0 20px;
        .input-holder {
            margin-top: 20px;
            border-radius: 21px;
            --input-element-border-color: none;
            //border: solid 1px rgba(0, 0, 0, 0.3);
        }
        .label-body {
            width: 500px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px 21px;
            margin-bottom: 20px;
            border-radius: 10px;
            border: 1px solid rgba(var(--background-sc-rgb), 0.1);
            .crs-item {
                position: relative;
                width: 40px;
                height: 40px;
                margin: 0 5px;
                align-items: center !important;
                justify-content: center !important;
                label:before {
                    background-color: var(--label-tag-color);
                    width: 12px !important;
                    height: 12px !important;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    transform: scale(1);
                    opacity: 0.7;
                }
                &:before {
                    position: absolute;
                    content: '';
                    border: 1px solid var(--label-tag-color);
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    opacity: 0;
                }
                .cr-element {
                    width: 24px;
                    height: 24px;
                }
            }
            .crs-holder.input-active {
                .crs-item:before {
                    opacity: 1;
                    transition: opacity 360ms;
                }
            }
        }
        .cr-element.radio {
            border: none;
            background-color: var(--label-tag-bg-color);
            &:before {
                background-color: var(--label-tag-color);
            }
            &:after {
                display: none;
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                border: 1px solid black;
            }
            &.active {
                &:after {
                    display: block;
                }
            }
        }
        .radio-but-txt {
            display: block;
            font-size: 14px;
            line-height: 20px;
            padding-left: 10px;
            margin-top: 20px;
            margin-bottom: 10px;
            color: var(--custom-color-1);
            font-weight: bold;
        }
        .preview-block {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 56px;
            margin-bottom: 20px;
            border-radius: 10px;
            border: 1px solid rgba(var(--background-sc-rgb), 0.1);
            padding: 0 15px;
        }
    }
    .modal-body {
        padding: 0;
    }
    .module-header {
        display: none;
    }
    .modal-head {
        height: 50px;
    }
    .input-holder {
        --input-element-background: var(--background);
        border-radius: 20px;
        border: solid 1px var(--line-color);
    }
    .ta-header {
        .ta-row {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
        }
        .th-divider-holder {
            display: none;
        }
        .ta-cell.a-square {
            display: none;
        }
    }
    .ta-gr-left-line + .ta-cell {
        display: none;
    }
    .ta-gr-left-line + .ta-cell + .ta-cell + .ta-cell {
        padding-left: 46px;
    }
    .ta-header {
        border-top: 1px solid rgba(var(--background-sc-rgb), 0.1);
    }
    .manage-cancel-but {
        background: var(--hero);
        color: var(--hero-sc) !important;
        &:hover {
            background: var(--hero-hover) !important;
        }
    }
    .ellipsis-text {
        text-transform: capitalize;
    }
    .table-heading-text {
        color: var(--custom-color-1);
    }
    .modal-head {
        > div {
            font-weight: bold;
            color: var(--custom-color-1);
        }
    }
    .paper.full-radius {
        border-radius: unset;
    }
    .bc-icon-arrow-back {
        position: absolute;
        left: 23px;
        cursor: pointer;
    }
    .ta-cell > .ellipsis-text {
        color: var(--custom-color-1);
    }
    .bc-icon-search {
        color: var(--custom-color-1);
    }
    .pagination-drop {
        color: var(--custom-color-1);
    }
    .input-element {
        color: var(--custom-color-1);
        font-weight: 600;
    }
    .input-swap-animate {
        color: var(--custom-color-1);
        font-weight: 600;
    }
    .modal-splash.light-background {
        z-index: 999;
    }
    .ta-group {
        max-height: 43px !important;
    }
    .paper.with-shadow {
        border: none;
    }
}

.manage-label-popup-content {
    .paper-actions {
        .paper-left-actions {
            .input-holder {
                //border-color: rgba(0, 0, 0, 0.2);
                .input-structure {
                    width: 250px;
                    height: 36px;
                    label {
                        .input-element {
                            padding-bottom: 3px;
                        }
                    }
                    ::placeholder {
                        color: rgba(60, 64, 67, 0.7);
                    }
                }
            }
        }
    }
}
