@mixin crm-list-column-title-icon-components {
    .crm-list-column-title-icon-components {
        display: flex;

        .tittle-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 12px;
        }

        & > span,
        & > a {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & > i.icon {
            margin-left: auto;
            font-size: 20px;
            color: var(--c-blue-marguerite);
        }
    }
}
