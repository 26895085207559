:root {
    // colors
    --c-catskill-white: #f9fbfc;
    --c-texas-rose: #ffad5f;
    --c-feijoa: #abd47c;
    --c-feijo: #5faa09;
    --c-monte-carlo: #7fcac3;
    --c-peach-orange: #ffc99c;
    --c-cape-cod: #3c4043;
    --c-denim: var(--hero);
    --c-mercury: #e8e8e8;
    --gun-powder: #3d3d5f;
    --c-amaranth: #df2f4d;
    --c-mercury: #e6e6e6;
    --c-bridal-heath: #fffaf5;
    --charcoal-grey: #3c4043;
    --athens-gray: #f4f6f8;
    --c-nobel: #b4b4b4;
    --c-white: #ffffff;
    --c-gray: #898989;
    --c-gray-chateau: #9d9fa1;
    --c-blue-marguerite: #7481c9;
    --c-green: #47b4a9;
    --c-cyan-blue: var(--hero);
    --c-blue-light: #44aac2;

    --feijoa: #abd47c;
    --froly: #f7776e;
    --golden-tainoi: #ffcb69;
    --amethyst: #9171cb;
    --c-white: #ffffff;
    --c-bunker: #141921;

    --c-golden-tainoi: #ffca5f; // Scheduled, Suspended
    --c-amethyst: #9171cb; // Idle, Archived
    --c-jaffa: #f48a3a; // InProgress
    --c-fern: #6fb873; // Completed
    --c-curious-blue: #207ede; // Sent, NonStarted
    --c-amaranth: #df2f4d; // Stopped, Invalid
    --c-cinnabar: #e74e42; // Failed, Error
    --c-jafa: #f36f4e; // Resuming, Error
    --active-green: #2bc784;

    // rgb's
    --c-rgb-feijoa: 171, 212, 124;
    --c-rgb-cinnabar: 230, 78, 72;
    --c-rgb-texas-rose: 255, 173, 95;

    --delete-modal-color: var(--c-amaranth);
    --archive-modal-color: var(--c-texas-rose);
    --circle-icon-bg: var(--c-bridal-heath);

    // Statuses labels
    --status-label-scheduled-color: var(--c-gray);
    --status-label-suspended-color: var(--c-jaffa);
    --status-label-idle-color: var(--c-jaffa);
    --status-label-archived-color: var(--c-amethyst);
    --status-label-in-progress-color: var(--c-curious-blue);
    --status-label-completed-color: var(--c-fern);
    --status-label-sent-color: var(--c-curious-blue);
    --status-label-non-started-color: var(--c-gray);
    --status-label-stopped-color: var(--c-amethyst);
    --status-label-expired-color: var(--c-amethyst);
    --status-label-invalid-color: var(--c-amaranth);
    --status-label-failed-color: var(--c-amaranth);
    --status-label-error-color: var(--c-amaranth);
    --status-label-goal-color: var(--c-green);
    --status-label-event-draft-color: var(--c-gray);
    --status-label-selected-channel-color: var(--c-cyan-blue);
    --status-label-starting-color: var(--froly);
    --status-label-updating-color: var(--froly);
    --status-label-resuming-color: var(--froly);
    --status-label-suspending-color: var(--froly);

    // Mode labels
    //TODO: should replace after designer review
    --mode-label-live-color: var(--c-fern);
    --mode-label-test-color: var(--golden-tainoi);
    --mode-label-draft-color: var(--froly);
    --mode-label-archived-color: var(--c-amethyst);

    // Used or not labels
    //TODO: should replace after designer review
    --used-or-not-label-used-color: var(--c-curious-blue);
    --used-or-not-label-not-used-color: var(--c-gray);

    // Report state labels
    //TODO: should replace after designer review
    --report-state-label-standart-color: var(--c-gray);
    --report-state-label-scheduled-color: var(--c-gray);

    // Tittle link text
    --tittle-link-text-color: var(--c-curious-blue);

    // Communication state labels
    --communication-state-label-success-color: var(--status-label-completed-color);
    --communication-state-label-failed-color: var(--status-label-failed-color);

    // Icon statuses
    --status-icon-error-color: var(--c-amaranth);

    // Segmentation colors
    --profile-segmentation-color: var(--c-blue-light);

    // Partner Statuses colors
    --partner-active-color: var(--active-green);
    --partner-not-active-color: var(--c-gray);

    // generic
    --zoom: 1;

    // Status colors
    --status-success-color: var(--active-green);
    --status-error-color: var(--c-amaranth);
}
