.crm-data-table {
    .paper-actions {
        .paper-left-actions {
            flex: unset;
            padding-right: 0;
        }

        .paper-right-actions {
            flex: 1;
        }
    }

    //.scroll-holder {
    //    min-height: 47.3rem;
    //}

    .crm-data-table-header-bulk-actions {
        display: flex;
        align-items: center;
        border-left: solid 1px rgba(0, 0, 0, 0.1);
        margin-left: 10px;

        & button.btn {
            margin: 0 10px;
        }

        & button.btn:first-child {
            margin: 0 10px 0 20px;
        }

        & > .crm-data-table-header-selected {
            margin-left: 20px;
            font-weight: 600;
            color: var(--c-denim);
        }
    }

    .right-actions-holder {
        margin-left: auto;
    }

    &.crm-data-table-hide-header-footer {
        .module-header,
        .paper-actions,
        .ta-pagination-holder {
            display: none;
        }
        .paper.with-shadow {
            border: unset;
        }
    }

    &.crm-data-table-hide-header {
        .module-header {
            display: none;
        }

        .paper.full-radius {
            border-radius: unset;
        }
    }

    &.crm-data-table-hide-footer {
        .ta-pagination-holder {
            display: none;
        }
    }
}

.crm-data-table-header-action-bar {
    .crm-data-table-header-action-bar-left-actions {
        display: flex;
    }

    .crm-data-table-header-action-bar-right-actions {
        margin-left: auto !important;
    }
}
.campaign-tabs-wrapper {
    .crm-data-table-header-action-bar-right-actions {
        width: 100%;
        margin-left: 0 !important;
    }
}

.crm-data-table-hide-header-left-actions-bar > .paper > .paper-actions {
    .paper-left-actions {
        padding: 0;
    }
}

.crm-data-table-hide-expand-icon {
    .left-actions-holder {
        display: none;
    }
    .ta-header {
        .ta-row {
            .ta-cell.a-square {
                display: none;
            }
        }
    }
}
.crm-data-table-header-action-bar-right-actions {
    display: flex;
    flex-direction: row;
    .input-holder {
        max-width: 250px;
    }
    button {
        display: flex;
    }
    .dropdown-holder {
        max-width: 250px;
    }
    > div:not(.download-toolbar) {
        margin-right: 20px;
        z-index: 100;
        position: relative;
    }
}
.crm-tab-content {
    position: relative;
    overflow: hidden;
}
.overlay-content-wrap {
    position: absolute;
    top: 126px;
    right: 0;
    width: 940px;
    height: 70%;
    background-color: var(--background);
    border-top: 1px solid rgba(var(--background-sc-rgb), 0.1);
    border-left: 1px solid rgba(var(--background-sc-rgb), 0.1);
    transition: transform 360ms;
    .overlay-button {
        position: absolute;
        top: -1px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--background);
        border: 1px solid rgba(var(--background-sc-rgb), 0.1);
        border-right: 0;
        border-radius: 0;
        &.btn:not(.active).a-minimal:hover {
            background: var(--background);
        }
        &.disabled {
            user-select: none;
            pointer-events: none;
            i {
                color: rgba(60, 64, 67, 0.5);
            }
        }
    }
}
.overlay-left {
    transform: translateX(100%);
}
.overlay-clear {
    transform: translateX(-1px);
}
.reset-button.btn:not(.c-icon).f-default {
    min-width: 16rem;
}
.overlay-content-wrap.overlay-left {
    .overlay-button {
        left: -61px;
    }
}
.overlay-content-wrap.overlay-clear {
    .overlay-button {
        left: -60px;
    }
}

/////////////////////////////////////////////////////
.campaign-tabs-wrapper {
    .crm-segment-type-label > .icon {
        margin-right: 1.2rem;
        &.bc-icon-crm-free-bet {
            color: lightsalmon;
        }
        &.bc-icon-crm-free-spin {
            color: lightseagreen;
        }
        &.bc-icon-crm-deposit-bonus {
            color: lightskyblue;
        }
    }
}

.crm-data-table-selected-items-modal {
    .modal-body {
        width: 710px;
        height: 460px;
        padding: 0;

        .paper.full-radius {
            border-radius: unset;
        }

        .paper.with-shadow {
            box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.5);
        }

        .crm-common-table {
            overflow-y: auto;

            .table-holder .scroll-holder {
                height: 460px !important; // TODO replace selector tmp workaround
            }
        }
    }
}
