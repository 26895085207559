.social-n-details-panel {
    border-radius: 2rem;
    border: 1px solid var(--line-color);
    background-color: var(--background);
}
.social-n-details-panel-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem;
}
.social-n-details-panel-choose-file {
    display: flex;
    align-items: center;
}
.social-n-details-panel-choose-file-title {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    margin-right: 2rem;
}
.social-n-details-panel-browse {
    margin-right: 2rem;
    min-width: 30rem;
    max-width: 40rem;
}
.social-c-file-upload-details {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    .social-c-file-upload-details-img-wrapper {
        height: 110px;
        width: 110px;
        border: 1px dotted rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        margin-right: 15px;
    }
    p {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.6rem;
        text-transform: capitalize;
        margin-right: 2rem;
    }
    img {
        width: 110px;
        height: 110px;
        object-fit: contain;
        margin-right: 2rem;
    }
    i {
        color: rgb(230, 78, 72);
        cursor: pointer;
    }
}

.social-template-section-container {
    padding: 0 2rem 0 2rem;
}

.social-inputs-container {
    display: flex;
    padding: 2rem 0;
    .input-holder {
        width: 500px;
        &:last-child {
            margin-left: 50px;
        }
    }
}

.viber-content {
    .social-inputs-container {
        padding-bottom: 0;
    }
}

.viber-template-validity-period-container {
    padding: 0 2rem 2rem 2rem;
    .viber-template-validity-period-section {
        padding: 2rem;
        border-radius: 2rem;
        border: 1px solid var(--line-color);
        background-color: var(--background);
        display: flex;
        column-gap: 2rem;
        .viber-template-validity-period-title {
            margin-top: 1rem;
            font-weight: 600;
        }
        .viber-template-validity-period-input-container {
            width: 30rem;
        }
    }
}

.viber-template-cascade-section {
    .viber-template-validity-period-container {
        padding: 0 0 2rem;
    }
}

.viber-template-cascade-container {
    padding: 0 2rem 2rem;
    display: flex;
    column-gap: 2rem;
    .viber-template-cascade-section {
        flex: 1;
        .section-holder {
            height: 100%;
            .viber-template-cascade-section-content {
                .social-template-section-container {
                    padding: 0;
                }

                .crm-create-template-page-c-c-content {
                    padding: 0 0 2rem;
                }
            }
        }
    }
    .viber-template-cascade-section.sms-content {
        .section-body {
            height: 100%;
            .viber-template-cascade-section-content {
                height: 100%;
                .crm-create-template-page-c-c-content {
                    height: calc(100% - 36px);
                    .input-structure {
                        padding: 0 1.5rem;
                    }
                }
            }
        }
    }
}

.crm-fnt-content {
    padding: 0 2rem;
    margin-top: 2rem;

    .crm-fnt-sub-content {
        display: flex;
        align-items: center;
        column-gap: 2rem;

        .crm-fnt-category-dropdown {
            min-width: 250px;
        }
        .crm-fnt-important-cb {
            min-width: 170px;
        }
    }
    .crm-messaging-row {
        min-height: 350px;
        margin-top: 4rem;
    }
}
