.crm-category-modal-wrap {
    .create-tag-content {
        padding: 20px;
    }
    .ta-gr-left-line + .ta-cell + .ta-cell + .ta-cell {
        padding-left: 2rem;
    }
    .modal-body {
        .alert-holder {
            padding: 2rem 2rem 0 2rem;
        }
    }
}

.crm-data-table-row-disabled {
    .ta-row {
        .ta-cell:not(.right-actions-holder) {
            opacity: 0.6;
        }
    }
}

.manage-tags-modal {
    width: 100%;
    .modal-content.s-default {
        width: 100%;
    }
    .modal-content {
        display: flex;
        flex-direction: column;
        max-width: 120rem;
        max-height: 80rem;
        height: 100%;
        .modal-body {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: hidden;
            .clients-file-uploader {
                margin-bottom: 2.5rem;
                .form {
                    display: flex;
                    align-items: center;
                    .file-browser {
                        flex: 5;
                    }
                    .bc-icon-info {
                        margin-left: 6px;
                        margin-right: 20px;
                    }
                    .client-fields {
                        flex: 2;
                    }
                    .submit-button {
                        flex: 1;
                    }
                }
            }
            .manage-tag-container {
                flex: 1;
                overflow-y: hidden;
                display: flex;
                flex-direction: column;
                .create-new-tag-container {
                    width: 100%;
                    height: 36px;
                    display: flex;
                    column-gap: 2rem;
                    .create-tag-button {
                        width: 170px;
                    }
                    .new-tag-name-input {
                        width: 350px;
                    }
                }
                .action-container {
                    display: flex;
                    align-items: center;
                    margin-top: 2rem;
                    .cha-ra-group {
                        label {
                            display: flex;
                            align-items: center;
                            i {
                                padding-left: 6px;
                            }
                            p {
                                font-size: 14px;
                                font-weight: 600;
                                color: var(--custom-color-1);
                            }
                        }
                        .crs-holder {
                            padding-left: unset;
                        }
                    }
                    .bc-icon-info {
                        margin-left: 6px;
                        margin-right: 20px;
                    }
                }
                .client-tag-data-table {
                    flex: 1;
                    overflow-y: hidden;
                    margin-top: 2rem;
                    .paper {
                        overflow-y: hidden;
                        height: 100%;
                        border: 1px solid rgba(var(--background-sc-rgb), 0.1);
                        .paper-actions {
                            .crm-data-table-header-action-bar-right-actions > div {
                                margin-right: unset;
                            }
                            .crm-data-table-header-action-bar-right-actions .input-holder {
                                max-width: unset;
                            }
                        }
                        .module-header {
                            display: none;
                        }
                        .table-holder {
                            height: calc(100% - 82px);
                        }
                    }
                }
            }
        }
    }
}
