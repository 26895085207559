@import './mixins';

.dashboard-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;

    @include crm-list-column-title-icon-components;
}

.dashboard-kpis-wrap {
    width: 100%;
    margin-bottom: 2rem;
    //display: none;
}

.dashboard-kpis-p-w {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: grid;
    grid-template-rows: auto 11fr;
}

.dashboard-kpis-p-w-head {
    width: 100%;
}

.dashboard-kpis-p-w-content {
    width: 100%;
    padding: 15px 0;
}

.date-widgets {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 2rem;
    .popover-track {
        max-width: 238px;
    }
    .date-filters-holder {
        padding-right: 10px;
    }
    .date-filter-input.active .icon {
        background: unset;
        color: unset;
    }

    .btn {
        margin-left: 12px;
    }
}

.widgets-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 12fr;
    grid-gap: 20px;
}

.campaign-action-metrics-wrap {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-gap: 20px;
    padding: 20px 0;
}

.campaign-action-metrics-wrap-column {
    display: grid;
    grid-template-rows: auto 11fr;
    background-color: var(--background);
    border-radius: 20px;
    border: 1px solid var(--line-color);
    &:only-child {
        grid-column: span 2;
    }

    .cha-ra-group {
        padding: 2rem;
    }
}

.campaign-action-wrap-column-head {
    width: 100%;
    .cha-ra-group.t-tab {
        width: 100%;
        flex: 0 0 auto;
        justify-content: flex-end;
        padding-right: 2rem;
        box-shadow: none;
    }
}

.for-crs-holder-to-button-style {
    .cha-ra-group.t-tab .crs-holder .crs-component {
        border: none;
    }
    .cha-ra-group.t-tab .crs-holder .crs-label {
        border-radius: 20px;
        label {
            transition: 0ms color;
            color: rgba(var(--background-sc-rgb), 0.75);
            text-align: center;
            font: 600 1.4rem/3.6rem var(--font-family);
        }
    }
    .cha-ra-group.t-tab .crs-holder:not(.input-active) .crs-label:hover {
        background: rgba(var(--background-sc-rgb), 0.03);
        transition: 300ms background;
    }
    .cha-ra-group.t-tab .crs-holder.input-active .crs-label {
        background: var(--hero);
        label {
            color: var(--hero-sc);
        }
    }
}

.campaign-metrics-wrap-column-head,
.charts-wrap-inner-head {
    width: 100%;
    .campaign-metrics-wrap-c-h-d {
        display: flex;
        width: 100%;
        padding: 2rem;
        align-items: center;
        justify-content: flex-end;

        .popover-track {
            max-width: 228px;
        }
        .crs-item {
            label.ellipsis-text {
                text-align: center;
            }
        }
    }
}

.campaign-metrics-wrap-column-content,
.campaign-action-wrap-column-content {
    .paper.with-shadow {
        box-shadow: unset;
    }
}

.charts-wrap {
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    grid-template-rows: 12fr;
    grid-gap: 20px;
}

.charts-wrap-inner {
    display: grid;
    grid-template-rows: auto 11fr;
    background-color: var(--background);
    border-radius: 20px;
    border: 1px solid var(--line-color);
    min-height: 600px;

    .highcharts-background {
        fill: var(--background);
    }
}

.charts-wrap-inner-content {
    padding: 2rem;
    box-sizing: border-box;
    .highcharts-container {
        width: 100% !important;
        height: 100% !important;
    }
}

.crm-report-d-a-d-container {
    height: 100%;
}

// Overwrite widget styles
.widget-holder {
    > li.text > p {
        font: 700 var(--font-size, 1.4rem) / 3rem var(--font-family);
    }
    .comparison-text {
        font-weight: 700;
    }
    .input-element-back {
        border: none;
    }
    .module-header {
        .left-content {
            display: none;
        }
        .right-content {
            justify-content: flex-end;
            width: 100%;

            .input-structure,
            .input-element {
                padding-left: 0;
            }
        }
        .comparison-text {
            font-weight: 700;
        }
        .input-element-back {
            border: none;
        }
    }
}

// .DKPIActiveUsers {
//     color: #14cee6;
// }

// .DKPIRetentionRate {
//     color: #e84855;
// }

// .DKPIConversionRate {
//     color: #ffcc4d;
// }

// .DKPIMoneyRetentionRate {
//     color: #60afff;
// }

// .DKPIDAUMAU {
//     color: #6e51cf;
// }

// .DKPIDAUWAU {
//     color: #7fffd4;
// }

// .DKPIARPU {
//     color: #006400;
// }

// .DKPIReactivationRate {
//     color: #c71585;
// }

// .DKPICLV {
//     color: #604020;
// }
