$btn-main-color: var(--hero);

.crm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--header-primary-color);
    height: 56px;
    padding: 0 20px;
    box-shadow: 0 1px var(--line-color);
    color: #ffffff;
    z-index: 210;

    .dropdown-holder {
        .input-element-back {
            background: var(--header-primary-color);
            border: none;
        }
    }

    .divider {
        --background-sc-rgb: var(--foreground-sc-rgb);
    }
}

.crm-header-column {
    display: flex;
    align-items: center;
    .logo {
        width: 35px;
        height: 30px;
        padding: 0;

        .image-content {
            width: 30px;
            height: 30px;

            img {
                border-radius: 0;
            }
        }
    }

    .navigation-button-wrap {
        &.popover-opened {
            .btn.h-btn-dash {
                background-color: var(--active-purple);
                border-radius: 20px;
            }
        }

        .btn.h-btn-dash {
            color: var(--hero-sc);
            background: transparent;
            margin-left: 0;
            border-radius: 20px;
            border: none;
            flex-direction: row-reverse;
            justify-content: space-between;
            max-width: 100%;
            --input-message-padding-side: 2rem;
            span {
                font-weight: bold;
                padding-right: 6px;
            }
            i {
                line-height: 25px;
            }
            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }
        }
    }

    .global-search-container {
        z-index: 1;
        .searchResultRow {
            .bc-icon-new-window {
                font-size: 2rem;
            }
        }
        //TODO: remove after Geneui provide isOpen state
        .modal-splash {
            z-index: 400;
        }
    }

    .navigation-button-wrap.popover-opened {
        .btn.a-default.transparent-btn {
            background-color: var(--active-purple);
            border-radius: 20px;
        }
    }

    .btn.a-default.transparent-btn {
        background: transparent;
        border-radius: unset;

        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }
        .icon {
            line-height: 25px;
        }
    }

    .dropdown-holder {
        margin-left: 0;
    }

    .btn:not(.c-icon).f-default.create-btn {
        min-width: 113px;
        padding-left: 10px;
        color: #fff;
        border-color: #fff;
        min-height: 34px;

        > span {
            font-weight: 600;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        &.active {
            background: var(--hero-sc);
            color: #3e3e55;
            border-radius: 100px;
            height: 36px;
        }
    }
    .time-holder {
        p {
            min-width: 57px;
        }
    }

    .partners-button-wrap {
        .crm-c-tooltip {
            margin-top: 1.1rem;
        }

        .btn.a-default.transparent-btn {
            padding: 0 7px 0 11px;
            min-width: 127px;
            margin-left: 0;
            > span {
                font-weight: 600;
                padding-right: 6px;
            }
        }

        .btn > * + * {
            margin-left: 0;
        }
    }

    .btn.a-default.transparent-btn {
        background: transparent;
        margin-left: 0;
        border-radius: 20px;
        border: none;
    }

    .btn.a-default,
    .btn.active {
        min-width: 32px;
        min-height: 32px;
    }

    .btn.a-default.user-btn {
        min-width: fit-content;
        padding-left: 3px;
        height: 32px;
        border-radius: 16px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.02);
        background-color: rgba(255, 255, 255, 0.05);

        .ellipsis-text {
            margin-left: 15px;
        }

        i {
            background: var(--hero-sc);
            padding: 4px;
            border-radius: 100px;
            color: #3e3e55;
            font-size: 18px;
            width: 26px;
            height: 26px;
        }
    }

    .partners-button-wrap.popover-opened {
        .btn.transparent-btn {
            background: var(--active-purple);
        }
    }

    .btn.transparent-btn.popover-opened {
        background: var(--active-purple);
    }
}
.navigation-wrap {
    position: relative;
    .navigation-popover-content {
        position: absolute;
        left: 100%;
        top: 50px;
        border-radius: 10px;
        transform: translate(-50%, 0);
        background: var(--background);
        box-shadow: 0 2px 4px 0rgba 0, 0, 0, 0.1;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        color: var(--custom-color-1);
    }
}
.navigation-popover-content {
    width: 280px;
    padding: 8px 0;
    .crm-option-list-content {
        &__option-list {
            width: 260px;
        }
        .partner-search-input {
            i {
                font-size: 1.8rem;
            }
        }
    }
    .partners-button-wrap {
        .option-list-button {
            border-radius: 20px;
            color: #ffffff;
            width: 127px;
            height: 32px;
            margin: 0;
            border: transparent;
            background-color: var(--header-primary-color);
            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                color: #ffffff;
            }
            &.option-list-button {
                background-color: var(--header-primary-color);
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &[style] {
        top: 45px !important;
    }

    .icon-segment:before,
    .icon-campaign:before,
    .icon-customer-journey:before {
        font-size: 21px;
    }
}

.crm-header-maintenance-main-content {
    .maintenance-title {
        line-height: 1.2;
    }
    .maintenance-start-date-container {
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
        margin-top: 2px;
        font-size: 1.2rem;
        & > .icon {
            font-size: 1.6rem;
        }
        .maintenance-start-date {
            color: #ff008c;
        }
    }
    .maintenance-duration-container {
        font-size: 1.2rem;
    }
}

.partner-popover-content {
    width: auto;
    padding: 8px 0;
    .option-left-elements,
    .option-title {
        width: 100%;
        justify-content: space-between;
    }
    .option-left-elements {
        span {
            display: block;

            &:first-child {
                flex: 1;
                min-width: 0;
                margin-right: 10px;
                text-align: left;
            }
            &:last-child {
                flex: 0 0 30%;
                text-align: right;
            }
        }
    }
    .option-title {
        padding-right: 15px;
    }
}

.popover-positioner.create-menu-popover {
    &[style] {
        top: 52px !important;
    }

    .popover-content {
        border-radius: 20px;
    }
}

.popover-content {
    border: solid 1px rgba(112, 112, 112, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.option-title {
    font-weight: 600;
}

.modal-heading {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-color);
    margin: 5px 0 25px 0;
}

.CreateMenuActionButton {
    min-width: 74px;
    cursor: pointer;
    color: var(--text-color);
    transition: color ease-in 0.2s;

    &:hover {
        color: $btn-main-color;
    }

    &:hover i {
        background-color: $btn-main-color;
        color: #fff;
    }

    i {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.03);
        font-weight: normal;
        transition: all ease-in 0.2s;
    }

    span {
        margin-top: 6px;
        text-transform: capitalize;
    }
}

.ProductsDropdownContent {
    width: 324px;
    height: 469px;
    padding: 20px 20px 34px 20px;

    .CreateMenuActionButton {
        width: 90px;
        margin-top: 20px;
    }

    .divider {
        width: 100%;
    }
}

.crm-menu-item-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 74px;
    cursor: pointer;
    color: var(--custom-color-1);
    transition: color ease-in 0.2s;

    &:hover {
        color: $btn-main-color;
    }

    &:hover i {
        background-color: $btn-main-color;
        color: #fff;
    }

    i {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50%;
        background-color: rgba(155, 155, 155, 0.05);
        font-weight: normal;
        transition: all ease-in 0.2s;
    }

    span {
        display: block;
        margin-top: 6px;
        text-transform: capitalize;
        font-weight: 600;
        max-width: 88px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-width: 100%;
    }
}

.crm-products-list-cnt {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 320px;
    padding: 20px 20px 40px 20px;
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translate(-50%, 0);
    background: var(--background);
    box-shadow: 0 2px 4px 0rgba (0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transform: translate(-50%, 0);

    .divider {
        width: 100%;
        margin: 20px 0;
    }
}

.crm-products-list-cnt-inner {
    display: flex;
    flex-wrap: wrap;

    .crm-menu-item-circle {
        width: 92px;
        margin-top: 20px;
    }

    .divider.type-horizontal {
        width: 100%;
        margin: 20px 0 0 0;
    }
}

.crm-user-profile-cnt {
    width: 248px;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 50px;
    background-color: var(--background);
    box-shadow: 0 2px 4px 0 rgba(#000000, 0.1);
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: var(--background);
    border-radius: 10px;
    color: var(--custom-color-1);

    .crm-lng-img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
}
.create-wrap {
    position: relative;
}
.products-wrap {
    position: relative;
}
.profile-wrap {
    position: relative;
}
.crm-header-create-cnt {
    width: 296px;
    padding-bottom: 10px;
    font-weight: 600;
    user-select: none;
    position: absolute;
    left: 50%;
    top: 50px;
    background: var(--background);
    box-shadow: 0 2px 4px 0 rgba(#000000, 0.1);
    border: solid 1px rgba(#000000, 0.1);
    border-radius: 10px;
    transform: translate(-50%, 0);
    cursor: default;

    .crm-menu-item-circle {
        width: 88px;
        height: 88px;
        margin: 10px 5px;
    }

    .option-container {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
        &:hover {
            background-color: rgba(20, 115, 230, 0.05);
        }
    }

    .option-right-elements {
        color: #3b8ae9;
    }
}

.crm-h-c-cnt-head {
    height: 50px;
    padding: 15px;
    border-bottom: solid 1px var(--line-color);
    text-align: center;
    color: var(--custom-color-1);

    span {
        font-weight: 600;
        line-height: 20px;
    }

    .back-btn {
        position: absolute;
        top: 14px;
    }
}

.crm-h-c-cnt-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: rgba(var(--background-sc-rgb), 0.75);
}

.crm-h-c-cnt-footer {
    padding: 0 20px;
}

.devider-with-text {
    position: relative;
    text-transform: lowercase;
    text-align: center;
    line-height: 0;
    font-weight: 600;
    padding: 20px 10px 10px 10px;
    color: rgba(var(--background-sc-rgb), 0.75);

    &::after,
    &::before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 40%;
        background-color: var(--c-mercury);
    }

    &::after {
        left: 0;
    }

    &::before {
        right: 0;
    }
}

.crm-h-c-cnt-f-inner {
    padding: 20px 10px 10px 10px;
    > button {
        background: rgba(0, 0, 0, 0.03) !important;
        box-shadow: none !important;
        border: none !important;
        transition: background 240ms;
        > span {
            color: var(--custom-color-1);
            transition: color 240ms;
            font-weight: 600;
        }
        &:hover {
            background: var(--hero) !important;
            > span {
                color: #ffffff;
            }
        }
    }
}
