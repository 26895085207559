.drag-and-drop-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    position: relative;
    font-size: 1.4rem;
}
.drag-and-drop-inner {
    display: grid;
    grid-template-rows: auto auto 1fr;
}
.drag-and-drop-tween-arrows {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-left: 1px solid rgba(var(--background-sc-rgb), 0.1);
    border-right: 1px solid rgba(var(--background-sc-rgb), 0.1);
    .d-a-d-button {
        &.a-default:not(.d-a-d-button-active) {
            background-color: transparent;
            color: rgba(60, 64, 67, 0.7);

            &:hover {
                background-color: rgba(20, 115, 230, 0.1);
                color: var(--hero);
            }
        }

        &:first-child {
            margin-bottom: 2rem;
        }

        &.d-a-d-button-active {
            background-color: var(--hero);
            color: #fff;
        }
    }
}
.d-a-d-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-bottom: solid 1px rgba(var(--background-sc-rgb), 0.1);
    p {
        font-weight: 600;
        span {
            font-weight: inherit;
        }
    }
}
.drag-and-drop-heading {
    font-weight: 600;
    color: var(--hero);
}
.drag-and-drop-choose {
    padding: 0 0.6rem 0 2rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 11fr;
    grid-template-areas: 'top-select' 'bottom-select';
    grid-row-gap: 2rem;
    position: relative;
}

.d-a-d-select-all {
    grid-area: top-select;
    display: flex;
    align-items: center;
    color: var(--custom-color-1);
    font-weight: bold;
    padding: 0 2rem 0 1.6rem;
    label {
        margin-right: 1.5rem;
        font-weight: 600;
    }
}
.drag-and-drop-bg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 1.5rem;
    .drag-and-drop-bg-text {
        font-weight: 600;
        color: var(--custom-color-1);
        text-align: center;
    }
    i {
        font-size: 14rem;
        color: #ebeff2;
    }
}
.drag-and-drop-bg-hidden {
    display: none;
}
.drag-and-drop-wrapper {
    .empty-state-holder {
        width: 100%;
        height: 100%;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .empty-state-image {
        img {
            width: 12rem;
            height: 12rem;
        }
    }
    .empty-state-title {
        font-size: 1.4rem;
        color: var(--custom-color-1);
    }
}
.d-a-d-list {
    grid-area: bottom-select;
    height: 100%;
    width: 100%;
    color: var(--custom-color-1);
    position: relative;
    overflow: hidden;
    &.dragging {
        .d-a-d-item.selected {
            // opacity: 0.5;

            & + .d-a-d-child-wrapper .d-a-d-item {
                opacity: 0.5;
            }
        }
    }
}
.d-a-d-scrollable-cont {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;
}
.d-a-d-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    height: 42px;
    border-radius: 10px;
    border: solid 1px rgba(var(--background-sc-rgb), 0.1);
    margin-bottom: 1.5rem;
    background-color: var(--background);
    cursor: pointer;
    transition: all 0.3s;
    margin-right: 0.6rem;
    position: relative;
    user-select: none;

    span {
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &.d-a-d-child {
        .crs-component {
            left: 5px;
        }
    }

    &.active {
        & + .d-a-d-child-wrapper {
            max-height: 8000px;
        }
    }

    &.selected-item {
        font-weight: 600;
        border: 1px solid #7dc681;
        &::after {
            content: '';
            position: absolute;
            top: -1px;
            left: 20px;
            background-color: #7dc681;
            width: 16px;
            height: 24px;
            clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 50% 70%);
        }
        .crs-holder {
            display: none;
        }
        span {
            padding-left: 36px;
        }
    }
    .crs-holder,
    .label-holder,
    .crs-label {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
    }
    .crs-component {
        height: 16px;
        width: 16px;
    }
    &.d-a-d-deactive {
        opacity: 0.5;
        pointer-events: none;
    }
    &:not(.selected-item):hover {
        background-color: rgba(20, 115, 230, 0.05);
        color: var(--hero);
        border: solid 1px rgba(20, 115, 230, 0.1);
        button.btn {
            i {
                color: var(--hero);
            }
        }
    }
    &:not(.selected-item):active {
        box-shadow: 0 2px 4px 0 rgba(20, 115, 230, 0.1);
        border: 1px solid transparent;
    }
    .crs-label label {
        margin-right: 1.5rem;
        font-weight: 600;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-left: 6rem;
        display: block;
        padding-right: 5.2rem;
        padding-top: 1.2rem;
    }
    i {
        margin-left: auto;
    }
    .tween-block-drpd-arrow:first-child {
        transition: transform 0.24s ease-in-out;
        color: var(--custom-color-1);
        & + .crs-holder {
            .label-holder .crs-item {
                left: 54px;
            }
        }
    }
}

.tween-block-drpd-arrow {
    margin-right: 15px;
    &:hover {
        background-color: transparent !important;
    }
}

.d-a-d-child-wrapper {
    width: 100%;
    padding-left: 34px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.24s ease-in-out;
}
.d-a-d-item-draggable {
    display: flex;
    align-items: center;
    padding: 8px 15px 8px 20px;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    background-color: #f3f8fe;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(20, 115, 230, 0.1);
    color: var(--hero);

    p {
        margin-left: 1.5rem;
        font-weight: 600;
    }

    i {
        margin-left: auto;
    }

    span {
        font-weight: 600;
    }

    &.permanent-row {
        border: 1px solid #7dc681;

        &::after {
            content: '';
            position: absolute;
            top: -1px;
            left: 20px;
            background-color: #7dc681;
            width: 16px;
            height: 24px;
            clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 50% 70%);
        }

        .crs-holder {
            display: none;
        }

        span {
            position: absolute;
            left: 56px;
            color: var(--custom-color-1);
        }

        .icon {
            color: var(--custom-color-1);
        }
    }
}
.dragging-els-info {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: none;
    width: 40rem;
    height: 4.2rem;
    &.active {
        pointer-events: auto;
        display: block;
    }
}
.create-dynamic-segment-p-w-content + .crm-report-fields-wrapper {
    .dragging-els-info {
        width: 100%;
    }
}
.drag-and-drop-search {
    padding: 2rem;

    .input-structure {
        height: 4.2rem;
    }
}
.d-a-d-area {
    margin-top: 2rem;
    width: 100%;
    height: 4.2rem;
    .d-a-d-area-item {
        color: rgba(60, 64, 67, 0.5);
        font-weight: 600;
        padding: 1rem 0;
        background-color: #fdfdfd;
        border-radius: 1rem;
        border: 1px dashed rgba(60, 64, 67, 0.3);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
            border-color: var(--hero);
            background-color: rgba(20, 115, 230, 0.1);
            color: var(--hero);
        }
    }
}

.d-a-d-item-label-part {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    max-width: calc(100% - 24px);

    .crs-holder {
        margin-right: 20px;
    }

    .crs-holder,
    .label-holder {
        position: static;
        width: auto;
    }
}
.d-a-d-scrollable-cont {
    > div {
        position: relative;

        > .tween-block-drpd-arrow {
            position: absolute;
            top: 1rem !important;
            left: 1rem !important;
            z-index: 50;
            transition: transform 240ms;
            width: 24px;
            height: 24px;
            min-width: auto;
            min-height: auto;
            border: none;

            &.activated {
                transform: rotate(90deg);
            }
            & + .d-a-d-item {
                padding-left: 4.8rem;
            }
        }
    }
}
