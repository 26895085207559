.report-info-modal-c {
    .modal-body {
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .title-text {
        > .ellipsis-text {
            color: var(--custom-color-1);
        }
    }
    .segment-modal-bottom {
        min-height: 80px;
        &.segment-modal-head-bottom {
            .c-tagList-bc {
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }

    .modal-content {
        display: grid;
        grid-template-rows: auto 10fr auto;
        min-width: 798px;
        max-height: 80rem;
        height: 100%;
    }
    .segment-modal-head .key-value-h {
        display: grid;
        grid-template-columns: 4fr 8fr;
        margin-bottom: 1.5rem;
    }
    .title-holder {
        margin-bottom: 10px;
    }
    .segment-modal-head {
        &.segment-modal-head-bottom {
            overflow: auto;
        }
    }
    .used-in-section {
        .kv-label {
            display: none;
        }
        .key-value-h {
            grid-template-columns: 10fr;
            margin-bottom: unset;
        }
    }
    .kpis-wrapper {
        .tagList-bc {
            max-height: 50px;
            overflow: auto;
        }
    }
    .filters-wrapper {
        flex: 1;
        overflow: auto;
        & > .crm-filter-tag-wrapper {
            flex-wrap: wrap;
            row-gap: 15px;
            & > .crm-filter-tag-wrapper {
                padding: 3px;
                border-radius: 20px;
                border: 1px solid var(--line-color);
                flex-wrap: wrap;
                row-gap: 15px;
            }
        }
    }
}
.cj-report-info-modal {
    .modal-body {
        .segment-modal-head {
            height: 45%;
            overflow-y: auto;
        }
    }
}

.composite-report-info-modal {
    .composite-report-general-info {
        overflow-y: auto;
        height: 45%;
        .title-holder {
            display: none;
        }
        .key-value-info-content:last-child {
            margin-bottom: unset;
        }
        .crm-keyvalue-items-wrapper {
            height: 190px;
        }
        .composite-operation-icon {
            color: var(--hero);
        }
        .segment-modal-head {
            overflow-y: unset;
        }
    }
    .composite-report-tabs {
        overflow-y: auto;
        height: 55%;
        .tabs-holder {
            margin-top: 2rem;
        }
        .composite-report-info-tab {
            .crm-keyvalue-items-wrapper {
                max-width: 716px;
                display: flex;
                .key-value-items-vertical {
                    width: 25%;
                    padding-right: 8px;
                    .key-value-items-skeleton {
                        width: 100%;
                        .key-value-items-subtitle {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }
            }
            .column-kpis-filters-wrapper {
                height: 220px;
                overflow-y: scroll;
                .segment-modal-b-content.c-s-b-content {
                    max-height: unset;
                }
            }
        }
    }
}
