.settings-popup-container {
    max-width: 85.8rem;
    //min-width: 67.2rem;
    height: 40.3rem;

    .settings-el-layout {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 27%;
        justify-content: center;
        padding: 0 6rem;
        margin: 2rem 0;
    }

    .settings-popup-c {
        .dropdown-holder {
            .input-element-back {
                background: var(--input-element-background, var(--background));
                border: 1px solid var(--input-element-border-color, transparent);
            }
            .input-element {
                color: rgba(var(--background-sc-rgb), 0.75);
            }
        }
    }
}

.settings-popup-c {
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
    .dropdown-holder:not(:last-child) {
        margin-right: 3rem;
    }
}

.settings-popup-radio-button-title {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > p {
        margin-left: 1rem;
        font-size: 14px;
        font-weight: 600;
        color: var(--custom-color-1);
    }
}
.settings-popup-radio-button {
    .view-mode-item-1 {
        font-size: 1.2rem;
    }
    .view-mode-item-2 {
        font-size: 1.4rem;
    }
    .view-mode-item-3 {
        font-size: 1.8rem;
    }
}
.s-p-button-text {
    margin-left: 1rem;
    color: var(--charcoal-grey);
    font-size: 1.4rem;
    font-weight: 600;
}
.settings-popup-mode {
    img {
        width: 9.6rem;
        height: 6.5rem;
    }
}
.view-mode-item {
    width: 10rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    border: solid 1px rgba(60, 64, 67, 0.3);
}

.settings-el-layout {
    > .cha-ra-group {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 18%;
    }
    .crs-holder .label-holder {
        display: flex;
        flex-direction: column;
    }
    .crs-holder {
        .crs-item {
            order: unset !important;
        }
    }
}
.settings-modal-cont {
    .modal-content {
        width: 820px;
    }
    .modal-head {
        .ellipsis-text {
            color: var(--custom-color-1);
            font-weight: bold;
        }
    }
    .title-text {
        .ellipsis-text {
            color: var(--charcoal-grey);
        }
    }
}

@media (max-width: 770px) {
    .settings-popup-view {
        padding: 0;
    }
    .settings-popup-mode {
        padding: 0;
    }
    .settings-el-layout {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10%;
        margin-bottom: 3rem;
    }
}
