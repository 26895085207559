.cj-option-list-content-wrapper {
    .crm-option-list-content {
        width: 100%;

        .ReactVirtualized__Grid {
            width: 100% !important;
            .ReactVirtualized__Grid__innerScrollContainer {
                max-width: 100% !important ;
            }
        }
    }
}
