.crm-cascade-dropdown {
    position: relative;
    width: 100%;

    .crm-cascade-dropdown-input {
        // width: 100%;
        // height: 36px;
        // padding: 0 1.5rem;
        // font: 600 1.4rem var(--font-family);
        // color: rgba(var(--background-sc-rgb), 0.75);
        // border: 1px solid rgba(var(--background-sc-rgb), 0.3);
        // border-radius: 4.2rem;
        // resize: none;
        // transition: 150ms opacity;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        &.invalid {
            border-color: var(--danger);
        }

        .input-holder {
            display: inline-block;
            line-height: 36px;
            text-overflow: ellipsis;
        }

        button.btn.a-minimal {
            &:hover {
                background-color: unset;
            }
            color: rgba(var(--background-sc-rgb), 0.62);
            display: inline-block;
            min-width: unset;
            margin-left: -40px;
        }
    }

    &.active {
        .crm-cascade-dropdown-input {
            border-color: var(--hero);
        }
    }

    .crm-cascade-dropdown-error-message {
        position: absolute;
        bottom: -24px;
        left: 2rem;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font: 600 1.2rem/1.42 var(--font-family);
        color: var(--danger);
    }

    .crm-cascade-dropdown-content {
        position: absolute;
        top: 36px;
        max-height: 200px;
        overflow-y: auto;
        width: 100%;
        border-radius: 1rem;
        margin-top: 0.5rem;
        background-color: var(--background);
        box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(var(--background-sc-rgb), 0.08);
        z-index: 6;
    }
}
