.crm-uploader-block-wrapper {
    width: 100%;
    height: 36px;
    display: grid;
    grid-template-areas: 'uploadBlock uploadButton';
    grid-template-columns: 11fr auto;
    &.disabled {
        opacity: 0.5;
        .uploaderButton {
            cursor: default;
        }
    }

    p.information-message {
        margin-left: 14px;
    }
}

.crm-uploader-block-data {
    grid-area: uploadBlock;
    width: 100%;
    height: 3.6rem;
    border: solid 1px rgba(var(--background-sc-rgb), 0.1);
    border-right: none;
    border-radius: 21px 0 0 21px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;

    p {
        font-size: 14px;
        font-weight: 600;
        color: var(--custom-color-1);
        width: 15rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.crm-uploader-button {
    grid-area: uploadButton;
    background-color: #2bc784;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 0 21px 21px 0;
    display: flex;
    align-items: center;
    height: 36px;

    input[type='file'] {
        display: none;
    }

    span {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
    }
}

.crm-uploader-button-show-only-button {
    border-radius: 21px;
}
