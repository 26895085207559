.composite-segment-info-tabs-container {
    .tabs-content {
        width: 100%;
    }
    .tabs-content > .active {
        pointer-events: auto;
        cursor: default;
    }
    .tabs-content > li {
        width: 20%;
    }
}
