.crm-customer-journey-view-page {
    .overlay-button-content {
        display: flex;
        align-items: center;
    }

    .overlay-head {
        display: none;
    }

    .overlay-holder.t-vertical {
        height: 124px;
        border-radius: 0;
        box-shadow: unset;
        border-top: 1px solid rgba(var(--background-sc-rgb), 0.1);

        &.s-extended {
            height: 680px;
        }
    }

    .overlay-body {
        .crm-common-table {
            .module-header {
                display: none;
            }

            .paper-right-actions {
                display: none;
            }

            .scroll-holder {
                height: 475px !important;
            }
        }

        .overlay-content {
            padding: 0 0 1rem 0;

            .module-header {
                margin-bottom: 20px;

                .right-content .download-toolbar {
                    position: unset;
                }

                .right-content {
                    .block-report-export-warring-icon {
                        margin-right: -16px;
                        z-index: 21;
                        color: var(--status-icon-error-color);
                    }
                }
            }

            .module-title:before {
                top: unset;
            }
        }

        > .scroll-holder {
            > .scroll-content {
                overflow: hidden !important;
            }
        }
    }

    .crm-is-disabled-overlay {
        .extend-reduce {
            pointer-events: none;
            opacity: 0.6;
        }
    }

    .crm-customer-journey-view-page-filter-tags {
        margin: 0 2rem 2rem 2rem;
        background-color: var(--background);
        display: flex;
        align-items: center;
        overflow-x: auto;
    }
}

.cj-list-tags-popover {
    .popover-content {
        width: 30rem;
        height: auto;
        padding: 1.5rem;
        overflow-y: scroll;
        .popover-body {
            height: 100%;
            overflow: auto;
        }
    }
}

.cj-list-dropdown-wrapper {
    margin-right: 10px;
}

.cj-list-tags-wrapper {
    .static-title-holder {
        padding: 10px 0 10px 10px;
    }
}

.crm-customer-journey-cecp-page {
    .modal-content {
        // width: 100%;
        // max-width: 82rem;
        .module-header.p-top,
        .paper-actions {
            display: none;
        }
    }
    user-select: none;

    .crm-cj-target-group {
        --block-color: var(--feijoa);
    }

    .crm-cj-action-group {
        --block-color: var(--golden-tainoi);
    }

    .crm-cj-trigger-group {
        --block-color: var(--froly);
    }

    .crm-cj-condition-group {
        --block-color: var(--amethyst);
    }

    .crm-page-headline {
        .crm-cj-pre-save-modal-body-content {
            padding-top: 2rem;
            width: 100%;

            & > div:nth-child(2) {
                margin-top: 0 !important;
            }

            .label {
                font-size: 14px;
                font-weight: 600;
            }

            & > div {
                & > div:first-child {
                    display: flex;
                    align-items: center;
                }

                &:not(:first-child) {
                    margin-top: 24px;
                }

                & > div:last-child {
                    display: flex;
                    margin-top: 8px;

                    & > div:last-child {
                        width: 30%;
                        margin-left: auto;
                    }

                    & > div:first-child {
                        width: 64%;
                        margin-left: unset;
                    }
                }
            }
            .crm-cj-pre-save-modal-custom-fields {
                display: flex;
                flex-direction: column;
                row-gap: 3rem;
                max-height: 16rem;
                overflow-y: auto;

                & > div:first-child {
                    margin-top: 1rem;
                }
                & > div:last-child {
                    margin-top: unset;
                    & > div {
                        width: 100% !important;
                    }
                }
            }
        }

        .crm-view-page-headline-info-cnt {
            display: flex;

            .crm-view-page-headline-info-panel,
            .crm-view-page-headline-title-panel {
                margin-left: 80px;

                & > span {
                    display: block;
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 600;
                    color: var(--c-gray-chateau);
                    margin-bottom: 4px;
                }

                & > div {
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--custom-color-1);
                }
            }

            .crm-view-page-headline-title-panel {
                margin: unset;

                & > span {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--custom-color-1);
                }

                & > div {
                    font-size: 12px;
                    font-weight: 400;
                    color: var(--custom-color-1);
                }
            }
        }

        .page-h-right {
            button:not(:first-child) {
                margin-left: 20px;
            }
        }
    }

    .crm-page-wrapper {
        // TODO: refactor styles
        height: calc(100% - 70px);
        bottom: 0;
        top: unset;

        .crm-section {
            border: 0;
            padding: 0;

            .loader-holder {
                height: 100%;
                justify-content: center;
            }
        }
    }

    .crm-page-wrapper-with-client-list {
        height: calc(100% - 194px);
        top: 70px;
    }

    .crm-cj-flow-editor-background {
        background-color: var(--page-background);
        path {
            stroke: rgba(0, 0, 0, 0.06);
        }
    }

    .crm-holder-wrapper {
        .holder.opened {
            --opened-width: 260px;
        }
    }

    .crm-flow-sidebar {
        // TODO: should refactored
        .loader-holder {
            position: absolute;
            height: 100%;
            width: 100%;
            left: -1rem;
            top: -1rem;
            justify-content: center;
            align-items: center;
        }
    }

    .crm-flow-sidebar-block-group {
        width: 100%;
        margin-bottom: 0;
        color: var(--charcoal-grey);

        &.crm-flow-sidebar-block-group-read-only {
            pointer-events: none;
            opacity: 0.5;
        }

        .crm-flow-sidebar-block-group-head {
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            cursor: pointer;
            user-select: none;
            color: rgba(var(--background-sc-rgb), 0.75);

            .icon {
                font-size: 20px;
            }

            span {
                margin: 0 10px 0 8px;
                font-size: 14px;
                font-weight: 600;
            }

            &::after {
                content: '';
                display: block;
                height: 1px;
                width: 100%;
                background-color: var(--line-color);
            }
        }

        .crm-flow-sidebar-block-group-body {
            max-width: 220px;

            & .btn:last-child {
                margin: 20px auto 12px auto;
                border-color: var(--c-nobel);
                color: var(--c-nobel);

                &:hover {
                    background-color: var(--c-nobel);
                    color: var(--c-white);
                }

                .ellipsis-text {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .crm-flow-sidebar-block {
            display: flex;
            align-items: center;
            background-color: var(--background);
            overflow: hidden;
            width: 100%;
            height: 42px;
            margin-bottom: 20px;
            border-radius: 10px;
            border: 1px solid var(--line-color);
            color: rgba(var(--background-sc-rgb), 0.75);

            &:hover {
                cursor: grab;
            }

            &:active {
                cursor: grabbing;
            }

            div {
                display: flex;
                justify-content: center;
                min-width: 42px;
                background-color: var(--block-color);
                color: var(--background);
                height: 100%;
                .icon {
                    line-height: 42px;
                }
            }

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 124px;
                overflow: hidden;
                padding-right: 4px;
                margin-left: 20px;
                font-size: 14px;
                font-weight: 600;
                color: rgba(var(--background-sc-rgb), 0.75);
            }
        }
    }

    .crm-flow-editor-wrapper {
        position: relative;
        height: 100%;
        width: 100%;

        &.crm-flow-editor-connection-state {
            .crm-flow-editor-block .react-flow__handle:first-child:before {
                content: '';
                display: block;
                width: 220px;
                height: 60px;
                position: absolute;
                top: -26px;
                left: 3px;
                z-index: 1;
            }
        }

        .crm-flow-editor-block {
            position: relative;
            width: 220px;
            height: 60px;
            border-radius: 10px;
            background-color: var(--background);
            border: solid 1px var(--line-color);

            &:active {
                cursor: grabbing;
            }

            .disabled-section {
                filter: grayscale(0.8);
            }

            .crm-flow-editor-block-body {
                height: 100%;

                .crm-flow-editor-block-clients-count-container {
                    position: absolute;
                    right: -12px;
                    top: -12px;
                    min-width: 24px;
                    height: 24px;
                    display: flex;
                    column-gap: 4px;
                }

                .crm-flow-editor-block-clients-percentage {
                    background-color: var(--background);
                    border-radius: 24px;
                    text-align: center;
                    line-height: 22px;
                    color: var(--block-color);
                    font-size: 14px;
                    font-weight: 600;
                    border: 1px solid var(--block-color);
                    padding: 0 6px;
                }

                .crm-flow-editor-block-clients-count {
                    background-color: var(--block-color);
                    border-radius: 24px;
                    text-align: center;
                    line-height: 22px;
                    color: var(--background);
                    font-size: 14px;
                    font-weight: 600;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 0 6px;
                }

                .crm-flow-editor-block-content {
                    display: flex;
                    height: 100%;
                    border-radius: 9px;
                    overflow: hidden;

                    &.invalid {
                        border: 1px solid #cf000f;
                    }

                    & > div:first-child {
                        width: 42px;
                        min-width: 42px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: var(--background);
                        background-color: var(--block-color);
                    }

                    & > div:last-child {
                        margin-right: auto;
                        margin-left: 12px;
                        color: rgba(var(--background-sc-rgb), 0.75);

                        .crm-flow-editor-block-title {
                            & > input {
                                display: block;
                                width: 164px;
                                margin-top: 10px;
                                padding-right: 12px;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 1.36;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        span {
                            display: block;
                            width: 164px;
                            margin-top: 4px;
                            padding-right: 12px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 1.42;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .crm-flow-editor-block-actions {
                position: absolute;
                display: flex;
                bottom: -12px;
                right: -4px;

                button,
                & > i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-left: 5px;
                    background-color: var(--background);
                    border-radius: 50%;
                    border: 1px solid var(--line-color);
                    text-align: center;

                    i {
                        font-size: 18px;
                        color: var(--hero);
                    }

                    &:hover {
                        background-color: var(--hero);

                        i {
                            color: var(--c-white);
                        }
                    }
                }

                .crm-flow-editor-block-action-active {
                    pointer-events: none;
                    background-color: var(--hero);

                    i {
                        color: var(--c-white);
                    }
                }
            }
        }

        .react-flow__node.selected {
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 250px;
                left: -15px;
                height: 100px;
                top: -20px;
                border-radius: 10px;
                background-color: rgba(20, 115, 230, 0.1);
            }
        }

        .react-flow__edge {
            .react-flow__edge-path {
                stroke: var(--c-gray);
                cursor: pointer;
                stroke-width: 1.5px;
            }

            &.selected {
                .react-flow__edge-path {
                    stroke: var(--c-denim);
                }
            }
        }

        .react-flow__handle {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: solid 1px var(--hero);
            background-color: var(--c-white);
        }

        .crm-flow-editor-layout-controls {
            position: absolute;
            display: flex;
            background-color: var(--background);
            border-radius: 10px;
            border: solid 1px var(--line-color);
            right: 20px;
            top: 20px;
            z-index: 5;

            .btn {
                padding: 10px 16px;
                --size: 24px;
                border-radius: 0;
                background-color: unset;
                color: rgba(var(--background-sc-rgb), 0.7);

                &.active {
                    color: var(--c-denim);
                }
            }

            .btn + .new-section {
                border-left: 1px solid var(--line-color);
            }
        }

        .crm-flow-editor-controls {
            position: absolute;
            display: flex;
            bottom: 15px;
            left: 0;
            right: 0;
            margin: auto;
            width: 214px;
            box-shadow: unset;
            padding: 10px 20px;
            border-radius: 10px;
            border: solid 1px var(--line-color);
            background-color: var(--background);

            &.read-only {
                width: 134px;
            }

            .react-flow__controls-button {
                fill: rgba(var(--background-sc-rgb), 0.7);
                background-color: unset;
                border-bottom: 0;
                width: 24px;
                height: 24px;
                padding: 4px;
                box-sizing: border-box;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }

            .crm-flow-editor-controls-undo-redo-panel {
                display: flex;

                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    margin: 0 15px;
                    background-color: var(--line-color);
                }
            }
        }
    }

    .crm-flow-editor-read-only {
        .react-flow__node {
            &:before {
                cursor: pointer;
            }

            .crm-flow-editor-block {
                cursor: pointer;

                &:active {
                    cursor: pointer;
                }

                .react-flow__handle {
                    cursor: pointer;
                    z-index: 1;
                }
            }
        }
    }

    .crm-cj-edit-modal {
        .crm-option-list-content-wrapper {
            .crm-option-list-content {
                &__toggle-button {
                    color: rgba(var(--background-sc-rgb), 0.75);
                    background: var(--background);
                    border: 1px solid rgba(0, 0, 0, 0.3);

                    &:hover {
                        background: var(--background);
                        color: rgba(var(--background-sc-rgb), 0.75);
                    }
                }

                &__is-not-valid {
                    border: 1px solid var(--danger);
                }
            }
        }
    }
}
.download-send-to-email-toolbar {
    position: relative;
    z-index: 20;
    width: 100%;
}
.download-send-to-email-toolbar-inner {
    position: relative;
    .d-toolbar-menu-row {
        &:not(:first-child) {
            margin-left: 0;
        }
        i {
            margin-left: unset;
        }
        span {
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 0 1.4rem;
        }
    }
}
.crm-customer-journey-visualization-content,
.crm-customer-journey-cecp-page,
.crm-cj-report-page-wrapper,
.crm-customer-journey-view-page {
    .crm-cj-edit-modal,
    .crm-cj-view-page-filter-modal {
        .modal-content {
            min-width: 700px;
            width: calc(100vw - 100px);
            min-height: 580px;
            height: calc(100vh - 100px);
        }
        .modal-body {
            width: 100%;
            position: relative;
            padding: 2rem;
            height: 100%;
            overflow-y: auto;

            & > .input-holder:first-child {
                width: 49%;
            }
        }
    }

    .cj-report-condition-wrapper,
    .crm-cj-visualization-step,
    .crm-cj-edit-modal,
    .crm-cj-view-page-filter-modal,
    .crm-cj-visualization-filters {
        .crm-cj-modal-option {
            .cj-bonus-container {
                display: flex;
            }
            .wallet-dropdown {
                margin-left: 20px;
            }
            margin-top: 20px;
            .crm-option-list-content {
                left: 0;
                right: unset;
            }

            .crm-option-list-header-item {
                width: 100%;
            }

            .crm-option-dropdown {
                max-width: unset;
                width: 49%;
            }

            .cb-use-secondary-currency {
                padding-top: 14px;
            }

            &.right-side-option {
                position: absolute;
                width: 47.3%;
                right: 20px;
                top: 20px;
                margin-top: 0;
            }

            .crm-cj-edit-modal-cmp-head {
                display: flex;
                align-items: center;
                margin-bottom: 18px;
                user-select: none;
                color: rgba(var(--background-sc-rgb), 0.75);

                span {
                    margin-right: 10px;
                    font-size: 14px;
                    font-weight: 600;
                    white-space: nowrap;
                }

                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 100%;
                    background-color: var(--line-color);
                }
            }
            .scheduler-modal-wrap {
                .switcher-label-holder {
                    display: flex;
                }
                .scheduler-info-message {
                    margin-left: 10px;
                    color: var(--custom-color-1);
                    font-weight: 600;
                }
            }
        }

        .crm-cj-reference-cmp {
            & > button {
                margin-bottom: 10px;
                i {
                    font-size: 16px;
                }
            }
        }

        .crm-cj-filter-condition-cmp {
            // max-height: 338px;
            // overflow-y: auto;
            .crm-option-list-content {
                right: 0;
                left: unset;
            }

            .crm-option-dropdown {
                width: 100%;
                max-width: 26rem;
            }

            .cha-ra-group {
                text-transform: uppercase;
            }

            .crm-cj-filter-condition-header {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-bottom: 15px;

                .cha-ra-group {
                    flex: 1;
                }

                > .btn {
                    //margin: 12px 0 12px auto;
                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }
            }

            .crm-cj-filter-condition-filters-cnt {
                .crm-cj-filter-condition-block:not(:last-child) {
                    margin-bottom: 20px;
                }
            }

            .crm-cj-filter-condition-block {
                border: 1px solid rgba(var(--background-sc-rgb), 0.1);
                border-radius: 20px;
                padding: 15px 20px 30px 20px;

                &-heading:not(:empty) {
                    margin-bottom: 15px;
                }

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                & > div:first-child {
                    display: flex;
                    align-items: center;

                    > span {
                        flex: 1;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                .crm-cj-filter-condition-inputs {
                    display: flex;

                    & > .crm-cascade-dropdown,
                    & > .dropdown-holder {
                        flex: 1;
                        min-width: 100px;
                        margin: auto;
                        margin-left: unset;
                    }

                    .crm-cj-filter-condition-operator {
                        margin-right: 20px;
                    }

                    .crm-cj-filter-condition-inputs-left-content,
                    .crm-cj-filter-condition-inputs-right-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .crm-cj-filter-condition-inputs-left-content {
                        flex: 1;
                        max-width: fit-content;
                        .crm-cascade-dropdown-function {
                            margin: 0 20px;
                        }
                    }

                    .crm-cj-filter-condition-inputs-right-content {
                        flex: 1;
                        max-width: fit-content;
                        > button:last-child {
                            margin-left: 8px;
                            i {
                                font-size: 16px;
                            }
                        }

                        .information-message {
                            position: absolute;
                        }

                        > div:not(:first-child) {
                            margin-left: 20px;
                        }

                        .crm-cascade-dropdown:not(:first-child) {
                            margin-left: 20px;
                        }
                        .c-date-picker {
                            min-width: 22rem;
                        }
                        .dropdown-holder {
                            max-width: fit-content;
                        }
                    }
                }
            }
        }

        .crm-cj-edit-modal-bonus-cmp-fields {
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
        }

        .crm-cj-money-cmp {
            display: flex;

            > div {
                width: 100%;
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .crm-communication-availability {
            .crm-communication-availability-dropdown-container {
                display: flex;
                flex-direction: row;
                & > div:first-child {
                    padding-right: 1.5rem;
                }
            }
            .crm-communication-availability-schedule-container {
                display: flex;
                flex-direction: column;
                padding-left: 18px;
                .information-message {
                    position: absolute;
                }

                & > .crm-communication-availability-schedule-item:not(:first-child) {
                    padding-top: 50px;
                }

                & > .crm-communication-availability-schedule-item:first-child {
                    padding-top: 24px;
                }

                .crm-communication-availability-schedule-item {
                    display: flex;
                    flex-direction: row;

                    .crm-communication-availability-schedule-item-label-container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .label {
                            font-size: 14px;
                            font-weight: 600;
                            width: 100px;
                        }
                    }
                    .crm-communication-availability-schedule-item-time-container {
                        display: flex;
                        flex-direction: row;
                        padding-left: 50px;
                        width: 800px;
                        .bc-icon-minus {
                            padding-top: 5px;
                        }
                        .time-picker-holder {
                            width: 300px;
                        }
                    }
                }
            }
        }

        .cj-calculate-kpi-block {
            min-height: 300px;

            .radio-group {
                label {
                    display: flex;
                    align-items: center;
                    i {
                        padding-left: 6px;
                    }
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        color: var(--custom-color-1);
                    }
                }
                .crs-holder {
                    padding-left: unset;
                }
            }
            .kind-holder {
                display: flex;
                flex-direction: column;
                .input-holder {
                    max-width: 240px;
                    margin-right: 20px;
                }
            }
            .kind-holder + .kind-holder {
                margin-top: 20px;
            }

            .kind-holder-title {
                display: flex;
                align-items: center;
                padding-bottom: 8px;
            }
        }
    }

    .crm-page-headline {
        .page-h-left {
            justify-content: center;
        }
        .page-h-right {
            .cj-report-export-warring-icon-container {
                width: 24px;
                margin-right: -16px;
                z-index: 21;
                .bc-icon-danger-48 {
                    color: var(--status-icon-error-color);
                }
            }
        }
    }
}

.cj-activity-timeline-modal {
    z-index: 399;
    .modal-content {
        width: 820px;
        .modal-body {
            height: 400px;
            padding: 0;
            .scroll-holder {
                height: 40rem !important ;
            }
            .paper {
                border-radius: 0;
                box-shadow: none;
            }
        }
    }
}
.crm-filter-tag-button-wrapper {
    padding-right: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    margin-right: 15px;

    & + .crm-filter-tag-wrapper {
        & > .crm-filter-tag-wrapper {
            padding: 3px;
            border-radius: 40px;
            border: 1px solid var(--line-color);
            //background-color: rgba(#E7F1FC, 0.8);
        }
    }
}
.crm-filter-tag-wrapper {
    display: flex;
    align-items: center;
    & + .static-title-holder {
        padding: 0 15px;
    }
    .crm-cj-filter-tag {
        height: 30px;
        max-width: 200px;
        padding: 3px 10px 3px 15px;
        border-radius: 18px;
        background-color: rgba(20, 115, 230, 0.1);
        display: flex;
        align-items: center;
        //margin-right: 10px;
        p {
            font-size: 14px;
            font-weight: 600;
            color: var(--hero);
            white-space: nowrap;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        i {
            color: rgba(20, 115, 230, 0.5);
            cursor: pointer;
        }
    }
}

.crm-cj-block-info-sidebar {
    .holder-body {
        height: calc(100% - 20px);
    }
    .module-title {
        padding: 9px 0;
        color: rgba(var(--background-sc-rgb), 0.75);
        &::before {
            display: none;
        }
    }
}

.crm-customer-journey-view-page {
    .holder-body-c {
        & > * {
            margin: unset;
        }
    }

    .crm-cj-block-info-sidebar {
        .holder-body {
            height: 100%;
        }
        .module-title {
            padding: 9px 0;
            color: rgba(var(--background-sc-rgb), 0.75);
            &::before {
                display: none;
            }
        }
        &_title-block-type {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 4px;
        }
        &_title-block-name {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
        }
        .holder-body-c {
            display: flex;
            flex-direction: column;
            padding: 2rem;
            flex-wrap: unset;
        }

        &_option-name-wrapper {
            display: flex;

            .tag-cc {
                text-transform: uppercase;
            }
        }

        &_option-name {
            flex: 1;
            padding-right: 1rem;
            font-size: 14px;
            font-weight: 600;
            color: rgba(var(--background-sc-rgb), 0.75);
            white-space: nowrap;
            margin-bottom: 20px;
        }
        i {
            margin-right: 10px;
        }
    }

    .cj-info-block-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .cj-filter-condition-info-block-property-wrapper {
            &:not(:last-child) {
                margin-bottom: 40px;
            }

            & > div:last-child {
                margin-left: 10px;
            }

            .crm-filter-condition-info-block-value-item-panel {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                & > span {
                    display: block;
                    font-weight: 600;
                    margin-bottom: 16px;
                }

                & > div {
                    .cj-info-label-value-wrapper {
                        padding-left: 0;
                    }

                    .cj-filter-condition-info-block-right-value-wrapper {
                        margin-left: 0;
                    }
                }
            }

            &_headline {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                &_title {
                    color: var(--hero);
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .cj-info-label-value-wrapper {
                padding-left: 20px;
            }

            .cj-filter-condition-info-block-right-value-wrapper {
                margin-left: 10px;
                &_headline {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    &_title {
                        margin-right: 10px;
                    }
                    &::after {
                        content: '';
                        display: block;
                        height: 1px;
                        width: 100%;
                        background-color: var(--line-color);
                    }
                }
            }
        }
        .cj-info-label-value-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &:not(:last-child) {
                padding-bottom: 1.5rem;
            }

            &_label {
                width: 48%;
                font-size: 14px;
                font-weight: 600;
                color: rgba(var(--background-sc-rgb), 0.75);
                text-overflow: ellipsis;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
            }
            &_value {
                width: 48%;
                font-size: 14px;
                font-weight: 600;
                color: rgba(var(--background-sc-rgb), 0.75);
            }
        }
    }

    .crm-cj-block-info-sidebar-panel {
        width: 100%;

        &:not(.crm-cj-block-info-sidebar-last-panel):after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--line-color);
            margin: 20px 0;
        }
    }

    .crm-option-list-content-wrapper {
        .crm-option-list-content {
            &__toggle-button {
                color: rgba(var(--background-sc-rgb), 0.75);
                background: var(--background);
                border: 1px solid rgba(0, 0, 0, 0.3);
                margin: 2px;

                &:hover {
                    background: var(--background);
                    color: rgba(var(--background-sc-rgb), 0.75);
                }
            }

            &__is-not-valid {
                border: 1px solid var(--danger);
            }

            z-index: 300;
        }

        .ellipsis-text {
            display: flex;
            align-items: center;
            width: 100%;

            .btn {
                margin-left: auto;
            }
        }
    }
}

.crm-flow-editor-block-tooltip {
    white-space: break-spaces;
}

.cj-info-block-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cj-filter-group-condition-info-block-property-wrapper {
        margin: 2rem 0;

        .crm-cj-block-info-sidebar_option-name {
            color: var(--hero);
        }

        & > div:last-child {
            padding: 2rem;
            border: 0.1rem solid var(--line-color);
            border-radius: 2rem;

            .cj-filter-condition-info-block-property-wrapper_headline_title {
                color: unset;
            }
        }
    }

    .cj-filter-condition-info-block-property-wrapper {
        &_headline {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &_title {
                margin-right: 10px;
            }
            &::after {
                content: '';
                display: block;
                height: 1px;
                width: 100%;
                background-color: var(--line-color);
                flex: 1;
            }
        }
        .cj-info-label-value-wrapper {
            padding-left: 20px;
        }
        .cj-filter-condition-info-block-right-value-wrapper {
            margin-left: 10px;
            &_headline {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                &_title {
                    margin-right: 10px;
                }
                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 100%;
                    background-color: var(--line-color);
                }
            }
        }
    }

    .cj-info-label-value-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.5rem;
        width: 100%;
        &_label {
            width: 48%;
            font-size: 14px;
            font-weight: 600;
            color: rgba(var(--background-sc-rgb), 0.75);
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
        }
        &_value {
            width: 48%;
            font-size: 14px;
            font-weight: 600;
            color: rgba(var(--background-sc-rgb), 0.75);
        }
        .cj-info-value {
            color: var(--tittle-link-text-color);
            cursor: pointer;
            p {
                font-size: 1.4rem;
                font-weight: 600;
            }
        }
    }
}

.crm-cj-menu-item-dropdown {
    .skeleton-holder {
        min-width: 18rem;
    }
}
.crm-template-dynamic-variables-container {
    display: flex;
    flex-direction: column;
    padding-left: 18px;
}

.crm-template-dynamic-variable {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;

    .label {
        font-size: 14px;
        font-weight: 600;
        width: 150px;
    }
    .crs-holder {
        margin-left: 10px;
    }
    .crm-cascade-dropdown {
        margin-left: 30px;
        .information-message {
            position: absolute;
        }
    }
}
.crm-template-item-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cj-info-block-dynamic-variable-container {
    .cj-dynamic-variable-field-name {
        margin: 25px 0px 25px 0px;
        font-weight: 600;
    }
}

.crm-cj-template-filter-condition-inputs-right-content {
    display: flex;
    .crm-cascade-dropdown-function {
        margin-left: 20px;
    }
}

.cms-cj-description-info-wrapper {
    display: flex;
    align-items: center;
}

.cms-cj-description-info-wrapper__tooltip {
    ol,
    ul,
    dl {
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 25px;
    }

    ol {
        display: block;
        list-style-type: decimal;
    }

    ul {
        display: block;
        list-style-type: disc;
    }

    li {
        display: list-item;
    }

    ol li {
        list-style-type: decimal;
    }

    ul li {
        list-style-type: disc;
    }

    dt,
    dd {
        display: block;
    }

    dd {
        margin-inline-start: 40px;
    }
}
