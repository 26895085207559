.crm-custom-tabs-tab-with-switcher {
    position: relative;
    .crm-custom-tab-switcher {
        position: absolute !important;
        top: 20px;
        right: 20px;
    }
}

.crm-campaign-report-block {
    .crm-custom-tabs-tab {
        width: 100%;
    }
    > .crm-custom-tabs-btn-panel {
        display: flex;
        li {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font: 600 1.4rem/1.8rem var(--font-family);
            cursor: pointer;
            box-shadow: inset 0 0 0 0 var(--hero);
            transition: all 300ms;
            > button {
                padding: 1.1rem 2.6rem;
            }
            &:not(:last-of-type) {
                margin-right: 0.2rem;
            }
            border: 1px solid transparent;
            border-radius: 10px 10px 0 0;
            &:hover {
                border: solid 1px transparent;
            }
            &.active {
                border: solid 1px var(--line-color);
                border-bottom: 1px solid var(--background);
                border-radius: 10px 10px 0 0;
                > p {
                    color: var(--hero);
                }
            }
        }
    }
}
.activity-kpi {
    display: flex;
    .section-holder {
        padding: 0;
        margin: 0 20px 0 0;
        border: none;
        &:last-child {
            margin: 0;
            max-width: 480px;
        }
        .crm-segment-custom-line {
            background: none;
        }
    }
    .section-head {
        display: none;
    }
    .client-count-block .highcharts-title {
        div,
        span {
            color: #3c4043;
            font-size: 28px;
            line-height: 38px;
        }
    }
}
.crm-custom-tabs-cnt {
    > .crm-custom-tabs-btn-panel {
        display: flex;
        li {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font: 600 1.4rem/1.8rem var(--font-family);
            cursor: pointer;
            transition: all 300ms;
            > button {
                padding: 1.1rem 2.6rem;
            }
            &:not(:last-of-type) {
                margin-right: 0.2rem;
            }
            &.active {
                box-shadow: inset 0 -4px 0 0 var(--hero);
                > p {
                    color: #3c4043;
                }
                &.invalid {
                    box-shadow: inset 0 -4px 0 0 var(--danger);
                }
            }
            &.invalid {
                border-top-left-radius: 10px !important;
                border-top-right-radius: 10px !important;
                background-color: rgba(223, 47, 77, 0.06);
                p {
                    width: 100%;
                }
            }
        }
    }
    .tab-switcher {
        display: none;
    }
}
.crm-custom-tabs-content {
    position: static;

    .crm-tab-content:last-child {
        .paper-actions {
            .dropdown-holder + .popover-track {
                max-width: 250px;
            }
        }
    }

    & > .crm-tab-content {
        display: none;
        &.active {
            display: block;
        }
    }
}

.crm-custom-tabs-cnt {
    > .crm-custom-tabs-btn-panel {
        padding: 0 2rem;
        margin-bottom: 2.5rem;
    }
}

// Custom checkbox styles
.crm-custom-tabs-tab-with-switcher {
    .crm-custom-tab-switcher {
        &:hover {
            .react-switch-handle {
                width: 1.8rem !important;
                left: 0 !important;
                border-radius: 2.5rem !important;
                box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.16);
            }
        }
        .react-switch-handle {
            width: 1.4rem !important;
            height: 1.4rem !important;
            background: var(--background);
            cursor: pointer;
            border-radius: 50%;
            position: absolute;
            transform: translateX(2px) !important;
            top: 2px !important;
            left: 0;
            transition: all 200ms ease-in-out;
        }
        .react-switch-bg {
            background: rgb(156, 158, 160) !important;
            width: 36px !important;
            height: 18px !important;
            position: relative;
            border-radius: 9px;
            cursor: pointer;
            transition: background 0.25s ease 0s;
        }
    }
}
.crm-custom-tabs-tab-with-switcher.active-switcher {
    .crm-custom-tab-switcher {
        &:hover {
            .react-switch-handle {
                width: 18px !important;
                left: -4px !important;
                border-radius: 25px !important;
                box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.16);
            }
        }
        .react-switch-handle {
            transform: translateX(20px) !important;
        }
        .react-switch-bg {
            background: rgb(20, 115, 230) !important;
        }
    }
}
