.crm-template-list-page-wrapper {
    .bc-icon-message-closed {
        font-size: 2.1rem;
        padding-bottom: 2px;
    }

    .quick-wiew-icon {
        font-size: 21px;
    }

    .tag-c {
        --color: rgba(0, 0, 0, 0.1);
        color: var(--hero);
    }
}

.crm-template-info-modal {
    .modal-content {
        width: 1160px;
        .wrap-tabs-holder-info {
            height: 355px;
        }
    }

    .tabs-content-holder {
        padding: 2rem 0 !important;
    }

    .segment-modal-head {
        & > ul {
            & > li {
                display: unset;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .loader-holder {
        margin: 10%;
    }

    .tabs-holder.horizontal {
        .tabs-content.type-basic li {
            &.active {
                color: var(--hero);
            }
            width: unset;
            & > div {
                & > span {
                    font-weight: inherit;
                }
            }
        }
    }
}

/* template test */
.test-template-modal {
    .modal-content {
        min-width: 400px;
        .modal-head {
            border: none;
            min-height: 0;
            .ellipsis-text {
                color: var(--custom-color-1);
                font-weight: bold;
                line-height: 19px;
                padding-top: 25px;
                padding-bottom: 37px;
            }
        }
        .modal-body {
            padding: 0 40px;
            .text-icon-block {
                display: flex;
                align-items: center;
                flex-direction: row;
                .test-title-txt {
                    padding-left: 28px;
                    font-weight: 600;
                }
                > i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 64px;
                    height: 64px;
                    font-size: 42px;
                    background-color: rgba(62, 195, 239, 0.06);
                    color: #3ec3ef;
                    border-radius: 50%;
                }
            }
            .test-txt {
                text-align: center;
                display: block;
                padding-bottom: 20px;
                font-weight: 600 !important;
            }
            .regular {
                color: var(--custom-color-1);
                font-weight: bold;
                line-height: 19px;
                font-size: 14px;
            }
            .input-holder {
                padding-bottom: 20px;
            }
            .input-element {
                font-weight: 600;
                color: var(--custom-color-1);
            }
            .input-swap-animate {
                font-weight: 600;
                color: var(--custom-color-1);
            }
        }
    }
}

.smb-wrap {
    .tabs-content.type-basic {
        padding: 0;
        li {
            width: 110px;
            box-shadow: none;
            > p {
                text-transform: capitalize;
                color: var(--custom-color-1);
                font-weight: 600;
            }
        }
        > li.active {
            pointer-events: unset;
            position: relative;
            box-shadow: inset 0 0 0 1px rgba(var(--background-sc-rgb), 0.1);
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            &:before {
                position: absolute;
                content: ' ';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--background);
            }
            > p {
                color: var(--hero);
            }
        }
    }
}

.mobile-view {
    width: 300px;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    > p {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 10px;
        color: rgba(#ffffff, 0.75);
        overflow: scroll;
        word-break: break-all;
        border-top: 38px solid transparent;
        border-bottom: 34px solid transparent;
        border-left: 14px solid transparent;
        border-right: 8px solid transparent;
    }
}

.tablet-view {
    width: 600px;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    > p {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 10px;
        color: rgba(#ffffff, 0.75);
        overflow: scroll;
        word-break: break-all;
        border-top: 28px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 24px solid transparent;
        border-right: 18px solid transparent;
    }
}

.desktop-view {
    width: 400px;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    > p {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 10px;
        color: rgba(#ffffff, 0.75);
        overflow: scroll;
        word-break: break-all;
        border-top: 18px solid transparent;
        border-bottom: 100px solid transparent;
        border-left: 20px solid transparent;
        border-right: 14px solid transparent;
    }
}

.for-device {
    position: absolute;
    width: 100%;
    height: calc(100% - 75px);
    left: 0;
    top: 60px;
    border-top: 1px solid var(--line-color);
    border-bottom: 1px solid var(--line-color);
    display: flex;
    justify-content: center;
    padding: 6px 10px;
    overflow-y: scroll;
}

.inner-wrap-tabs-holder-info {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 21px;
    .input-structure {
        max-width: 320px;
    }
    .input-element {
        font-weight: 600;
        color: #3c4043 !important;
    }
    .input-swap-animate {
        font-weight: 600;
        color: #3c4043 !important;
        font-size: 10px !important;
    }

    .info-body-subject {
        grid-template-columns: 1fr 10fr;
    }
    iframe {
        width: 100%;
    }
}
.wrap-tabs-holder-info-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .display-mode-tabs-wrapper {
        margin-left: auto;
    }
}
.for-device-dark-mode {
    text-align: center;
    padding: 10px 80px;
    background: rgba(255, 255, 255, 0.04);
    > div {
        background: #171c26;
    }
}
.for-device-light-mode {
    text-align: center;
    padding: 10px 80px;
    background: rgba(0, 0, 0, 0.04);
    > div {
        background: rgba(255, 255, 255, 1);
    }
}
.controlled-icon-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.wrap-tabs-holder-info {
    width: 1150px;
    max-width: 100%;
    height: 100%;
    padding: 0 20px;
    > .tabs-holder {
        > .tabs-content-holder {
            border-left: 1px solid rgba(var(--background-sc-rgb), 0.1);
            border-right: 1px solid rgba(var(--background-sc-rgb), 0.1);
        }
    }
    .tabs-content-holder {
        padding: 1.9rem 0 !important;
    }
}

.wrap-for-device {
    i {
        color: var(--hero);
    }
}

.full-text {
    word-break: break-all;
    text-transform: capitalize;
}

.table-quick-view {
    left: -80px !important;

    .popover-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px !important;
        height: 200px;
        padding: 10px;
        overflow: unset;

        .left-arrow {
            position: fixed;
            content: '';
            width: 0;
            height: 0;
            border-top: 18px solid transparent;
            border-right: 22px solid var(--background);
            border-bottom: 20px solid transparent;
            top: 4px;
            left: -20px;
            bottom: 0;
            margin: auto;
            &:before {
                position: absolute;
                content: '';
                top: 9px;
                left: -4px;
                width: 30px;
                height: 2px;
                box-shadow: 0 2px 2px var(--line-color);
                transform: rotate(42deg);
            }
        }
    }
}

.lang-popover {
    .popover-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px !important;
        min-height: 50px;
        padding-top: 10px;
        padding-bottom: 10px;

        .popover-body {
            width: 100%;
            padding: 0 10px;
            > .scroll-holder {
                max-height: 184px !important;
                > .scroll-content {
                    max-height: 184px !important;
                }
            }
            .option-container-wrap {
                width: 100%;
                padding-right: 6px;
                padding-bottom: 6px;
            }
            .option-container {
                border-radius: 6px;

                .crm-lng-img {
                    width: 24px;
                }
            }
        }
        .left-arrow {
            position: fixed;
            content: '';
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-right: 16px solid var(--background);
            border-bottom: 14px solid transparent;
            top: 4px;
            left: -14px;
            bottom: 0;
            margin: auto;
            &:before {
                position: absolute;
                content: '';
                top: 7px;
                left: -2px;
                width: 21px;
                height: 1px;
                box-shadow: 0 1px 1px var(--line-color);
                transform: rotate(42deg);
            }
        }
        .option-left-elements {
            border-radius: 50%;
            overflow: hidden;
        }
        .option-right-elements {
            margin: 0;
        }
    }
}
