.crm-modal {
    .crm-circle-icon-cnt {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        width: 64px;
        border-radius: 50%;
        background-color: rgba(125, 198, 129, 0.06);
    }

    .crm-allowd-not-allowd-cnt {
        max-height: 250px;
        overflow-y: auto;

        max-width: 420px;
        font-weight: 600;
        font-size: 14px;

        ul {
            list-style: disc;
            margin: 12px 24px;
            line-height: 18px;

            li {
                display: list-item;
            }
        }
    }

    .btn {
        &.crm-modal-action-btn {
            padding-left: 4rem;

            & > i {
                font-size: 3rem;
                color: var(--background);
                position: absolute;
                left: 1rem;
                line-height: 3.6rem;
            }
        }

        &.crm-modal-reload-action-btn {
            margin-left: 1rem;

            &.crm-modal-reload-action-loading {
                span {
                    margin-left: 2.5rem;
                }
            }
        }
    }
}
.crm-delete-modal,
.crm-archive-modal,
.crm-unarchive-modal,
.crm-stop-modal,
.crm-warning-modal {
    .modal-content {
        max-width: 700px;
    }

    .ellipsis-text {
        font-weight: 600;
    }

    .modal-body {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 100%;
        align-items: center;
        grid-gap: 40px;
        padding: 20px 40px;

        span {
            max-width: 320px;
            font-weight: 600;
            font-size: 14px;
            word-break: break-word;
        }
    }
}

.crm-stop-modal {
    .icon {
        font-size: 40px;
        color: green; // TODO replace to var
    }
}

.crm-delete-modal {
    .icon {
        font-size: 40px;
        color: var(--delete-modal-color);
    }

    .modal-footer {
        button:last-child {
            background-color: var(--delete-modal-color);
        }
    }
}

.crm-archive-modal,
.crm-unarchive-modal,
.crm-warning-modal {
    .icon {
        font-size: 42px;
        color: var(--archive-modal-color);
    }
}

.crm-single-input-modal {
    .modal-body {
        .crm-note-text {
            font-weight: 600;
            text-align: center;
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
}

.confirmation-modal-warning-icon-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
    grid-gap: 40px;
    padding: 20px;
    .crm-circle-icon-cnt {
        .icon {
            font-size: 42px;
            color: var(--archive-modal-color);
        }
    }
}
