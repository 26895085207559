.custom-report-parameters-section-container {
    display: flex;
}

.custom-report-inner-container {
    min-width: 500px;
    max-width: 500px;
}

.parameters-editor {
    min-width: 1000px;
    margin-left: 50px;
}
