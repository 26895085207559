@import './mixins';

@mixin text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.create-campaign-page-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: grid;
    grid-template-rows: auto 11fr;
    overflow-y: auto;
    overflow-x: hidden;
    .create-campaign-page-content {
        &.idle-campaign {
            display: flex;
            flex-direction: column;
        }
        .campaign-page-generel-loader {
            top: 50%;
            justify-content: center;
            align-items: center;
        }
    }

    @include crm-list-column-title-icon-components;

    .table-holder:not(.content-empty) {
        max-height: none;
    }

    .crm-custom-tabs-content-wrapper {
        .ta-header {
            .ta-cell.a-square:empty {
                min-height: 3.6rem !important;
            }
        }
    }
}

.campaign-info-button-group {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;
    .crm-custom-tabs-btn-panel {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2%;
        line-height: 1.36;
        position: relative;
        padding-bottom: 30px;
        &.disabled {
            opacity: 0.6;
            pointer-events: none;
        }
        li {
            border-radius: 2rem;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
            background-color: var(--background);
            height: 11.8rem;
            position: relative;
            transition: all 200ms;
            &.active {
                margin-top: -1rem;
                margin-bottom: 1rem;
                box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.05);
            }
            &.inactive {
                opacity: 0.5;
                pointer-events: none;
                user-select: none;
                cursor: default;
            }
            button {
                height: 100%;
            }
            .crs-holder {
                position: absolute;
                right: 2rem;
                top: 2rem;
            }
            &:nth-child(2).active ~ .tab-switcher {
                left: 50%;
            }
            &:nth-child(3).active ~ .tab-switcher {
                left: 85%;
            }
            //&:nth-child(4).active ~ .tab-switcher {
            //  left: 85%;
            //}
        }
    }

    .crm-common-table {
        --table-max-height-additional-calc: 416px !important;
    }
}

.tab-switcher {
    position: absolute;
    bottom: -8px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 19px solid var(--background);
    border-bottom: 17px solid transparent;
    left: 15%;
    margin: auto;
    transform: rotate(90deg);
    transition: all 300ms;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        top: 7px;
        left: -2px;
        width: 23px;
        height: 1px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        transform: rotate(42deg);
    }
    &:before {
        content: '';
        position: absolute;
        top: -8px;
        right: -18px;
        width: 23px;
        height: 1px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        transform: rotate(-42deg);
    }
}
.campaign-tabs-inner {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    overflow: hidden;
    > button {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: auto 11fr;
        grid-column-gap: 2rem;
        align-items: center;
        text-align: left;
        padding-right: 2rem;
    }
    > div {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: auto 11fr;
        grid-column-gap: 2rem;
        align-items: center;
        text-align: left;
        padding-right: 2rem;
    }
}
.campaign-tab-heading {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.campaign-tab-desc {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--custom-color-1);
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.campaign-tab-icon {
    height: 100%;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--background);
    &.segment-icon {
        background-color: #7dc681;
    }
    &.template-icon {
        background-color: #b763c6;
    }
    &.bonus-icon {
        background-color: #7481c9;
    }
    i {
        font-size: 2.8rem;
    }
}
.campaign-tab-heading-group {
    margin-bottom: 1rem;
    padding-right: 4rem;
    text-transform: capitalize;
    > .campaign-tab-title {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--custom-color-1);
        @include text-ellipsis;
    }
    > .campaign-tab-subtitle {
        font-size: 1.8rem;
        font-weight: 600;
        color: var(--custom-color-1);
        @include text-ellipsis;
    }
}
.campaign-tab-content {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-gap: 2rem;
    span {
        font-weight: 600;
        color: var(--custom-color-1);
    }
    p {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--custom-color-1);
    }
    > .campaign-tab-content-part {
        display: flex;
        flex-direction: column;
        gap: 5px;
        overflow: hidden;
        &:first-child {
            border-right: 1px solid rgba(var(--background-sc-rgb), 0.1);
            padding-right: 1rem;
        }
    }
}
.campaign-tab-content-type {
    display: flex;
    align-items: center;
    span {
        margin-right: 0.65rem;
    }
    i {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.75rem;
        width: 1.9rem;
        height: 1.9rem;
        font-size: 2rem;
    }
    p {
        @include text-ellipsis;
    }
}
.campaign-tab-user-info,
.campaign-tab-date {
    display: flex;
    align-items: center;
    > span {
        @include text-ellipsis;
        margin-right: 0.4rem;
    }
    p {
        text-transform: capitalize;
        @include text-ellipsis;
    }
}
// Table wrapper shadow
.campaign-tab-table-wrapper {
    //border-radius: 2rem;
    //box-shadow: 0 0 5px 0 rgba(#e6e6e6, .8);
    position: relative;
}
.campaign-tab-table-wrapper-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.campaign-tab-content-wrapper {
    overflow: hidden;
}

.cb-use-secondary-currency {
    padding-bottom: 10px;
}

.crm-campaign-details {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px;

    .section-holder.section-holder.section-holder {
        margin-right: 20px;
        margin-top: 0;
    }
    .section-holder {
        position: relative;
        padding: 0;

        .module-header {
            background-color: transparent;
        }

        &:last-child {
            margin-right: 0 !important;
            .section-body {
                padding: 0;
            }
        }

        .section-head {
            border-bottom: 1px solid var(--line-color);
            padding: 0 20px;
            height: 5.1rem;
        }
        .section-body {
            display: block;
            padding: 20px;

            .crm-create-campaign-custom-fields-body-container {
                height: 25rem;
                padding-top: 4px;
                overflow-y: auto;
            }
            .module-header {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;

                .crm-statics-segment-data-table-name {
                    font-weight: 600;
                }
                border-radius: 2rem 2rem 0 0;
            }
            .chanel-row,
            .goal-row {
                display: flex;
                > div {
                    flex: 1;
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .chanel-row {
                margin-bottom: 20px;
            }
            .collect-row {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .popover-track {
                    margin-right: 14px;
                }
                .c-date-picker {
                    width: 70%;
                    max-width: 250px;
                    min-width: 180px;
                }
            }
            .switcher-label-holder {
                display: flex;
                align-items: center;
                margin-top: 20px;
                margin-left: 20px;
                .scheduler-info-message {
                    font-weight: 600;
                }
            }
            .non-market {
                margin-bottom: 20px;
                padding-left: 10px;
            }
            .owner-row {
                margin-bottom: 20px;
            }
        }
    }
    .table-holder {
        .ta-header {
            .ta-row {
                .ta-cell.a-square:first-child {
                    min-height: 3.4rem;
                }
            }
        }
    }
}
