.crm-auth-layout-container {
    padding: 2rem;
    width: 100vw;
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 51.2rem;
    gap: 4rem;
    background-color: #fafbfc;
    color: rgba(#000, 0.8);

    .crm-auth-left-block {
        width: 100%;
        padding: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .crm-auth-left-wrapper {
            max-width: 65rem;
            width: 100%;
        }

        ul {
            margin-top: 4rem;
            display: flex;
            gap: 3rem;
            text-align: center;
        }
    }

    .crm-auth-right-block {
        padding: 1.6rem 1.6rem 0 1.6rem;
        width: 51.2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1.6rem;
        background-color: #fff;
        box-shadow: 0 0 0.4rem 0 #00000029;
        box-sizing: border-box;
    }
}

.crm-auth-left-title {
    font-size: 4.8rem;
    font-weight: 700;
    color: var(--c-cyan-blue);
    text-transform: uppercase;
}

.crm-auth-left-subtitle {
    margin-top: 4.2rem;
    font-size: 2.8rem;
    font-weight: 700;
    color: rgba(#000, 0.8);
}

.crm-auth-left-description {
    margin-top: 2.4rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: rgba(#000, 0.5);
}

.crm-auth-form-card {
    padding: 0 6rem;

    &.crm-auth-form-succeed-card {
        .crm-auth-form-line:not(:first-child) {
            margin-top: 3.2rem;
        }
    }

    &.crm-auth-form-confirm-succeed-card {
        .crm-auth-form-line:not(:first-child) {
            margin-top: 0;
        }

        .crm-auth-form-line.crm-auth-form-icon-line {
            margin: 4.6rem 0 3.8rem;
        }
    }

    .crm-auth-form-line:not(:first-child) {
        margin-top: 2rem;
    }

    .crm-auth-form-line {
        img {
            margin: 0 auto;
        }
    }

    .crm-auth-form-title {
        color: var(--c-cyan-blue);
    }
    .crm-auth-form-subtitle {
        font-size: 2.2rem;
    }
    .crm-auth-form-info {
        font-size: 1.6rem;
        font-weight: 600;
        color: rgba(#000, 0.8);
    }
    .crm-auth-form-success-message {
        font-size: 1.6rem;
        font-weight: 600;
        color: rgba(#000, 0.8);
    }

    .crm-auth-form-link {
        display: inline-block;
    }

    .crm-auth-form-back-button {
        text-align: center;

        a {
            font-size: 1.4rem;
            font-weight: 600;
            color: var(--c-cyan-blue);
        }
    }
}
