.executed-report-info-modal {
    height: 100%;
    .campaign-tab-table-wrapper-inner {
        position: relative;
    }
    .module-header {
        display: none;
    }
}
.executed-modal-inner {
    .modal-body {
        padding: 0;
    }
    .title-text {
        > .ellipsis-text {
            color: var(--custom-color-1);
        }
    }
    .tab-switcher {
        display: none;
    }
    .full-radius {
        border-radius: 0;
        border: none;
        border-top: 1px solid rgba(var(--background-sc-rgb), 0.1);
    }
    .crm-section {
        border: unset;
        padding: unset;
    }
    .ta-pagination-holder {
        .pagination-drop {
            justify-content: flex-start;
            width: 100%;
        }
    }
    .modal-head {
        .ellipsis-text {
            font-weight: bold;
            color: var(--custom-color-1);
        }
    }
    .modal-content.s-default {
        width: 902px !important;
    }
    .table-holder {
        height: 386px;
    }
    .key-value-items-group {
        padding: 20px;
    }
    .title-holder {
        margin-bottom: 20px;
    }
}

.crm-segment-list-page-wrapper {
    .ta-n-body.ta-header {
        .ta-cell.a-square {
            min-height: 3.5rem;
        }
    }
}
.menu-item-info-value {
    width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
}

.custom-tabs-cnt {
    padding: 10px 12px;
    .paper {
        border-top: none;
    }
    .crm-custom-tabs-btn-panel {
        margin-bottom: -1px;
        p {
            font-size: 1.4rem;
            font-weight: 600;
            color: var(--custom-color-1);
        }
    }
}

.tab-content-cnt-wrapper {
    padding: 16px;
    .download-toolbar {
        top: 1.8rem;
    }
    .tab-content-cnt {
        border: 1px solid var(--line-color);
        border-radius: 20px;
        height: 100%;
        overflow: hidden;
    }
    .module-header {
        display: none;
    }
}

.executed-report-info-modal-content-cnt {
    height: 700px;
    overflow-y: auto;
    .executed-report-info-modal {
        height: unset;
    }
}

.executed-report-list-page-wrapper {
    padding: 20px;
    .paper {
        border-radius: 20px;
        border: 1px solid var(--line-color);
        overflow: hidden;
        margin-bottom: 20px;
        .executed-report-list-wrapper {
            padding: 20px;
            border-top: 1px solid var(--line-color);
            .paper {
                border: 1px solid var(--line-color);
            }
        }
        .right-content {
            .report-action-buttons-wrapper {
                display: flex;
            }
        }
        .report-result-tabs {
            padding: 2rem !important;
        }
    }
}
