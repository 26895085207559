.jodit {
    .jodit-ui-spacer {
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
    }

    .jodit-ui-checkbox__wrapper {
        input {
            appearance: auto;
        }
    }

    .jodit-input_group-buttons {
        width: unset !important;
    }
}
