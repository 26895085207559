.crm-option-list-popover {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.crm-option-list-content-wrapper {
    position: relative;

    .crm-option-list-content {
        padding-top: 10px;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px rgba(112, 112, 112, 0.2);
        background-color: var(--background);
        position: absolute;
        z-index: 1000;
        top: 56px;
        right: 0;
        color: rgba(var(--background-sc-rgb), 0.75);

        .input-element-back {
            border-radius: 0;
        }

        .search-holder {
            color: rgba(var(--background-sc-rgb), 0.75);

            .input-element {
                color: rgba(var(--background-sc-rgb), 0.75);
            }

            i {
                display: none;
            }
        }

        &__toggle-button {
            width: 100%;
            height: 36px;
            //background: var(--background) ;
            border: 1px solid var(--line-color);
            color: #3c4043;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 0 15px;

            &:hover {
                background: var(--background);
                color: #3c4043;
            }
        }

        &__is-not-valid {
            border: 1px solid var(--danger);
        }

        &__option-list {
            max-height: 240px;
            min-height: 240px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            span[class^='crm-option-column-'] {
                margin-right: 8px;
            }
        }

        .small-empty-option-list {
            display: flex;
            align-items: center;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }

        &__column-names {
            font-size: 12px;
            color: rgba(var(--custom-color-1), 0.75);

            &:hover {
                background: rgba(var(--background-sc-rgb), 0.05);
            }
        }

        .option-container.border-bottom {
            cursor: default;
        }

        .input-element {
            padding-left: 0;
        }

        .input-element-back {
            border: none;
            border-bottom: 1px solid var(--line-color);
        }

        .CreateMenuActionButton {
            width: 90px;
            margin-top: 20px;
        }

        .divider {
            width: 100%;
        }
    }

    &__error-text {
        display: block;
        margin: 4px 0 0 15px;
        color: var(--danger);
    }
}

.crm-option-list-content-wrapper {
    .option-title {
        display: flex;

        span {
            font-weight: 600;
        }
    }
}

.crm-option-list-content-wrapper {
    .btn.crm-option-list-content__toggle-button {
        background: var(--background);
    }
}

.cj-option-list-content-wrapper {
    .option-left-elements,
    .option-title {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        span {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            &:first-child {
                text-align: left;
                flex: 1.5;
            }

            &:last-child {
                flex: 0.8;
                text-align: left;
            }

            &:not(:first-child):not(:last-child) {
                text-align: left;
                flex: 1.5;
            }
        }
    }
}
