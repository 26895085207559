.crm-client-view-page-wrapper {
    padding: 20px;
    height: 100%;

    .paper {
        height: 100%;
        border-radius: 20px;
        border: 1px solid var(--line-color);
        overflow: hidden;
        margin-bottom: 20px;

        .client-view-tab-wrapper {
            padding: 1.5rem;
            border-top: 1px solid var(--line-color);
            height: calc(100% - 5rem);
            display: flex;
            align-items: center;

            .crm-campaign-report-block {
                height: 100%;
                width: 100%;

                .crm-custom-tabs-btn-panel {
                    display: flex;
                    margin-bottom: -1px;

                    li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        transition: all 300ms;
                        > button {
                            padding: 1rem 1.2rem;
                            > p {
                                font: 600 1.4rem/1.8rem var(--font-family);
                            }
                        }
                        &.active {
                            > p {
                                color: #3c4043;
                            }
                        }
                    }
                }
                .tab-switcher {
                    display: none;
                }
                .crm-custom-tabs-content-wrapper {
                    height: calc(100% - 4rem);

                    .crm-custom-tabs-content {
                        .crm-tab-content {
                            padding: 1.5rem;

                            .client-view-page-tab-content {
                                height: 100%;

                                overflow-y: auto;
                                border: unset;
                                border-radius: unset;

                                .crm-keyvalue-items-wrapper {
                                    display: grid;
                                    grid-template-columns: repeat(4, 1fr);
                                    .key-value-info-content.key-value-items-vertical {
                                        margin-bottom: 2.5rem;
                                        .key-value-items-title {
                                            color: rgba(var(--background-sc-rgb), 0.62);
                                        }
                                        .key-value-items-skeleton {
                                            padding-top: 1rem;
                                            .key-value-items-subtitle {
                                                color: var(--background-sc);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .right-content {
            .report-action-buttons-wrapper {
                display: flex;
            }
        }
    }
}

.crm-client-preview-popover {
    .popover-content {
        width: 40rem;
        min-height: 7rem;
        padding: 1.5rem;

        .popover-body {
            height: 100%;
            overflow: auto;

            .scroll-holder {
                min-height: 3rem !important;
                .scroll-content {
                    overflow: auto !important;
                }
            }
        }
    }
    .crm-keyvalue-items-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.5rem;

        .key-value-info-content {
            border-left: 0.2rem solid var(--line-color);
            padding: 0 0 0 0.5rem;

            .key-value-items-title {
                color: rgba(var(--background-sc-rgb), 0.62);
            }
            .key-value-items-skeleton {
                width: 100%;
                .key-value-items-subtitle {
                    color: var(--background-sc);
                }
                .skeleton-holder {
                    & > span {
                        .react-loading-skeleton {
                            height: 1.6rem !important;
                        }
                    }
                }
            }
        }
    }
}
